const PROJECT_LOADER_START = "@@project/startLoader";
const SET_PROJECT_LIST = "@@project/setProjectList";
const PROJECT_LOADER_STOP = "@@project/stopLoader";
const PROJECT_SUBMITTING = "@@project/submitting";
const SET_SELECTED_PROJECT = "@@project/setSelectedProject";
const SET_PROJECT_PIPE_COUNT = "@@project/setProjectPipeCount";
const SET_TOTAL_PIPE_COUNT = "@@project/setTotalPipeCount";
const SET_USED_PIPE_COUNT = "@@project/setUsedPipeCount";
const SET_AVAILABLE_SPREAD_PIPE_COUNT = "@@project/setAvailableSpreadPipeCount";
const SET_AVAILABLE_SUBSPREAD_PIPE_COUNT =
  "@@project/setAvailableSubSpreadPipeCount";

const SUBMIT_PROJECT_SAGA = "@@project/submitProject";
const FETCH_PROJECT_SAGA = "@@project/fetchProject";
const UPDATE_PROJECT_SAGA = "@@project/updateProject";
const FETCH_PIPE_LENGTH = "@@project/fetchPipeLength";

export {
  PROJECT_LOADER_START,
  SET_PROJECT_LIST,
  PROJECT_SUBMITTING,
  SET_PROJECT_PIPE_COUNT,
  PROJECT_LOADER_STOP,
  SET_SELECTED_PROJECT,
  SUBMIT_PROJECT_SAGA,
  FETCH_PROJECT_SAGA,
  UPDATE_PROJECT_SAGA,
  FETCH_PIPE_LENGTH,
  SET_TOTAL_PIPE_COUNT,
  SET_USED_PIPE_COUNT,
  SET_AVAILABLE_SPREAD_PIPE_COUNT,
  SET_AVAILABLE_SUBSPREAD_PIPE_COUNT,
};
