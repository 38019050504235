import { Tabs } from "antd";
import React from "react";
import Configuration from "../components/subcomponents/Configuration";
import PipeDetails from "../components/subcomponents/PipeDetails";
import Reports from "../components/subcomponents/Reports";
import Sequence from "../components/subcomponents/Sequence";

let tabKeys = {
  configuration: "configuration",
  sequence: "sequence",
  reports: "reports",
  pipeDetails: "pipeDetails",
};

const tabItems = [
  {
    key: tabKeys.configuration,
    label: "Configuration",
    children: <Configuration />,
  },
  { key: tabKeys.sequence, label: "Sequence", children: <Sequence /> },
  { key: tabKeys.reports, label: "Reports", children: <Reports /> },
  {
    key: tabKeys.pipeDetails,
    label: "Pipe Details",
    children: <PipeDetails />,
  },
];

const TabList = () => {
  return (
    <Tabs
      defaultActiveKey={tabKeys.configuration}
      items={tabItems}
      className="custom-tab"
      destroyInactiveTabPane={true}
    />
  );
};

export default TabList;
