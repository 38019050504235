import { CloseCircleFilled } from "@ant-design/icons";
import { Modal, Input, Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { commonConstants, configurationConstants } from "../constants";
import { SEND_EMAIL_SEQUENCE_SAGA } from "../redux/configuration/types";
import {
  SEND_CONFIGURATION_EMAIL,
  SEND_EMAIL_PIPE_DATA_SAGA,
  SEND_EMAIL_PROJECT_SUMMARY_SAGA,
} from "../redux/reports/types";

const { Item } = Form;

const EmailPopup = (props) => {
  let {
    setIsSendingEmail,
    setShowEmailModal,
    selectionType,
    selectedConfiguration,
    showEmailModalFor,
  } = props;
  const reducerList = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sending, setSending] = useState(false);

  const customerEmail =
    reducerList?.projectReducer?.selectedProject?.customerEmail;
  const projectManagerEmail =
    reducerList?.projectReducer?.selectedProject?.projectManagerEmail;
  const technicianEmail =
    reducerList?.projectReducer?.selectedProject?.technicianEmail;

  const [okDisabled, setOkDisabled] = useState(false);
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    let emailsList = [];
    switch (showEmailModalFor) {
      case commonConstants.showEmailModalFor.sequence:
      case commonConstants.showEmailModalFor.projectSummary: {
        customerEmail && projectManagerEmail
          ? customerEmail === projectManagerEmail
            ? emailsList.push(customerEmail)
            : emailsList.push(projectManagerEmail, customerEmail)
          : customerEmail
          ? emailsList.push(customerEmail)
          : projectManagerEmail
          ? emailsList.push(projectManagerEmail)
          : "";
        break;
      }
      case commonConstants.showEmailModalFor.configuration:
      case commonConstants.showEmailModalFor.pipeData: {
        customerEmail && technicianEmail
          ? customerEmail === technicianEmail
            ? emailsList.push(customerEmail)
            : emailsList.push(technicianEmail, customerEmail)
          : customerEmail
          ? emailsList.push(customerEmail)
          : technicianEmail
          ? emailsList.push(technicianEmail)
          : "";
        break;
      }
    }
    setEmails([...emailsList]);
  }, [reducerList?.projectReducer?.selectedProject]);

  useEffect(() => {
    emails.length === 0 ? setOkDisabled(true) : setOkDisabled(false);
  }, [emails]);

  const [emailForm] = Form.useForm();

  const validateEmail = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!value) {
      return Promise.resolve("Email is optional.");
    } else if (!emailRegex.test(value)) {
      return Promise.reject("Please enter a valid email address.");
    } else if (emails.includes(value)) {
      return Promise.reject("Please enter a non-duplicate email address.");
    }
    return Promise.resolve();
  };

  const handleInputEnter = () => {
    emailForm
      .validateFields()
      .then(() => {
        if (emailForm.getFieldValue("additionalEmails").trim() !== "") {
          setEmails([
            ...emails,
            emailForm.getFieldValue("additionalEmails").trim(),
          ]);
          emailForm.setFieldsValue({ additionalEmails: "" });
        }
      })
      .catch((e) =>
        console.error("Invalid Email : ", e.values.additionalEmails)
      );
  };

  const handleOk = () => {
    if (emails.length > 0) {
      sendEmail(emails);
    }
  };

  const handleTagClose = (removedIndex) => {
    const newEmails = [...emails];
    newEmails.splice(removedIndex, 1);
    setEmails(newEmails);
  };

  const sendEmail = async (emails) => {
    let projectNumber =
      reducerList?.projectReducer?.selectedProject?.projectNumber;
    switch (showEmailModalFor) {
      case commonConstants.showEmailModalFor.configuration: {
        let payload = {
          projectNumber: projectNumber,
          emails: [...emails],
        };
        setIsSendingEmail(true);
        setSending(true);
        dispatch({
          type: SEND_CONFIGURATION_EMAIL,
          payload: payload,
          cb: () => {
            setIsSendingEmail(false);
            setShowEmailModal(false);
            setSending(false);
          },
          error: () => {
            setIsSendingEmail(false);
            setSending(false);
          },
        });
        break;
      }
      case commonConstants.showEmailModalFor.sequence: {
        let payload = {
          projectNumber: projectNumber,
          emails: [...emails],
        };

        if (selectionType == configurationConstants.selectionType.spread) {
          payload = {
            ...payload,
            spread: selectedConfiguration.id?.toString(),
          };
        }

        if (selectionType == configurationConstants.selectionType.subSpread) {
          payload = {
            ...payload,
            subSpread: selectedConfiguration.id?.toString(),
          };
        }
        setIsSendingEmail(true);
        setSending(true);
        dispatch({
          type: SEND_EMAIL_SEQUENCE_SAGA,
          payload: payload,
          cb: () => {
            setIsSendingEmail(false);
            setShowEmailModal(false);
            setSending(false);
          },
          error: () => {
            setIsSendingEmail(false);
            setSending(false);
          },
        });
        break;
      }
      case commonConstants.showEmailModalFor.pipeData: {
        let payload = {
          projectNumber: projectNumber,
          emails: [...emails],
        };
        setIsSendingEmail(true);
        setSending(true);
        dispatch({
          type: SEND_EMAIL_PIPE_DATA_SAGA,
          payload: payload,
          cb: () => {
            setIsSendingEmail(false);
            setShowEmailModal(false);
            setSending(false);
          },
          error: () => {
            setIsSendingEmail(false);
            setSending(false);
          },
        });
        break;
      }
      case commonConstants.showEmailModalFor.projectSummary: {
        let payload = {
          projectNumber: projectNumber,
          emails: [...emails],
        };
        setIsSendingEmail(true);
        setSending(true);
        dispatch({
          type: SEND_EMAIL_PROJECT_SUMMARY_SAGA,
          payload: payload,
          cb: () => {
            setIsSendingEmail(false);
            setShowEmailModal(false);
            setSending(false);
          },
          error: () => {
            setIsSendingEmail(false);
            setSending(false);
          },
        });
      }
    }
  };

  return (
    <Modal
      okText={
        sending ? t("common.sendingButtonText") : t("common.sendButtonText")
      }
      cancelText={t("common.cancelButtonText")}
      width={500}
      onOk={() => handleOk()}
      onCancel={() => {
        setShowEmailModal(false);
      }}
      okButtonProps={{ disabled: okDisabled }}
      visible
    >
      <div className="email-popup-text">
        {emails.length === 0 ? (
          <p>{t("reportTab.pleaseEnterOneOrMoreEmail")}</p>
        ) : emails.length < 2 ? (
          <p>
            {showEmailModalFor}
            {t("reportTab.areYouSureYouWantToSendEmail")}
          </p>
        ) : (
          <p>
            {showEmailModalFor}
            {t("reportTab.areYouSureYouWantToSendEmails")}
          </p>
        )}
        <ol>
          {emails.map((email, index) => (
            <li key={email}>
              <Button key={index} className="modal-email-button">
                <div>{email}</div>
              </Button>
              <Button
                onClick={() => handleTagClose(index)}
                className="email-cancel-button"
              >
                <CloseCircleFilled />
              </Button>
            </li>
          ))}
        </ol>

        <Form form={emailForm}>
          <Item
            name="additionalEmails"
            rules={[
              {
                validator: validateEmail,
              },
            ]}
          >
            <Input
              placeholder="Enter additional email(s)"
              onPressEnter={handleInputEnter}
              disabled={emails.length > 4}
            />
          </Item>
        </Form>
      </div>
    </Modal>
  );
};
export default EmailPopup;
