import { Table, Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { commonConstants } from "../../constants";
import { sequenceHelpers } from "../../helpers";

const SequenceData = ({
  sequenceList,
  currentPage,
  rowsPerPage,
  totalCount,
  handleTableChange,
  selectionType,
  spreadName,
}) => {
  const { t } = useTranslation();
  const columns = sequenceHelpers.getColumns(t, selectionType);
  const data = sequenceHelpers.generateData(sequenceList, spreadName);
  const isDataAvailable = data && data.length > 0;

  if (columns.length > 0) {
    const lastColumnIndex = columns.length - 1;
    columns[lastColumnIndex].title = (
      <span>
        {columns[lastColumnIndex].title}
        <Tooltip placement="topLeft" title={t("sequenceTable.tdcInfo")}>
          <sup className="sup">ⓘ</sup>
        </Tooltip>
      </span>
    );
  }

  return (
    <div className="container">
      <div className="table-container">
        <>
          {isDataAvailable && (
            <Table
              dataSource={data}
              columns={columns}
              scroll={{
                y: "auto",
              }}
              pagination={{
                current: currentPage,
                pageSize: rowsPerPage,
                total: totalCount,
                showSizeChanger: true,
                pageSizeOptions: commonConstants.pageSizeOptions,
              }}
              className="custom-table"
              onChange={(e) => handleTableChange(e)}
            />
          )}
        </>
      </div>
    </div>
  );
};

export default SequenceData;
