import { Spin } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ProjectListHeader from "../../layout/projectListHeader";
import TabList from "../../layout/tab";
import { SET_CONFIGURATION_LISTS } from "../../redux/configuration/types";
import {
  FETCH_PROJECT_SAGA,
  SET_SELECTED_PROJECT,
} from "../../redux/project/types";

const HomePage = () => {
  const dispatch = useDispatch();

  const reducerList = useSelector((state) => state);
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    dispatch({
      type: FETCH_PROJECT_SAGA,
    });
  }, []);

  useEffect(() => {
    if (reducerList?.projectReducer?.projectList?.length > 0) {
      setProjectList(reducerList.projectReducer.projectList);
    }
  }, [reducerList?.projectReducer?.projectList]);

  if (reducerList?.projectReducer?.isProjectLoading) {
    return (
      <div className="home-loader">
        <Spin size="large" tip="Loading data..." />
      </div>
    );
  }

  const handlerClearSearch = () => {
    setProjectList(reducerList.projectReducer.projectList);

    dispatch({
      type: SET_SELECTED_PROJECT,
      payload: {},
    });

    dispatch({
      type: SET_CONFIGURATION_LISTS,
      payload: [],
    });
  };

  const reloadNewData = () => {
    dispatch({
      type: FETCH_PROJECT_SAGA,
    });
  };

  return (
    <div className="h-100 d-flex flex-column">
      <div className="flex-auto">
        <ProjectListHeader
          projectListData={projectList}
          reloadData={reloadNewData}
          setProjectList={setProjectList}
          handlerClearSearch={handlerClearSearch}
        />

        {reducerList?.projectReducer?.selectedProject?.id && <TabList />}
      </div>
    </div>
  );
};

export default HomePage;
