import { roleBasedAccessControlConstants } from "../constants";

function generateSequence(arr) {
  function stringToNumber(str) {
    let result = 0;
    for (let i = 0; i < str.length; i++) {
      result = result * 26 + (str.charCodeAt(i) - "A".charCodeAt(0) + 1);
    }
    return result;
  }

  function numberToString(num) {
    let result = "";
    while (num > 0) {
      let remainder = (num - 1) % 26;
      result = String.fromCharCode("A".charCodeAt(0) + remainder) + result;
      num = Math.floor((num - 1) / 26);
    }
    return result;
  }

  if (arr.length === 0) {
    return "A";
  }

  const numericArray = arr.map(stringToNumber);
  const maxNumericValue = Math.max(...numericArray);
  const nextNumericValue = maxNumericValue + 1;
  const nextString = numberToString(nextNumericValue);
  return nextString;
}

function sortBySortOrder(array) {
  return array.sort((a, b) => a.sortOrder - b.sortOrder);
}

function deleteAndSelectNext({ itemIdToDelete, items }) {
  const indexToDelete = items.findIndex((item) => item.id === itemIdToDelete);

  if (indexToDelete !== -1) {
    items.splice(indexToDelete, 1);

    let selectedItem = {};

    if (indexToDelete < items.length) {
      selectedItem = items[indexToDelete];
    } else if (items.length > 0) {
      selectedItem = items[indexToDelete - 1];
    } else {
      selectedItem = {};
    }

    return selectedItem;
  } else {
    return selectedItem;
  }
}

function getRoleOfUserFromReduxState(userDetails) {
  return (
    userDetails?.signInUserSession?.accessToken?.payload?.["cognito:groups"]
      ?.filter((ite) =>
        roleBasedAccessControlConstants.pipeFitterRoles.includes(ite)
      )
      ?.sort(roleBasedAccessControlConstants.pipeFitterRoles.customSort)[0] ||
    ""
  );
}

const calculateTotalPages = ({ rowsPerPage, totalCount }) => {
  return Math.ceil(totalCount / rowsPerPage);
};

const calculateSkipFromPage = ({ page, limit }) => {
  return (page - 1) * limit;
};

export {
  generateSequence,
  sortBySortOrder,
  deleteAndSelectNext,
  getRoleOfUserFromReduxState,
  calculateTotalPages,
  calculateSkipFromPage,
};
