import { combineReducers } from "redux";

import switchThemeReducer from "./application/reducer";
import configurationReducer from "./configuration/reducer";
import projectReducer from "./project/reducer";
import auth from "./reducer/authReducer";
import clientRequest from "./reducer/clientReducer";
import reportsReducer from "./reports/reducer";
import rolesReduceer from "./roles/reducer";
import toolBoxReducer from "./toolbox/reducer";
import { SIGNOUT } from "./types";
import uploadsReducer from "./uploads/reducer";

const appReducer = combineReducers({
  auth,
  clientRequest,
  rolesReduceer,
  toolBoxReducer,
  projectReducer,
  configurationReducer,
  reportsReducer,
  switchThemeReducer,
  uploadsReducer,
});

const rootReducer = (state, action) => {
  const newState = action.type === SIGNOUT ? undefined : state;
  return appReducer(newState, action);
};

export default rootReducer;
