import { SWITCH_THEME, TOGGLE_THEME } from "./types";

const initialState = {
  switchTheme: false,
};

const switchThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SWITCH_THEME:
      return {
        ...state,
        switchTheme: action.payload,
      };

    case TOGGLE_THEME:
      return {
        ...state,
        switchTheme: !state.switchTheme,
      };
    default:
      return state;
  }
};

export default switchThemeReducer;
