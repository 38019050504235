import LogReport from "../components/Comingsoon";
import { HomeLayout } from "../layout";
import Home from "../pages/home-page";

export default [
  {
    path: "/",
    exact: true,
    layout: HomeLayout,
    component: Home,
  },

  {
    path: "/pipefitter-home",
    layout: HomeLayout,
    component: Home,
  },

  {
    path: "/log-report",
    layout: HomeLayout,
    component: LogReport,
  },
];
