import {
  CaretUpOutlined,
  EditOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import { Form, Input, Row, Col, Select, Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { commonConstants, configurationConstants } from "../../constants";
import { colorConstants } from "../../constants/global-color";
import { spreadConfiguratorHelpers } from "../../helpers";
import "../../locales/en.json";
import { isAnySequenceRelatedParameterChangedForConfiguration } from "../../helpers/spread-configuration";
import {
  MIN_MAX_CALCULATION_SAGA,
  UPDATE_SPREAD_CONFIGURATION_SAGA,
  UPDATE_SUB_SPREAD_CONFIGURATION_SAGA,
} from "../../redux/configuration/types";
import { FETCH_PIPE_LENGTH } from "../../redux/project/types";
import Qrcode from "../subcomponents/Qrcode";

const SpreadConfigurationForm = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [qrCodeModal, setQrCodeModal] = useState(false);
  const [spreadConfigForm] = Form.useForm();
  const [sequenceOptionsList, setSequenceOptionsList] = useState([]);
  const reducerList = useSelector((state) => state);

  const [updateConfigurationLoading, setUpdateConfigurationLoading] =
    useState(false);
  const [isEditingEnabled, setIsEditingEnabled] = useState(false);
  const [disableSequenceEndA, setDisableSequenceEndA] = useState(false);
  const [disableSequenceEndB, setDisableSequenceEndB] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [isCalculating, setIsCalculating] = useState(false);
  const handleClick = () => {
    setQrCodeModal(true);
  };

  const updateSpread = (payload) => {
    let updateSpreadPayload = {
      targetNumberOfPipes: payload.targetNumberOfPipesInSpread,
      pipelineTypes: payload.pipelineTypes,
      sequenceEndA: payload.sequenceEndA,
      sequenceEndB: payload.sequenceEndB,
      spreadId: payload.spreadId,
      spreadName: payload.spreadName,
      projectNumber: payload.projectNumber,
      sortOrder: payload.sortOrder,
    };
    if (payload.targetLengthOfSpread) {
      updateSpreadPayload.targetLengthOfSpread = payload.targetLengthOfSpread;
    }

    setUpdateConfigurationLoading(true);
    dispatch({
      type: UPDATE_SPREAD_CONFIGURATION_SAGA,
      payload: updateSpreadPayload,
      cb: () => {
        setUpdateConfigurationLoading(false);
        setIsEditingEnabled(false);
      },
      err: () => {
        setIsEditingEnabled(true);
        setUpdateConfigurationLoading(false);
      },
    });
  };

  const updateSubSpread = (payload) => {
    let updateSpreadPayload = {
      targetNumberOfPipes: payload.targetNumberOfPipesInSpread,
      pipelineTypes: payload.pipelineTypes,
      sequenceEndA: payload.sequenceEndA,
      sequenceEndB: payload.sequenceEndB,
      subSpreadId: payload.subSpreadId,
      spreadId: payload.spreadId,
      subSpreadName: payload.subSpreadName,
      projectNumber: payload.projectNumber,
      sortOrder: payload.sortOrder,
    };

    if (payload.targetLengthOfSpread) {
      updateSpreadPayload.targetLengthOfSpread = payload.targetLengthOfSpread;
    }

    setUpdateConfigurationLoading(true);
    dispatch({
      type: UPDATE_SUB_SPREAD_CONFIGURATION_SAGA,
      payload: updateSpreadPayload,
      cb: () => {
        setIsEditingEnabled(false);
        setUpdateConfigurationLoading(false);
        props.setSelectedKey("0");
      },
      err: () => {
        setIsEditingEnabled(true);
        setUpdateConfigurationLoading(false);
      },
    });
  };

  useEffect(() => {
    if (!props.selectConfiguration.id) {
      return;
    }
    setIsEditingEnabled(false);
    if (props.selectionType === configurationConstants.selectionType.spread) {
      let sequenceList = [];
      props.configurationList
        ?.filter((spread) => spread.id != props.selectConfiguration.id)
        .forEach((spread) => {
          sequenceList = [
            ...sequenceList,
            ...[
              {
                label: " Start of " + spread.spreadName,
                value: spread.spreadName + " End A",
              },
              {
                label: " End of " + spread.spreadName,
                value: spread.spreadName + " End B",
              },
            ],
          ];
        });

      let usedSpreads = [];

      props.configurationList
        .filter((spread) => spread.id != props.selectConfiguration.id)
        .filter((spread) => spread.pipelineTypes != "Unconstraint")
        .filter((spread) => spread.sequenceEndA?.trim() != "")
        .map((spread) => {
          let replacedString = spread.sequenceEndA?.replace(" End A", "");
          replacedString = replacedString?.replace(" End B", "");
          usedSpreads.push(replacedString);
        });

      sequenceList = sequenceList.filter(
        (option) => !usedSpreads.some((ite) => option.value.includes(ite))
      );

      setSequenceOptionsList(sequenceList);
    } else if (
      props.selectionType === configurationConstants.selectionType.subSpread
    ) {
      let sequenceList = [];
      const selectedSpread = props.configurationList.find(
        (spread) => spread.id === props.selectConfiguration.spreadId
      );

      selectedSpread?.subSpreads
        ?.filter((subSpread) => subSpread.id !== props.selectConfiguration.id)
        ?.forEach((subSpread) => {
          sequenceList = [
            ...sequenceList,
            ...[
              {
                label: " Start of " + subSpread.subSpreadName,
                value: subSpread.subSpreadName + " End A",
              },
              {
                label: " End of " + subSpread.subSpreadName,
                value: subSpread.subSpreadName + " End B",
              },
            ],
          ];
        });

      let usedSubSpreads = [];

      selectedSpread?.subSpreads
        ?.filter((subSpread) => subSpread.id !== props.selectConfiguration.id)
        .filter((subSpread) => subSpread.pipelineTypes != "Unconstraint")
        .filter((subSpread) => subSpread.sequenceEndA?.trim() != "")
        .map((subSpread) => {
          let replacedString = subSpread.sequenceEndA?.replace(" End A", "");
          replacedString = replacedString?.replace(" End B", "");

          usedSubSpreads.push(replacedString);
        });

      sequenceList = sequenceList.filter(
        (option) => !usedSubSpreads.some((ite) => option.value.includes(ite))
      );

      setSequenceOptionsList(sequenceList);
    }

    if (props.selectConfiguration?.pipelineTypes === "Unconstraint") {
      setDisableSequenceEndA(true);
      setDisableSequenceEndB(true);
    } else {
      setDisableSequenceEndA(false);
      setDisableSequenceEndB(false);
    }

    const initialValues = {
      targetNumberOfPipesInSpread:
        props.selectConfiguration?.targetNumberOfPipes,
      targetLengthOfSpread: props.selectConfiguration?.targetLengthOfSpread,
      pipelineTypes: props.selectConfiguration?.pipelineTypes,
      sequenceEndA:
        props.selectConfiguration?.sequenceEndA === " "
          ? "None"
          : props.selectConfiguration?.sequenceEndA,
      sequenceEndB:
        props.selectConfiguration?.sequenceEndB === " "
          ? "None"
          : props.selectConfiguration?.sequenceEndB,
    };

    setInitialFormValues(initialValues);
    spreadConfigForm.setFieldsValue(initialValues);

    if (!props.updateConfigurationLoading) {
      spreadConfigForm.setFieldsValue(initialValues);
    }
  }, [props.updateConfigurationLoading, props.selectConfiguration]);

  useEffect(() => {
    dispatch({
      type: FETCH_PIPE_LENGTH,
      payload: {
        projectNumber:
          reducerList?.projectReducer?.selectedProject?.projectNumber,
        spreadId: "0",
        subSpreadId: "0",
      },
    });
  }, [reducerList?.projectReducer?.selectedProject?.projectNumber]);

  const availablePipes = reducerList?.projectReducer?.projectPipeCount;
  const usedPipes = reducerList?.projectReducer?.usedPipeCount;
  const validPipes = availablePipes + usedPipes;

  const handleEditToggle = () => {
    setIsEditingEnabled(!isEditingEnabled);

    if (isEditingEnabled) {
      handleCancel();
    }
  };

  const handleEditConfiguration = () => {
    spreadConfigForm
      .validateFields()
      .then((values) => {
        let isChanged = isAnySequenceRelatedParameterChangedForConfiguration({
          oldDataPipeLineType: props.selectConfiguration?.pipelineTypes,
          oldDataTargetNumberOfPipes:
            props.selectConfiguration?.targetNumberOfPipes,
          updatedPipeLineType: values?.pipelineTypes,
          updatedTargetNumberOfPipes: values?.targetNumberOfPipesInSpread,
        });

        if (
          props.selectConfiguration?.sequenceGenerationStatus === "InProgress"
        ) {
          Modal.error({
            title: t("configuration.sequenceInProgress"),
            content: t("configuration.errorsequenceGenerationInprogress"),
            okText: "Ok",
            width: 600,
            className: "sequenceInProgress",
          });
          return;
        }
        if (props.selectConfiguration?.sequenceGenerationStatus === "Started") {
          Modal.error({
            title: t("configuration.sequenceStarted"),
            content: t("configuration.errorsequenceGenerationStarted"),
            okText: "Ok",
            className: "Sequence-error",
            width: 600,
          });
          return;
        }

        if (
          props.selectConfiguration?.sequenceGenerationStatus === "Completed" &&
          isChanged
        ) {
          Modal.confirm({
            title: t("configuration.configurationSequenceWarningMessage"),
            okText: (
              <span style={{ color: colorConstants.whiteColor }}>
                {t("common.yes")}
              </span>
            ),
            cancelText: t("common.no"),
            width: 500,
            className: "custom-approve-modal",
            onOk: handleClickSubmit,
          });
        } else {
          handleClickSubmit();
        }
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleClickSubmit = () => {
    spreadConfigForm
      .validateFields()
      .then((values) => {
        if (values.sequenceEndA === "None" || values.sequenceEndA === null) {
          values.sequenceEndA = " ";
        }

        if (values.sequenceEndB === "None" || values.sequenceEndB === null) {
          values.sequenceEndB = " ";
        }

        if (
          props.selectionType == configurationConstants.selectionType.spread
        ) {
          updateSpread({
            ...values,
            spreadId: props.selectConfiguration.id,
            spreadName: props.selectConfiguration.spreadName,
            projectNumber: props.selectConfiguration.projectNumber,
            sortOrder: props.selectConfiguration.sortOrder,
          });
        } else if (
          props.selectionType == configurationConstants.selectionType.subSpread
        ) {
          updateSubSpread({
            ...values,
            subSpreadId: props.selectConfiguration.id,
            subSpreadName: props.selectConfiguration.subSpreadName,
            projectNumber: props.selectConfiguration.projectNumber,
            spreadId: props.selectConfiguration.spreadId,
            sortOrder: props.selectConfiguration.sortOrder,
          });
        }
        setInitialFormValues({
          targetNumberOfPipesInSpread: values.targetNumberOfPipesInSpread,
          targetLengthOfSpread: values.targetLengthOfSpread,
          pipelineTypes: values.pipelineTypes,
          sequenceEndA:
            values.sequenceEndA === " " ? "None" : values.sequenceEndA,
          sequenceEndB:
            values.sequenceEndB === " " ? "None" : values.sequenceEndB,
        });
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handlePipelineTypeChange = (value, field) => {
    console.log(value, "Field -->", field, "Value field changed");
    if (value === "Unconstraint") {
      spreadConfigForm.setFieldsValue({
        sequenceEndA: "None",
        sequenceEndB: "None",
      });
      setDisableSequenceEndA(true);
      setDisableSequenceEndB(true);
    } else if (value == "Continue") {
      spreadConfigForm.setFieldsValue({
        sequenceEndA: undefined,
        sequenceEndB: undefined,
      });
      setDisableSequenceEndA(false);
      setDisableSequenceEndB(false);
    } else {
      setDisableSequenceEndA(false);
      setDisableSequenceEndB(false);
    }
  };

  const handleTargetNumberOfPipesChange = (value) => {
    const nominalPipeLengthValue =
      reducerList?.projectReducer?.selectedProject?.nominalPipeLength;

    const targetLengthOfSpread = value * nominalPipeLengthValue;

    spreadConfigForm.setFieldsValue({
      targetLengthOfSpread: targetLengthOfSpread,
    });
  };

  const handleCancel = () => {
    spreadConfigForm.setFieldsValue(initialFormValues);

    const pipelineType = spreadConfigForm.getFieldValue("pipelineTypes");
    if (pipelineType === "Unconstraint") {
      setDisableSequenceEndA(true);
      setDisableSequenceEndB(true);
    } else {
      setDisableSequenceEndA(false);
      setDisableSequenceEndB(false);
    }
  };

  const handleClickMinMax = () => {
    const projectNumber =
      reducerList?.projectReducer?.selectedProject?.projectNumber;

    if (projectNumber) {
      setIsCalculating(true);
      dispatch({
        type: MIN_MAX_CALCULATION_SAGA,
        payload: { projectNumber },
        cb: () => setIsCalculating(false),
        error: () => setIsCalculating(false),
      });
    } else {
      notification.error({
        message: "Project number is not available",
      });
    }
  };

  return (
    <div className="right-div">
      <div className="qr-code-div">
        <span
          className="custom-tooltip-min-max"
          data-tooltip={
            isCalculating ? "Min-Max calculating" : "Min-Max calculation"
          }
        >
          {validPipes > 0 ? (
            <CaretUpOutlined
              className="form1-icon"
              onClick={handleClickMinMax}
            />
          ) : (
            ""
          )}
          {isCalculating && (
            <div className="downloading-text-configuration">
              <div className="typing-animation">
                <div className="dot" style={{ "--delay": "0ms" }}></div>
                <div className="dot" style={{ "--delay": "200ms" }}></div>
                <div className="dot" style={{ "--delay": "400ms" }}></div>
              </div>
            </div>
          )}
        </span>
        <span className="custom-tooltip" data-tooltip="Generate QR">
          <QrcodeOutlined className="form-icon" onClick={handleClick} />
          {qrCodeModal && (
            <Qrcode qrCodeModal={qrCodeModal} setQrCodeModal={setQrCodeModal} />
          )}
        </span>
      </div>
      <div className="edit-form">
        {props.doesHavePermissionToEditConfiguration ? (
          <Button onClick={() => handleEditToggle()} className="ant-edit-btn">
            <div className="editconfigure">
              <div className="editicon">
                <span className="custom-tooltip" data-tooltip="Edit">
                  <EditOutlined onClick={() => handleEditToggle()} />
                </span>
              </div>
            </div>
          </Button>
        ) : (
          <div className="ant-edit-btn"></div>
        )}
        <Form form={spreadConfigForm} name="basic" layout="vertical">
          <Row gutter={12}>
            {Object.keys(
              spreadConfiguratorHelpers.spreadConfigurationForm({
                sequenceList: sequenceOptionsList,
              })
            ).map((fieldName) => {
              const {
                type,
                label,
                placeholder,
                rules,
                options,
              } = spreadConfiguratorHelpers.spreadConfigurationForm({
                sequenceList: sequenceOptionsList,
              })?.[fieldName];
              if (
                type == commonConstants.inputTypes.input ||
                type == commonConstants.inputTypes.number
              ) {
                return (
                  <Col key={fieldName} lg={24}>
                    <Form.Item
                      key={fieldName}
                      label={t(`${label}`)}
                      name={fieldName}
                      rules={rules}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 10 }}
                    >
                      <Input
                        type={type}
                        size="large"
                        maxLength={
                          commonConstants.commonConstant.maxFormFieldLength
                        }
                        disabled={!isEditingEnabled}
                        placeholder={t(`${placeholder}`)}
                        className="no-spin-buttons"
                        onChange={(e) => {
                          if (fieldName === "targetNumberOfPipesInSpread") {
                            handleTargetNumberOfPipesChange(e.target.value);
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                );
              } else if (type == commonConstants.inputTypes.select) {
                return (
                  <Col key={fieldName} lg={24}>
                    <Form.Item
                      key={fieldName}
                      label={t(`${label}`)}
                      name={fieldName}
                      rules={rules}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 10 }}
                    >
                      <Select
                        className={
                          !isEditingEnabled
                            ? "select-drop"
                            : "select-drop-disabled"
                        }
                        bordered={false}
                        size="default"
                        placeholder={t(`${placeholder}`)}
                        onChange={(value) =>
                          handlePipelineTypeChange(value, fieldName)
                        }
                        disabled={
                          (fieldName === "sequenceEndA" &&
                            disableSequenceEndA) ||
                          (fieldName === "sequenceEndB" &&
                            disableSequenceEndB) ||
                          !isEditingEnabled
                        }
                      >
                        {options?.map((ite) => {
                          return (
                            <Select.Option key={ite.value} value={ite.value}>
                              {ite.label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                );
              }
            })}
          </Row>
          {props.doesHavePermissionToEditConfiguration && (
            <div className="conf-edit">
              {isEditingEnabled ? (
                <>
                  <Button
                    onClick={() => handleEditToggle()}
                    className="ant-btn ant-btn-default"
                  >
                    {t("common.cancelButtonText")}
                  </Button>
                  <Button
                    onClick={() => handleEditConfiguration()}
                    className="ant-btn-primary"
                    disabled={updateConfigurationLoading}
                  >
                    {updateConfigurationLoading
                      ? t("common.savingButtonText")
                      : t("common.saveButtonText")}
                  </Button>
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default SpreadConfigurationForm;
