import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Select, Table, Tooltip, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  configurationConstants,
  projectCreationConstants,
  roleBasedAccessControlConstants,
} from "../../constants";
import {
  commonHelpers,
  rabcHelpers,
  spreadConfiguratorHelpers,
} from "../../helpers";
import {
  CREATE_SPREAD_CONFIGURATION_SAGA,
  CREATE_SUB_SPREAD_CONFIGURATION_SAGA,
  GET_CONFIGURATION_SAGA,
} from "../../redux/configuration/types";
import { UPLOAD_JSON_FILES } from "../../redux/uploads/types";
import CommonLoader from "../Loaders/loader";
import AddSubSpread from "../SpreadConfiguration/AddSubSpread";
import SpreadConfigurationModal from "../Spreadpopup/spreadpopup";

const { Option } = Select;

export const ImportData = () => {
  const { t } = useTranslation();
  const [spreadConfigForm] = Form.useForm();
  const dispatch = useDispatch();
  const reducerList = useSelector((state) => state);
  const project = useSelector((state) => state.projectReducer.selectedProject);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [selectedSpread, setSelectedSpread] = useState(null);
  const [selectedSubSpread, setSelectedSubSpread] = useState(null);
  const [showSpreadModal, setShowSpreadModal] = useState(false);
  const [showSubSpreadModal, setShowSubSpreadModal] = useState(false);
  const [spreads, setSpreads] = useState([]);
  const [subspreads, setSubspreads] = useState([]);
  const [selectedConfiguration, setSelectedConfiguration] = useState({});
  const [selectionType, setSelectionType] = useState("");
  const [isInitialRender, setInitialRender] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilesImported, setIsFilesImported] = useState(false);
  const [addSpreadLoader, setAddSpreadLoader] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [uploading, setUploading] = useState(false);

  const invalidFiles = reducerList?.uploadsReducer?.invalidFiles;

  let roleOfUser = commonHelpers.getRoleOfUserFromReduxState(
    reducerList?.auth?.userDetails
  );

  let doesHavePermissionToCreate = rabcHelpers.hasUserHasRole({
    roleName: roleOfUser,
    scope: roleBasedAccessControlConstants.scopes.pipeDetails.create,
  });

  const selectConfiguration = ({ type, configurationId }) => {
    setSelectionType(type);
    if (type === configurationConstants.selectionType.spread) {
      let selectedSpread = reducerList?.configurationReducer.configurationList?.find(
        (spread) => spread.id === configurationId
      );
      setSelectedConfiguration(selectedSpread);
    } else if (type === configurationConstants.selectionType.subSpread) {
      let allSubSpreads = [];
      reducerList?.configurationReducer.configurationList?.map((ite) => {
        ite.subSpreads?.map((subs) => allSubSpreads.push(subs));
      });

      let selectedSubSpread = allSubSpreads.find(
        (ite) => ite.id === configurationId
      );
      setSelectedConfiguration(selectedSubSpread);
    }
  };

  const [selectedRadio, setSelectedRadio] = useState();

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };

  const isSpreadIncomplete = reducerList?.configurationReducer?.configurationList
    ?.filter((spread) => spread.sequenceGenerationStatus !== "Completed")
    ?.some((spread) => spread.spreadName === selectedSpread);

  const selectDefaultSpreadInitial = (spreadId) => {
    selectConfiguration({
      type: configurationConstants.selectionType.spread,
      configurationId: spreadId,
    });
  };

  useEffect(() => {
    if (reducerList?.projectReducer?.selectedProject?.projectNumber) {
      setIsLoading(true);
      dispatch({
        type: GET_CONFIGURATION_SAGA,
        payload: {
          projectNumber:
            reducerList?.projectReducer?.selectedProject?.projectNumber,
        },
        cb: () => {},
      });
    }
  }, [reducerList?.projectReducer?.selectedProject?.projectNumber]);

  useEffect(() => {
    if (
      reducerList?.configurationReducer.configurationList?.length &&
      isInitialRender
    ) {
      selectDefaultSpreadInitial(
        commonHelpers.sortBySortOrder(
          reducerList?.configurationReducer.configurationList
        )?.[0]?.id
      );

      setInitialRender(false);
    }
  }, [reducerList?.configurationReducer.configurationList]);

  useEffect(() => {
    if (selectedSpread) {
      const selectedSpreadData = spreads.find(
        (spread) => spread.spreadName === selectedSpread
      );
      if (selectedSpreadData && selectedSpreadData.subSpreads) {
        setSubspreads(selectedSpreadData.subSpreads);
      } else {
        setSubspreads([]);
      }
    }
  }, [selectedSpread, spreads]);

  const addSpread = (values) => {
    let configurationList =
      reducerList?.configurationReducer?.configurationList || [];

    setShowSpreadModal(true);
    setAddSpreadLoader(true);

    let spreadName = spreadConfiguratorHelpers.generateSpreadString(
      configurationList?.map((ite) => ite?.spreadName)
    );

    let createSpreadPayload = {
      spreadName: spreadName,
      sortOrder: Number(spreadName?.split(" ")?.[1]),
      projectNumber:
        reducerList?.projectReducer?.selectedProject?.projectNumber,
      pipelineTypes: values.pipelineTypes,
      targetNumberOfPipes: values.targetNumberOfPipesInSpread,
      targetLengthOfSpread: values.targetLengthOfSpread,
      sequenceEndA: values.sequenceEndA,
      sequenceEndB: values.sequenceEndB,
    };

    dispatch({
      type: CREATE_SPREAD_CONFIGURATION_SAGA,
      payload: createSpreadPayload,
      cb: (newSpreadId) => {
        setAddSpreadLoader(false);
        setShowSpreadModal(false);

        const newSpread = {
          id: newSpreadId,
          spreadName: spreadName,
        };
        setSpreads([...spreads, newSpread]);

        setSelectedSpread(newSpread.spreadName);
        setSelectedSubSpread(
          configurationConstants.importDataSubSpread.anySubSpread
        );
      },
      err: () => {
        setShowSpreadModal(true);
        setAddSpreadLoader(false);
      },
    });
  };

  const addSubSpread = (formValues) => {
    let spreadData = reducerList?.configurationReducer.configurationList?.find(
      (spread) => spread.id === formValues.spreadId
    );
    setAddSpreadLoader(true);

    let alreadyExistsStringList = spreadData?.subSpreads?.map((ite) => {
      let sequence = ite?.subSpreadName?.split(" ")?.[2];
      return sequence?.substring(1, sequence.length);
    });

    let sortOrders =
      spreadData?.subSpreads?.map((ite) => ite?.sortOrder)?.length > 0
        ? spreadData?.subSpreads?.map((ite) => ite?.sortOrder)
        : [0];

    let maxSortOrder = Math.max(...sortOrders) + 1;

    let subSpreadName = commonHelpers.generateSequence(alreadyExistsStringList);
    let createSubSpreadPayload = {
      spreadId: formValues.spreadId,
      subSpreadName: `Sub ${spreadData.spreadName}${subSpreadName}`,
      sortOrder: maxSortOrder,
      projectNumber:
        reducerList?.projectReducer?.selectedProject?.projectNumber,
      pipelineTypes: formValues.pipelineTypes,
      targetNumberOfPipes: formValues.targetNumberOfPipesInSpread,
      targetLengthOfSpread: formValues.targetLengthOfSpread,
      sequenceEndA: formValues.sequenceEndA,
      sequenceEndB: formValues.sequenceEndB,
    };

    dispatch({
      type: CREATE_SUB_SPREAD_CONFIGURATION_SAGA,
      payload: createSubSpreadPayload,
      cb: (newSubSpreadId) => {
        const newSubSpread = {
          id: newSubSpreadId,
          subSpreadName: `Sub ${spreadData.spreadName}${subSpreadName}`,
        };

        setSubspreads([...subspreads, newSubSpread]);

        setSelectedSubSpread(newSubSpread.subSpreadName);

        setShowSubSpreadModal(false);
        setAddSpreadLoader(false);
      },
      err: () => {
        setShowSubSpreadModal(true);
        setAddSpreadLoader(false);
      },
    });
  };

  const handleSpreadChange = (value) => {
    setSelectedSpread(value);

    setSelectedSubSpread(
      configurationConstants.importDataSubSpread.anySubSpread
    );
    const selectedSpread = spreads.find((spread) => spread.id === value);
    if (selectedSpread && selectedSpread.subSpreads) {
      setSubspreads(selectedSpread.subSpreads);
    } else {
      setSubspreads([]);
    }
  };

  const handleSubSpreadChange = (value) => {
    setSelectedSubSpread(value);
  };

  const handleSpreadModalCancel = () => {
    setShowSpreadModal(false);
    spreadConfigForm.resetFields();
    setSelectedSpread(
      configurationConstants.importDataSpread.selectSpreadPlaceholder
    );
    setSelectedSubSpread(
      configurationConstants.importDataSubSpread.selectSubSpreadPlaceholder
    );
  };

  const handleSubSpreadModalCancel = () => {
    setShowSubSpreadModal(false);
    setSelectedSubSpread(
      configurationConstants.importDataSubSpread.anySubSpread
    );
  };

  const resetFields = () => {
    setSelectedSpread(null);
    setSelectedSubSpread(null);
    setIsFilesImported(false);
    setShowSpreadModal(false);
    setShowSubSpreadModal(false);
    setFilesToUpload([]);
    setFileNames([]);
    setErrorMessages([]);
    setDisableImportFields(true);
    setSelectedRadio();
  };

  useEffect(() => {
    if (project) {
      resetFields();
      setIsLoading(true);
      dispatch({
        type: GET_CONFIGURATION_SAGA,
        payload: { projectNumber: project.projectNumber },
        cb: () => setIsLoading(false),
      });
    }
  }, [project]);

  useEffect(() => {
    if (reducerList?.configurationReducer?.configurationList?.length) {
      const allSpreads = reducerList.configurationReducer.configurationList;
      setSpreads(allSpreads);
      // setSubspreads([]);
    }
  }, [reducerList?.configurationReducer?.configurationList]);

  const openInputDialog = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (e) => {
    const JSONFilesArray = Array.from(e.target.files);
    JSONFilesArray.length
      ? setIsFilesImported(true)
      : setIsFilesImported(false);
    setFilesToUpload([...JSONFilesArray]);
    const fileNamesList = JSONFilesArray.map((file) => file.name);
    setFileNames([...fileNamesList]);
  };

  const [errorMessages, setErrorMessages] = useState([]);

  const uploadJSONFiles = async () => {
    setUploading(true);
    try {
      const formData = new FormData();
      filesToUpload.forEach((file) => {
        formData.append("JSONFiles", file);
      });

      let payload = {
        projectNumber:
          reducerList?.projectReducer?.selectedProject?.projectNumber,
        files: formData,
      };

      selectedSpread
        ? (payload = { ...payload, spread: selectedSpread })
        : (payload = {
            ...payload,
            spread: configurationConstants.importDataSpread.anySpread,
          });

      selectedSubSpread
        ? (payload = { ...payload, subSpread: selectedSubSpread })
        : (payload = {
            ...payload,
            subSpread: configurationConstants.importDataSubSpread.anySubSpread,
          });

      dispatch({
        type: UPLOAD_JSON_FILES,
        ...payload,
        cd: (errorMessages) => {
          setUploading(false);
          setErrorMessages(errorMessages || []);
        },
        success: () => {
          setUploading(false);
          setErrorMessages("success");
        },
        error: () => {},
      });
    } catch (err) {
      console.error("Error during upload:", err);
    }
    setIsFilesImported(false);
    setErrorMessages([]);
  };

  const handleFileUploadDone = () => {
    setFilesToUpload([]);
    setFileNames([]);
    setIsFilesImported(false);
    setErrorMessages([]);
    resetFields();
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = filesToUpload.filter((_, i) => i !== index - 1);
    const updatedFileNames = fileNames.filter((_, i) => i !== index - 1);

    setFilesToUpload(updatedFiles);
    setFileNames(updatedFileNames);

    if (updatedFiles.length === 0) {
      setIsFilesImported(false);
      setErrorMessages([]);
    }
  };

  const errorMessageFinder = (inputFileName) => {
    const errorFileFound = invalidFiles.find(
      (errorFile) => errorFile.fileName === inputFileName
    );

    if (errorFileFound) {
      return errorFileFound.message;
    } else {
      return "";
    }
  };

  const handleCancelAllFiles = () => {
    setFilesToUpload([]);
    setFileNames([]);
    setIsFilesImported(false);
    setErrorMessages([]);
  };

  const [disableImportFields, setDisableImportFields] = useState(true);

  const handleSpreadCreate = () => {
    setShowSpreadModal(true);
  };
  const handleSubSpreadCreate = () => {
    setShowSubSpreadModal(true);
  };

  const handleNoButton = () => {
    setSelectedSpread(configurationConstants.importDataSpread.anySpread);
    setSelectedSubSpread(
      configurationConstants.importDataSubSpread.anySubSpread
    );
    setDisableImportFields(true);
  };

  const resetField = () => {
    setSelectedSpread(null);
    setSelectedSubSpread(null);
    setIsFilesImported(false);
    setShowSpreadModal(false);
    setShowSubSpreadModal(false);
    setFilesToUpload([]);
    setFileNames([]);
    setErrorMessages([]);
    setDisableImportFields(true);
  };

  const handleYesButton = () => {
    resetField();
    setDisableImportFields(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="initial-importData-load">
          <CommonLoader />
        </div>
      ) : (
        <>
          {fileNames.length === 0 ? (
            <div className="spreadImportData">
              <div className="spread-description-field">
                <span className="spread-description">
                  {t("importDataTab.importSpread&SubSpreadTitle")}
                </span>
                <Radio.Group
                  onChange={handleRadioChange}
                  value={selectedRadio}
                  className="yes-no-btn"
                >
                  <Radio
                    value={
                      projectCreationConstants.projectConstants
                        .addProjectFirstForm
                    }
                    onClick={handleYesButton}
                    className="assign-yes"
                  >
                    {t("sequenceTable.yes")}
                  </Radio>
                  <Radio
                    value={
                      projectCreationConstants.projectConstants
                        .addProjectSecondForm
                    }
                    onClick={handleNoButton}
                    className="assign-no"
                  >
                    {t("sequenceTable.no")}
                  </Radio>
                </Radio.Group>
              </div>
              <div className="import-spread-dropdown">
                <div className="first-line">
                  <label className="select-spread">Select Spread</label>
                  <Select
                    className="import-spread"
                    onChange={handleSpreadChange}
                    value={selectedSpread}
                    disabled={disableImportFields}
                  >
                    <Option
                      disabled={selectedSpread}
                      value={
                        configurationConstants.importDataSpread
                          .selectSpreadPlaceholder
                      }
                    >
                      {t("importDataTab.selectSpread")}
                    </Option>
                    {spreads.map((spread) => (
                      <Option key={spread.id} value={spread.spreadName}>
                        {spread.spreadName}
                      </Option>
                    ))}
                    <Option
                      value={configurationConstants.importDataSpread.anySpread}
                    >
                      {t("importDataTab.anyOption")}
                    </Option>
                  </Select>
                  {doesHavePermissionToCreate && (
                    <Tooltip title={t("configuration.createspread")}>
                      <Button
                        type="secondary"
                        className={
                          disableImportFields
                            ? "create-spread-disabled"
                            : "create-spread"
                        }
                        disabled={disableImportFields}
                        onClick={handleSpreadCreate}
                      >
                        <PlusOutlined className="icon-plus-del" />
                      </Button>
                    </Tooltip>
                  )}
                </div>
                <div className="second-line">
                  <label className="select-subSpread">Select SubSpread</label>
                  <Select
                    className="import-subspread"
                    onChange={handleSubSpreadChange}
                    value={selectedSubSpread}
                    disabled={!selectedSpread || disableImportFields}
                  >
                    <Option
                      disabled={selectedSpread}
                      value={
                        configurationConstants.importDataSubSpread
                          .selectSubSpreadPlaceholder
                      }
                    >
                      {t("importDataTab.selectSubSpread")}
                    </Option>
                    {subspreads.map((subspread) => (
                      <Option
                        key={subspread.id}
                        value={subspread.subSpreadName}
                      >
                        {subspread.subSpreadName}
                      </Option>
                    ))}
                    <Option
                      value={
                        configurationConstants.importDataSubSpread.anySubSpread
                      }
                    >
                      {t("importDataTab.anyOption")}
                    </Option>
                  </Select>
                  {doesHavePermissionToCreate && (
                    <Tooltip title={t("configuration.createSubspread")}>
                      <Button
                        type="secondary"
                        className={
                          disableImportFields || !isSpreadIncomplete
                            ? "create-subSpread-disabled"
                            : "create-subSpread"
                        }
                        disabled={disableImportFields || !isSpreadIncomplete}
                        onClick={handleSubSpreadCreate}
                      >
                        <PlusOutlined disabled className="icon-plus-del" />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="import-btn">
                {selectedRadio && (
                  <Button
                    type="primary"
                    className="import-btn-yes"
                    onClick={() => {
                      resetFields();
                    }}
                    disabled={
                      !selectedSpread && !selectedSubSpread && !selectedRadio
                    }
                  >
                    {t("common.cancelButtonText")}
                  </Button>
                )}
                {selectedSpread && (
                  <>
                    <input
                      type="file"
                      id="fileInput"
                      accept=".json"
                      multiple
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <Button
                      type="primary"
                      className="extend-primary-button"
                      onClick={openInputDialog}
                    >
                      {isFilesImported
                        ? t("importDataTab.dataImportedButtonText")
                        : t("importDataTab.importDataButtonText")}
                    </Button>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div>
              {fileNames.length > 0 && (
                <div className="spreadImportData-table">
                  <div className="selected-files-list">
                    <h5 className="selected-json-file">
                      {t("importDataTab.selectedJsonFiles")}
                    </h5>
                    <Table
                      dataSource={fileNames.map((fileName, index) => ({
                        fileName,
                        errorMessage: errorMessageFinder(fileName),
                        key: index + 1,
                      }))}
                      scroll={{ y: 200 }}
                      pagination={false}
                    >
                      <Table.Column
                        title={t("importDataTab.Slno")}
                        dataIndex="key"
                        key="key"
                      />
                      <Table.Column
                        title={t("importDataTab.fileName")}
                        dataIndex="fileName"
                        key="fileName"
                        render={(text) => (
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {text}
                          </div>
                        )}
                      />
                      {errorMessages.length > 0 ? (
                        <Table.Column
                          title={t("importDataTab.status")}
                          dataIndex="errorMessage"
                          key="errorMessage"
                          render={(text, record) => (
                            <span
                              style={
                                errorMessages === "success"
                                  ? { color: "green" }
                                  : record.errorMessage
                                  ? { color: "#f90023" }
                                  : { color: "green" }
                              }
                            >
                              {errorMessages === "success"
                                ? t("importDataTab.fileUploadSuccess")
                                : record.errorMessage
                                ? record.errorMessage
                                : t("importDataTab.fileUploadSuccess")}
                            </span>
                          )}
                        />
                      ) : (
                        <Table.Column
                          title={t("importDataTab.status")}
                          dataIndex=""
                          key="errorMessages"
                          render={() => (
                            <span>{t("importDataTab.yetToBeUploaded")}</span>
                          )}
                        />
                      )}
                      {errorMessages.length > 0 ? (
                        ""
                      ) : (
                        <Table.Column
                          title={t("importDataTab.action")}
                          key="action"
                          render={(text, record) => (
                            <button
                              onClick={() => handleRemoveFile(record.key)}
                              style={{
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                              }}
                            >
                              <CloseOutlined style={{ color: "#f90023" }} />
                            </button>
                          )}
                        />
                      )}
                    </Table>
                    <div className="json-upload">
                      {errorMessages === "success" ? (
                        ""
                      ) : (
                        <Button
                          className="cancel-all"
                          type="danger"
                          onClick={handleCancelAllFiles}
                          disabled={
                            errorMessages === "success"
                              ? false
                              : uploading
                              ? true
                              : false
                          }
                        >
                          Cancel
                        </Button>
                      )}
                      {errorMessages.length === 0 && (
                        <Button
                          type="primary"
                          className="import-btn-no"
                          onClick={uploadJSONFiles}
                          disabled={!isFilesImported}
                        >
                          {uploading
                            ? `${t("importDataTab.uploadingButtonText")}`
                            : `${t("importDataTab.uploadButtonText")}`}
                        </Button>
                      )}
                      {errorMessages === "success" && (
                        <Button
                          type="primary"
                          className="done-button"
                          onClick={handleFileUploadDone}
                        >
                          {t("importDataTab.done")}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
      {showSpreadModal && (
        <Modal
          width="40vw"
          title={t("configuration.createspread")}
          open={showSpreadModal}
          onCancel={handleSpreadModalCancel}
          className="spread-popup"
          footer={null}
        >
          <SpreadConfigurationModal
            visible={showSpreadModal}
            toggleLabels={handleSpreadModalCancel}
            selectConfiguration={selectedConfiguration}
            onSubmit={addSpread}
            configurationList={
              reducerList?.configurationReducer?.configurationList
            }
            selectionType={selectionType}
            addSpreadLoader={addSpreadLoader}
          />
        </Modal>
      )}
      {showSubSpreadModal && (
        <Modal
          width="40vw"
          title={t("configuration.createSubspread")}
          open={showSubSpreadModal}
          onCancel={handleSubSpreadModalCancel}
          className="subspread-popup"
          footer={null}
        >
          <AddSubSpread
            spreadList={reducerList?.configurationReducer?.configurationList?.filter(
              (spread) => spread.sequenceGenerationStatus !== "Completed"
            )}
            addSpreadLoader={addSpreadLoader}
            onSubmit={addSubSpread}
            toggleLabels={handleSubSpreadModalCancel}
          />
        </Modal>
      )}
    </>
  );
};
