import {
  PROJECT_LOADER_START,
  PROJECT_LOADER_STOP,
  PROJECT_SUBMITTING,
  SET_AVAILABLE_SPREAD_PIPE_COUNT,
  SET_AVAILABLE_SUBSPREAD_PIPE_COUNT,
  SET_PROJECT_LIST,
  SET_PROJECT_PIPE_COUNT,
  SET_SELECTED_PROJECT,
  SET_TOTAL_PIPE_COUNT,
  SET_USED_PIPE_COUNT,
} from "./types";

const initialState = {
  isProjectLoading: false,
  projectList: [],
  isProjectSubmitting: false,
  selectedProject: {},
  projectPipeCount: 0,
  totalPipeCount: 0,
  usedPipeCount: 0,
  spreadPipeCount: 0,
  subSpreadPipeCount: 0,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_LOADER_START: {
      return {
        ...state,
        isProjectLoading: true,
      };
    }

    case SET_PROJECT_PIPE_COUNT: {
      return {
        ...state,
        projectPipeCount: action.payload,
      };
    }

    case SET_TOTAL_PIPE_COUNT: {
      return {
        ...state,
        totalPipeCount: action.payload,
      };
    }

    case SET_USED_PIPE_COUNT: {
      return {
        ...state,
        usedPipeCount: action.payload,
      };
    }

    case SET_AVAILABLE_SPREAD_PIPE_COUNT: {
      return {
        ...state,
        spreadPipeCount: action.payload,
      };
    }

    case SET_AVAILABLE_SUBSPREAD_PIPE_COUNT: {
      return {
        ...state,
        subSpreadPipeCount: action.payload,
      };
    }

    case SET_PROJECT_LIST: {
      return {
        ...state,
        projectList: action.payload,
      };
    }

    case PROJECT_LOADER_STOP: {
      return {
        ...state,
        isProjectLoading: false,
      };
    }

    case PROJECT_SUBMITTING: {
      return {
        ...state,
        isProjectSubmitting: action.payload,
      };
    }

    case SET_SELECTED_PROJECT: {
      return {
        ...state,
        selectedProject: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default projectReducer;
