import { Button, Table } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { commonConstants } from "../../constants";

const PipeDataView = ({ filteredData, columns, onClose }) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(commonConstants.defaultPageSize);

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const pageSizeOptions = commonConstants.PipeDatapageSizeOptions;

  return (
    <div className="Sequence-view">
      <div className="table-container-sequence">
        <Table
          dataSource={filteredData}
          columns={columns}
          scroll={{
            y: "auto",
          }}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: pageSizeOptions,
            onShowSizeChange: handlePageSizeChange,
          }}
          className="pipeData-view-table"
        />
      </div>
      <div className="close-button-container">
        <Button type="primary" onClick={onClose} className="close-button">
          {t("Close")}
        </Button>
      </div>
    </div>
  );
};

export default PipeDataView;
