import { SET_CONFIGURATION_LISTS, SET_CONFIGURATION_LOADING } from "./types";

const initialState = {
  isConfigurationLoading: false,
  configurationList: [],
};

const configurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIGURATION_LOADING: {
      return {
        ...state,
        isConfigurationLoading: action.payload,
      };
    }

    case SET_CONFIGURATION_LISTS: {
      return {
        ...state,
        configurationList: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default configurationReducer;
