import { configurationConstants } from "../constants";

const getColumns = (t, sequenceFor) => {
  const columns = [
    {
      title: t("sequenceTable.siNo"),
      dataIndex: "key",
      width: 120,
    },
    ...[
      sequenceFor == configurationConstants.selectionType.spread
        ? {
            title: "Spread",
            dataIndex: "spread2Column",
            width: 150,
          }
        : {},
    ],

    {
      title: t("sequenceTable.pipeStart"),
      dataIndex: "pipeStart",
      width: 120,
    },
    {
      title: t("sequenceTable.pipeId"),
      dataIndex: "pipeId",
    },
    {
      title: t("sequenceTable.pipeEnd"),
      dataIndex: "pipeEnd",
      width: 120,
    },
    {
      title: t("sequenceTable.maxHilo"),
      dataIndex: "maxHilo",
      width: 120,
    },
    {
      title: t("sequenceTable.tdc"),
      dataIndex: "tdc",
      width: 120,
    },
  ].filter((ite) => ite.title);

  return columns;
};

const generateData = (sequenceList, spreadName) => {
  return sequenceList?.map((ite) => {
    return {
      key: parseInt(ite.SlNo) + 1,
      spread2Column: ite.subSpread ? ite.subSpread : spreadName,
      pipeStart: ite.pipeStart,
      pipeId: ite.pipeId,
      pipeEnd: ite.pipeEnd,
      maxHilo: Number(ite.maxHiLo)?.toFixed(2),
      tdc: Number(ite.tdc)?.toFixed(2),
    };
  });
};

const getColumnsWithoutHook = (t) => {
  return [
    {
      title: t("sequenceTable.siNo"),
      dataIndex: "key",
    },
    {
      title: t("sequenceTable.pipeStart"),
      dataIndex: "pipeStart",
    },
    {
      title: t("sequenceTable.pipeId"),
      dataIndex: "pipeId",
    },
    {
      title: t("sequenceTable.pipeEnd"),
      dataIndex: "pipeEnd",
    },
    {
      title: t("sequenceTable.maxHilo"),
      dataIndex: "maxHilo",
    },
    {
      title: t("sequenceTable.tdc"),
      dataIndex: "tdc",
    },
  ];
};

export { getColumns, generateData, getColumnsWithoutHook };
