const commonConstant = {
  maxFormFieldLength: 40,
};

const inputTypes = {
  input: "input",
  date: "date",
  switch: "switch",
  select: "select",
  number: "number",
};

const carouselConfig = [
  { width: 550, itemsToShow: 3, itemsToScroll: 3 },
  { width: 768, itemsToShow: 5, itemsToScroll: 5 },
  { width: 1200, itemsToShow: 6, itemsToScroll: 6 },
  { width: 1500, itemsToShow: 6, itemsToScroll: 6 },
];

const headerTittle = "Pipefitter";

const pageSizeOptions = ["10", "50", "100"];

const defaultPageSize = 50;

const ModalpageSizeOptions = ["50", "100"];

const PipeDatapageSizeOptions = ["50", "100"];

const showEmailModalFor = {
  configuration: "Configuration",
  sequence: "Sequence",
  projectSummary: "Project Summary",
  pipeData: "Pipe Data",
};

const reportTabKeys = {
  projectStatus: "projectStatus",
  pipeData: "pipeData",
  projectSummary: "projectSummary",
  pipelineargraph: "pipelineargraph",
  pipepolarview: "pipepolarview",
};

export {
  commonConstant,
  pageSizeOptions,
  headerTittle,
  inputTypes,
  carouselConfig,
  defaultPageSize,
  ModalpageSizeOptions,
  showEmailModalFor,
  PipeDatapageSizeOptions,
  reportTabKeys,
};
