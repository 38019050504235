import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";
import "./global-styles/base-style.scss";
import "./global-styles/antd-custom.scss";
import "./global-styles/light-theme.scss";
import "./global-styles/admin.scss";
import "./global-styles/admin-lightTheme.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
