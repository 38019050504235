import { get } from "lodash";

import {
  SIGNINSUCCESS,
  SIGNINFAILURE,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  SIGNUPSUCCESS,
  SIGNUPFAILURE,
  SETUSERDETAILS,
} from "../types";

const initialState = {
  isLoginLoading: false,
  isLogin: false,
  isSignUpLoading: false,
  userDetails: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN: {
      return {
        ...state,
        isLoginLoading: true,
      };
    }
    case SETUSERDETAILS: {
      return {
        ...state,
        userDetails: action.payload,
      };
    }
    case SIGNINSUCCESS: {
      const authChallenge = get(action, ["res", "challengeName"], "");
      const challengeName = authChallenge;
      return {
        ...state,
        challengeName,
        isLogin: true,
        isLoginLoading: false,
      };
    }
    case SIGNINFAILURE: {
      return {
        ...state,
        isLogin: false,
        isLoginLoading: false,
      };
    }

    case SIGNOUT: {
      return {
        isLogin: false,
      };
    }

    case SIGNUP: {
      return {
        ...state,
        isSignUpLoading: true,
      };
    }

    case SIGNUPSUCCESS: {
      const signedUp = true;
      return {
        ...state,
        signedUp,
        isSignUpLoading: false,
      };
    }

    case SIGNUPFAILURE: {
      return {
        ...state,
        isSignUpLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default authReducer;
