import { SearchOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip, Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const SearchModal = (props) => {
  const { onSearch, searchText, handleClickSearch } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const inputRef = useRef(null);
  const { t } = useTranslation();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    const callBack = () => {
      setIsModalVisible(false);
    };
    handleClickSearch({
      callBack,
    });
  };

  useEffect(() => {
    if (isModalVisible) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);
    }
  }, [isModalVisible]);

  return (
    <>
      <Tooltip placement="topLeft" title="Search Project">
        <Button className="search-top" shape="circle" onClick={showModal}>
          <SearchOutlined />
        </Button>
      </Tooltip>
      <Modal
        title={t("searchBar.searchproject")}
        open={isModalVisible}
        onOk={() => handleOk()}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <div className="d-flex position-relative align-items-center">
          <Input
            ref={inputRef}
            size="large"
            placeholder="Enter Project Name or Number"
            className="search-input"
            onChange={onSearch}
            value={searchText}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleOk();
              }
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default SearchModal;
