import { SET_CONFIGURATION_LISTS, SET_CONFIGURATION_LOADING } from "./types";

const startConfigurationLoader = (payload) => ({
  type: SET_CONFIGURATION_LOADING,
  payload,
});

const setConfigurationList = (payload) => ({
  type: SET_CONFIGURATION_LISTS,
  payload,
});

export { startConfigurationLoader, setConfigurationList };
