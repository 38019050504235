import { Tabs } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ImportData } from "../ImportData/ImportData";
import PipeFilter from "../ImportData/PipeFilter";

const { TabPane } = Tabs;

let tabKeys = {
  importData: "importData",
  pipefilter: "pipefilter",
};

const PipeDetails = () => {
  const [activeKey, setActiveKey] = useState(tabKeys.importData);
  const { t } = useTranslation();

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  return (
    <div className="pipe-details" style={{ display: "flex" }}>
      <Tabs
        className="pipe-tabs"
        defaultActiveKey={activeKey}
        style={{ flex: "70%" }}
        onChange={onChange}
      >
        <TabPane
          tab={t("pipeDetails.importData")}
          key={tabKeys.importData}
          style={{ position: "relative" }}
        >
          {activeKey == tabKeys.importData && <ImportData />}
        </TabPane>
        <TabPane
          tab={t("pipeDetails.pipeDataDetails")}
          key={tabKeys.pipefilter}
          style={{ position: "relative" }}
        >
          {activeKey == tabKeys.pipefilter && <PipeFilter />}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PipeDetails;
