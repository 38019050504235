import { Button, Table, Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { commonConstants } from "../../constants";
import { sequenceHelpers } from "../../helpers";

const SequenceView = ({
  sequenceList,
  currentPage,
  rowsPerPage,
  totalCount,
  handleTableChange,
  selectionType,
  spreadName,
  onClose,
}) => {
  const { t } = useTranslation();
  const columns = sequenceHelpers.getColumns(t, selectionType);
  const data = sequenceHelpers.generateData(sequenceList, spreadName);

  if (columns.length > 0) {
    const lastColumnIndex = columns.length - 1;
    columns[lastColumnIndex].title = (
      <span>
        {columns[lastColumnIndex].title}
        <Tooltip placement="topRight" title={t("sequenceTable.tdcInfo")}>
          <sup className="sup">ⓘ</sup>
        </Tooltip>
      </span>
    );
  }

  return (
    <div className="Sequence-view">
      <div className="table-container-sequence">
        <Table
          dataSource={data}
          columns={columns}
          scroll={{
            y: "auto",
          }}
          pagination={{
            current: currentPage,
            pageSize: rowsPerPage,
            total: totalCount,
            showSizeChanger: true,
            pageSizeOptions: commonConstants.ModalpageSizeOptions,
          }}
          className="custom-table"
          onChange={(e) => handleTableChange(e)}
        />
      </div>
      <div className="close-button-container">
        <Button type="primary" onClick={onClose} className="close-button">
          {t("Close")}
        </Button>
      </div>
    </div>
  );
};

export default SequenceView;
