import get from "lodash/get";

import {
  ADD_ROLE,
  ADD_ROLE_FAILURE,
  ADD_ROLE_SUCCESS,
  FETCH_ROLES,
  FETCH_ROLES_FAILURE,
  FETCH_ROLES_SUCCESS,
} from "./types";

const initialState = {
  roles: [],
  isRolesLoading: false,
};

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROLES: {
      return {
        ...state,
        isRolesLoading: true,
      };
    }

    case FETCH_ROLES_SUCCESS: {
      const resData = get(action, "response", []);
      return {
        ...state,
        roles: resData.rows,
        isRolesLoading: false,
      };
    }

    case FETCH_ROLES_FAILURE: {
      return {
        ...state,
        isRolesLoading: false,
      };
    }

    case ADD_ROLE: {
      return {
        ...state,
        isRolesLoading: true,
      };
    }

    case ADD_ROLE_SUCCESS: {
      return {
        ...state,
        isRolesLoading: false,
      };
    }

    case ADD_ROLE_FAILURE: {
      return {
        ...state,
        isRolesLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default roleReducer;
