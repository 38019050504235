import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Tree, Modal, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "../../locales/en.json";
import {
  configurationConstants,
  projectCreationConstants,
  roleBasedAccessControlConstants,
} from "../../constants";
import {
  commonHelpers,
  rabcHelpers,
  spreadConfiguratorHelpers,
} from "../../helpers";
import {
  CREATE_SPREAD_CONFIGURATION_SAGA,
  CREATE_SUB_SPREAD_CONFIGURATION_SAGA,
  DELETE_SPREAD_CONFIGURATION_SAGA,
  DELETE_SUB_SPREAD_CONFIGURATION_SAGA,
  GET_CONFIGURATION_SAGA,
  SORT_SPREAD_CONFIGURATION_SAGA,
  SORT_SUB_SPREAD_CONFIGURATION_SAGA,
} from "../../redux/configuration/types";
import CommonLoader from "../Loaders/loader";
import AddSubSpread from "../SpreadConfiguration/AddSubSpread";
import SpreadConfigurationForm from "../SpreadConfiguration/SpreadConfigurationForm";
import "../../global-styles/antd-custom.scss";
import SpreadConfigurationModal from "../Spreadpopup/spreadpopup";

const { TreeNode } = Tree;

const Configuration = () => {
  const { t } = useTranslation();
  const reducerList = useSelector((state) => state);
  const dispatch = useDispatch();

  let roleOfUser = commonHelpers.getRoleOfUserFromReduxState(
    reducerList?.auth?.userDetails
  );

  let doesHavePermissionToCreate = rabcHelpers.hasUserHasRole({
    roleName: roleOfUser,
    scope: roleBasedAccessControlConstants.scopes.configuration.create,
  });

  let doesHavePermissionToDelete = rabcHelpers.hasUserHasRole({
    roleName: roleOfUser,
    scope: roleBasedAccessControlConstants.scopes.configuration.delete,
  });

  let doesHavePermissionToEditConfiguration = rabcHelpers.hasUserHasRole({
    roleName: roleOfUser,
    scope: roleBasedAccessControlConstants.scopes.configuration.edit,
  });

  const [labelsVisible, setLabelsVisible] = useState(false);

  const [selectedKey, setSelectedKey] = useState("0");

  const toggleLabels = () => {
    if (isLoading) {
      return;
    }
    setSelectedRadio(
      projectCreationConstants.projectConstants.addProjectFirstForm
    );
    setLabelsVisible(!labelsVisible);
  };

  const [isConfigurationLoading, setIsConfigurationLoading] = useState(false);

  useEffect(() => {
    if (reducerList?.projectReducer?.selectedProject?.projectNumber) {
      setIsLoading(true);
      setIsConfigurationLoading(true);
      dispatch({
        type: GET_CONFIGURATION_SAGA,
        payload: {
          projectNumber:
            reducerList?.projectReducer?.selectedProject?.projectNumber,
        },
        cb: () => {
          setIsLoading(false);
          setIsConfigurationLoading(false);
        },
      });

      resetConfigurationSelection();
    }
  }, [reducerList?.projectReducer?.selectedProject?.projectNumber]);

  const [addSpreadLoader, setAddSpreadLoader] = useState(false);

  const addSpread = (values) => {
    setAddSpreadLoader(true);
    let configurationList =
      reducerList?.configurationReducer?.configurationList || [];

    let spreadName = spreadConfiguratorHelpers.generateSpreadString(
      configurationList?.map((ite) => ite?.spreadName)
    );

    let createSpreadPayload = {
      spreadName: spreadName,
      sortOrder: Number(spreadName?.split(" ")?.[1]),
      projectNumber:
        reducerList?.projectReducer?.selectedProject?.projectNumber,
      pipelineTypes: values.pipelineTypes,
      targetNumberOfPipes: values.targetNumberOfPipesInSpread,
      targetLengthOfSpread: values.targetLengthOfSpread,
      sequenceEndA: values.sequenceEndA,
      sequenceEndB: values.sequenceEndB,
    };

    dispatch({
      type: CREATE_SPREAD_CONFIGURATION_SAGA,
      payload: createSpreadPayload,
      cb: () => {
        toggleLabels();
        setAddSpreadLoader(false);
      },
      err: () => {
        setAddSpreadLoader(false);
      },
    });
  };

  const addSubSpread = (formValues) => {
    let spreadData = reducerList?.configurationReducer.configurationList?.find(
      (spread) => spread.id == formValues.spreadId
    );
    setAddSpreadLoader(true);

    let alreadyExistsStringList = spreadData?.subSpreads?.map((ite) => {
      let sequence = ite?.subSpreadName?.split(" ")?.[2];
      return sequence?.substring(1, sequence.length);
    });

    let sortOrders =
      spreadData?.subSpreads?.map((ite) => ite?.sortOrder)?.length > 0
        ? spreadData?.subSpreads?.map((ite) => ite?.sortOrder)
        : [0];

    let maxSortOrder = Math.max(...sortOrders) + 1;

    let subSpreadName = commonHelpers.generateSequence(alreadyExistsStringList);
    let createSubSpreadPayload = {
      spreadId: formValues.spreadId,
      subSpreadName: `Sub ${spreadData.spreadName}${subSpreadName}`,
      sortOrder: maxSortOrder,
      projectNumber:
        reducerList?.projectReducer?.selectedProject?.projectNumber,
      pipelineTypes: formValues.pipelineTypes,
      targetNumberOfPipes: formValues.targetNumberOfPipesInSpread,
      targetLengthOfSpread: formValues.targetLengthOfSpread,
      sequenceEndA: formValues.sequenceEndA,
      sequenceEndB: formValues.sequenceEndB,
    };

    dispatch({
      type: CREATE_SUB_SPREAD_CONFIGURATION_SAGA,
      payload: createSubSpreadPayload,
      cb: () => {
        setAddSpreadLoader(false);
        toggleLabels();
      },
      err: () => {
        setAddSpreadLoader(false);
      },
    });
  };

  const [selectedConfiguration, setSelectedConfiguration] = useState({});
  const [selectionType, setSelectionType] = useState("");

  const resetConfigurationSelection = () => {
    setSelectedConfiguration({});
    setSelectionType("");
    setInitialRender(true);
  };

  const selectConfiguration = ({ type, configurationId }) => {
    setSelectionType(type);
    if (type == configurationConstants.selectionType.spread) {
      let selectedSpread =
        reducerList?.configurationReducer.configurationList?.find(
          (spread) => spread.id == configurationId
        );
      setSelectedConfiguration(selectedSpread);
    } else if (type == configurationConstants.selectionType.subSpread) {
      let allSubSpreads = [];
      reducerList?.configurationReducer.configurationList?.map((ite) => {
        ite.subSpreads?.map((subs) => allSubSpreads.push(subs));
      });

      let selectedSubSpread = allSubSpreads.find(
        (ite) => ite.id == configurationId
      );
      setSelectedConfiguration(selectedSubSpread);
    }
  };

  const [selectedRadio, setSelectedRadio] = useState();

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };

  const [isInitialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (
      reducerList?.configurationReducer.configurationList?.length &&
      isInitialRender
    ) {
      selectDefaultSpreadInitial(
        commonHelpers.sortBySortOrder(
          reducerList?.configurationReducer.configurationList
        )?.[0]?.id
      );

      setInitialRender(false);
    }
  }, [reducerList?.configurationReducer.configurationList]);

  const selectDefaultSpreadInitial = (spreadId) => {
    selectConfiguration({
      type: configurationConstants.selectionType.spread,
      configurationId: spreadId,
    });
  };

  useEffect(() => {
    setSelectedKey("0");
  }, [isConfigurationLoading]);

  const handleClick = (e, info) => {
    setSelectedKey(info.key);
    switch (info.selectionType) {
      case configurationConstants.selectionType.spread:
        selectConfiguration({
          type: configurationConstants.selectionType.spread,
          configurationId: info.id,
        });
        break;
      case configurationConstants.selectionType.subSpread:
        selectConfiguration({
          type: configurationConstants.selectionType.subSpread,
          configurationId: info.id,
        });
        break;
      default:
        break;
    }
  };

  const onDropSpread = (info) => {
    let sortPayload = {
      movedSpreadId: info.dragNode.id,
      currentPosition: info.dragNode.sortOrder,
      targetPosition: info.dropPosition,
      projectNumber:
        reducerList?.projectReducer?.selectedProject?.projectNumber,
    };

    if (sortPayload.targetPosition < 0) {
      return;
    }

    setIsLoading(true);

    dispatch({
      type: SORT_SPREAD_CONFIGURATION_SAGA,
      payload: sortPayload,
      finally: () => {
        setIsLoading(false);
      },
    });
  };

  const onDropSubSpread = (info) => {
    let sortPayload = {
      movedSubSpreadId: info.dragNode.id,
      currentPosition: info.dragNode.sortOrder,
      targetPosition: info.dropPosition,
      projectNumber:
        reducerList?.projectReducer?.selectedProject?.projectNumber,
      spreadId: info.dragNode.spreadId,
    };

    if (sortPayload.targetPosition < 0) {
      return;
    }

    setIsLoading(true);

    dispatch({
      type: SORT_SUB_SPREAD_CONFIGURATION_SAGA,
      payload: sortPayload,
      finally: () => {
        setIsLoading(false);
      },
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      reducerList?.configurationReducer.configurationList?.length &&
      !isInitialRender
    ) {
      if (!selectedConfiguration) {
        selectDefaultSpreadInitial(
          commonHelpers.sortBySortOrder(
            reducerList?.configurationReducer.configurationList
          )?.[0]?.id
        );
      } else {
        let existingConfiguration =
          reducerList?.configurationReducer.configurationList.find(
            (item) => item.id === selectedConfiguration.id
          );
        if (!existingConfiguration) {
          selectDefaultSpreadInitial(
            commonHelpers.sortBySortOrder(
              reducerList?.configurationReducer.configurationList
            )?.[0]?.id
          );
        }
      }
    }
  }, [reducerList?.configurationReducer.configurationList]);

  const onDrop = (info) => {
    switch (info.dragNode.selectionType) {
      case configurationConstants.selectionType.spread: {
        onDropSpread(info);
        break;
      }
      case configurationConstants.selectionType.subSpread: {
        onDropSubSpread(info);
        break;
      }
    }
  };

  const deleteSpread = () => {
    setIsLoading(true);
    switch (selectionType) {
      case configurationConstants.selectionType.spread:
        {
          let oldConfigurationList =
            reducerList?.configurationReducer.configurationList;
          dispatch({
            type: DELETE_SPREAD_CONFIGURATION_SAGA,
            payload: {
              spreadId: selectedConfiguration.id,
              projectNumber: selectedConfiguration.projectNumber,
            },
            finally: () => {
              let nextItem = commonHelpers.deleteAndSelectNext({
                itemIdToDelete: selectedConfiguration.id,
                items: oldConfigurationList,
              });
              setSelectedKey("0");
              setIsLoading(false);
              setSelectedConfiguration(nextItem);
              setSelectionType(configurationConstants.selectionType.spread);
            },
          });
        }
        break;
      case configurationConstants.selectionType.subSpread:
        {
          let oldSubSpreadList = [];
          reducerList?.configurationReducer.configurationList?.map((ite) => {
            ite.subSpreads?.map((subs) => oldSubSpreadList.push(subs));
          });

          dispatch({
            type: DELETE_SUB_SPREAD_CONFIGURATION_SAGA,
            payload: {
              subSpreadId: selectedConfiguration.id,
              projectNumber: selectedConfiguration.projectNumber,
            },
            finally: () => {
              let nextItem = commonHelpers.deleteAndSelectNext({
                itemIdToDelete: selectedConfiguration.id,
                items: oldSubSpreadList,
              });
              setIsLoading(false);
              setSelectedConfiguration(nextItem);
              setSelectionType(
                nextItem.id
                  ? configurationConstants.selectionType.subSpread
                  : configurationConstants.selectionType.spread
              );
              setSelectedKey("0");
            },
          });
        }
        break;
      default:
        break;
    }
  };

  const enableDeleteButton = () => {
    if (selectionType === configurationConstants.selectionType.spread) {
      return !(
        reducerList?.configurationReducer?.configurationList?.length === 1
      );
    }
    if (selectionType === configurationConstants.selectionType.subSpread) {
      return true;
    }
  };

  const showDeleteModal = () => {
    let deleteMessage = "";
    if (
      selectionType === configurationConstants.selectionType.spread ||
      selectedConfiguration?.subSpreads?.length === 0
    ) {
      deleteMessage = t("configuration.deleteSpread");
    } else if (
      selectionType === configurationConstants.selectionType.subSpread
    ) {
      deleteMessage = t("configuration.deleteSubSpread");
    }
    Modal.confirm({
      title: deleteMessage,
      okText: <span style={{ color: "black" }}>{t("sequenceTable.yes")}</span>,
      cancelText: t("sequenceTable.no"),
      width: 600,
      className: "custom-approve-modal",
      onOk: deleteSpread,
    });
  };
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    setUserRole(
      commonHelpers.getRoleOfUserFromReduxState(reducerList?.auth?.userDetails)
    );
  }, [reducerList?.auth?.userDetails]);

  const isTechnicianOrCustomer =
    userRole === roleBasedAccessControlConstants.role.technician ||
    userRole === roleBasedAccessControlConstants.role.customer;

  return (
    <>
      {isLoading ? (
        <div className="initial-sequence-load">
          <CommonLoader />
        </div>
      ) : (
        <div className="first-parent">
          {selectionType && selectedConfiguration && !isConfigurationLoading ? (
            <div className="left-div">
              <div
                className={
                  isTechnicianOrCustomer ? "no-buttons" : "three-buttons"
                }
              >
                {doesHavePermissionToCreate && (
                  <div onClick={toggleLabels} className="plus-div">
                    <PlusOutlined className="icon-plus-del" />
                  </div>
                )}
                {labelsVisible && doesHavePermissionToCreate ? (
                  <Modal
                    title="Add Configuration"
                    width="40vw"
                    open={labelsVisible}
                    onCancel={toggleLabels}
                    className="add-configuration"
                    footer={null}
                  >
                    <Radio.Group
                      onChange={handleRadioChange}
                      value={selectedRadio}
                    >
                      <Radio
                        value={
                          projectCreationConstants.projectConstants
                            .addProjectFirstForm
                        }
                      >
                        Spread
                      </Radio>
                      <Radio
                        value={
                          projectCreationConstants.projectConstants
                            .addProjectSecondForm
                        }
                      >
                        SubSpread
                      </Radio>
                    </Radio.Group>
                    {selectedRadio ===
                      projectCreationConstants.projectConstants
                        .addProjectFirstForm && (
                      <SpreadConfigurationModal
                        selectConfiguration={selectedConfiguration}
                        onSubmit={addSpread}
                        configurationList={
                          reducerList?.configurationReducer?.configurationList
                        }
                        selectionType={selectionType}
                        addSpreadLoader={addSpreadLoader}
                        toggleLabels={toggleLabels}
                      />
                    )}
                    {selectedRadio ===
                      projectCreationConstants.projectConstants
                        .addProjectSecondForm && (
                      <AddSubSpread
                        spreadList={reducerList?.configurationReducer?.configurationList?.filter(
                          (spread) =>
                            spread.sequenceGenerationStatus !== "Completed"
                        )}
                        addSpreadLoader={addSpreadLoader}
                        onSubmit={addSubSpread}
                        toggleLabels={toggleLabels}
                      />
                    )}
                  </Modal>
                ) : (
                  ""
                )}

                {doesHavePermissionToDelete && (
                  <div className="delete-div">
                    <DeleteOutlined
                      onClick={() => {
                        if (enableDeleteButton()) {
                          showDeleteModal();
                        }
                      }}
                      disabled={enableDeleteButton()}
                      className={
                        enableDeleteButton() ? "icon-plus-del" : "icon-grey"
                      }
                    />
                  </div>
                )}
              </div>

              <div className="tree-div">
                <Tree
                  defaultExpandAll
                  draggable
                  className="parent-tree"
                  onDrop={onDrop}
                  onClick={handleClick}
                  selectedKeys={[selectedKey]}
                >
                  {commonHelpers
                    .sortBySortOrder(
                      reducerList?.configurationReducer.configurationList
                    )
                    ?.map((spread, index) => {
                      return (
                        <TreeNode
                          title={spread.spreadName}
                          id={spread.id}
                          selectionType={
                            configurationConstants.selectionType.spread
                          }
                          key={`${index}`}
                          sortOrder={spread.sortOrder}
                          selected={true}
                        >
                          {commonHelpers
                            .sortBySortOrder(spread.subSpreads)
                            ?.map((subSpread, subIndex) => {
                              return (
                                <TreeNode
                                  title={`${subSpread.subSpreadName.replace(
                                    " ",
                                    ""
                                  )}`}
                                  key={`${index}-${subIndex}`}
                                  selectionType={
                                    configurationConstants.selectionType
                                      .subSpread
                                  }
                                  id={subSpread.id}
                                  sortOrder={subSpread.sortOrder}
                                  spreadId={subSpread.spreadId}
                                />
                              );
                            })}
                        </TreeNode>
                      );
                    })}
                </Tree>
              </div>
            </div>
          ) : (
            ""
          )}

          {selectionType && selectedConfiguration ? (
            <div className="right-div">
              <SpreadConfigurationForm
                doesHavePermissionToEditConfiguration={
                  doesHavePermissionToEditConfiguration
                }
                selectConfiguration={selectedConfiguration}
                configurationList={
                  reducerList?.configurationReducer?.configurationList
                }
                selectionType={selectionType}
                setSelectedKey={setSelectedKey}
              />
            </div>
          ) : (
            <div className="initial-loader">
              <CommonLoader />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Configuration;
