const PipelineTypesEnum = {
  Unconstraint: "Unconstraint",
  Predetermined: "Predetermined",
  Continue: "Continue",
  Double: "Double",
};

const selectionType = {
  spread: "Spread",
  subSpread: "SubSpread",
};

const sequenceGenerationStatus = {
  Pending: "Pending",
  Started: "Started",
  InProgress: "InProgress",
  Completed: "Completed",
  ErrorOccurred: "ErrorOccurred",
};

const importDataSpread = {
  selectSpread: "Select a Spread",
  createSpread: "Create New Spread",
  anySpread: "Any",
  selectSpreadPlaceholder: null,
};
const importDataSubSpread = {
  selectSubSpread: "Select a SubSpread",
  createSubSpread: "Create New SubSpread",
  anySubSpread: "Any",
  selectSubSpreadPlaceholder: null,
};

const sequenceStatusErrorMessages = {
  invalidInputParametersError:
    "Input Parameters are invalid for the algorithm; Pass correct Project no, Spread/Subspread ID's",
  zeroPipesAvailableError:
    "Available number of pipes are zero for the Spread/Subspread. Check pipe details tab",
  meanMaxPendingError: "Mean max calculations are pending for the project!",
  fitMatrixExceptionError: "Exception raised while building a fit matrix",
  invalidConfigurationError:
    "Invalid Configuration(s), Check the project configuration",
  invalidResponseFromDbError:
    "Failed to receive a valid response from the database. Check the AWS Cloud Watch for the detailed error.",
};

export {
  PipelineTypesEnum,
  selectionType,
  sequenceGenerationStatus,
  importDataSpread,
  importDataSubSpread,
  sequenceStatusErrorMessages,
};
