import {
  SET_PROJECT_STATUS_PIPE_DATA,
  SET_REPORTS_PIPE_DATA_GRAPH,
  SET_REPORTS_PIPE_STATISTICS,
  SET_REPORTS_DATA_LOADING,
  SET_PROJECT_SUMMARY_DATA,
  SET_REPORTS_PIPE_STATUS,
} from "./types";

const initialState = {
  isReportsLoading: false,
  pipeStatistics: {},
  pipeDataGraph: [],
  projectStatusPipeData: [],
  projectSummaryData: [],
  pipeStatusData: {},
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORTS_DATA_LOADING: {
      return {
        ...state,
        isReportsLoading: action.payload,
      };
    }
    case SET_REPORTS_PIPE_STATISTICS: {
      return {
        ...state,
        pipeStatistics: action.payload,
      };
    }
    case SET_REPORTS_PIPE_STATUS: {
      return {
        ...state,
        pipeStatusData: action.payload,
      };
    }

    case SET_REPORTS_PIPE_DATA_GRAPH: {
      return {
        ...state,
        pipeDataGraph: action.payload,
      };
    }

    case SET_PROJECT_STATUS_PIPE_DATA: {
      return {
        ...state,
        projectStatusPipeData: action.payload,
      };
    }

    case SET_PROJECT_SUMMARY_DATA: {
      return {
        ...state,
        projectSummaryData: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reportsReducer;
