import get from "lodash/get";

import {
  FETCH_CLIENTS,
  SET_CLIENTS,
  SET_CLIENT_FAILURE,
  ADD_CLIENT,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILURE,
} from "../types";

const initialState = {
  isClientLoading: false,
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENTS: {
      const clientArr = get(action, ["response", "data"], []);
      const clientData = [];
      for (let i = 0; i < clientArr.length; i += 1) {
        const id = get(clientArr[i], "id", 0);
        const name = get(clientArr[i], "name", "");
        const createdAt = get(clientArr[i], "createdAt", "");
        const updatedAt = get(clientArr[i], "updatedAt", "");
        if (name !== "") {
          clientData[i] = {
            id,
            name,
            createdAt,
            updatedAt,
          };
        }
      }

      return {
        ...state,
        clientData,
        isClientLoading: false,
      };
    }

    case FETCH_CLIENTS: {
      return {
        ...state,
        isClientLoading: true,
      };
    }

    case SET_CLIENT_FAILURE: {
      return {
        ...state,
        isClientLoading: false,
      };
    }

    case ADD_CLIENT: {
      return {
        ...state,
        isClientLoading: true,
      };
    }

    case ADD_CLIENT_SUCCESS: {
      const clientArr = get(action, "response", {});
      const cliData = get(state, "clientData", []);
      const newData = [...cliData, clientArr];
      return {
        ...state,
        clientData: newData,
        isClientLoading: false,
      };
    }

    case ADD_CLIENT_FAILURE: {
      return {
        ...state,
        isClientLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default clientReducer;
