import { commonConstants, configurationConstants } from "../constants";

let { inputTypes } = commonConstants;

const validatePositiveInteger = (_, value) => {
  if (value && !Number.isInteger(Number(value))) {
    return Promise.reject("Enter a valid integer");
  }
  if (Number(value) == 0) {
    return Promise.reject("Enter a non-zero positive number");
  } else if (Number(value) < 1) {
    return Promise.reject("Enter a positive number");
  }
  return Promise.resolve();
};

const validatePositiveDecimal = (value) => {
  const floatValue = parseFloat(value);

  if (value && isNaN(parseFloat(floatValue))) {
    return Promise.reject("Please enter a valid number");
  }
  if (floatValue == 0) {
    return Promise.reject("Enter a non-zero positive number");
  } else if (floatValue < 1) {
    return Promise.reject(`Please enter positive value`);
  }
  return Promise.resolve();
};

const spreadConfigurationForm = ({ sequenceList }) => {
  return {
    targetNumberOfPipesInSpread: {
      type: inputTypes.input,
      label: "configuration.targetNumberOfPipesInSpread",
      labelSubSpread: "configuration.targetNumberOfPipesInSubSpread",
      placeholder: "configuration.targetNumberOfPipesInSpreadPlaceholder",
      placeholderSubSpread:
        "configuration.targetNumberOfPipesInSubSpreadPlaceholder",
      rules: [
        {
          required: true,
          message: "Please input Target Number of Pipes in Spread!",
        },
        {
          isValidator: true,
          validator: (_, value) => {
            return validatePositiveInteger(_, value);
          },
        },
      ],
    },
    targetLengthOfSpread: {
      type: inputTypes.input,
      label: "configuration.targetLengthOfSpread",
      labelSubSpread: "configuration.targetLengthOfSubSpread",
      placeholder: "configuration.targetLengthOfSpreadPlaceholder",
      placeholderSubSpread: "configuration.targetLengthOfSubSpreadPlaceholder",
      rules: [
        { required: false, message: "Please input Target Length of Spread!" },
        {
          isValidator: true,
          validator: (_, value) => {
            return validatePositiveDecimal(value);
          },
        },
      ],
    },
    pipelineTypes: {
      type: inputTypes.select,
      label: "configuration.pipelineTypes",
      placeholder: "configuration.pipelineTypesPlaceholder",
      rules: [{ required: true, message: "Please select a Pipeline Type!" }],
      options: [
        {
          value: configurationConstants.PipelineTypesEnum.Continue,
          label: configurationConstants.PipelineTypesEnum.Continue,
        },
        // {
        //   value: configurationConstants.PipelineTypesEnum.Double,
        //   label: configurationConstants.PipelineTypesEnum.Double,
        // },
        // {
        //   value: configurationConstants.PipelineTypesEnum.Predetermined,
        //   label: configurationConstants.PipelineTypesEnum.Predetermined,
        // },
        {
          value: configurationConstants.PipelineTypesEnum.Unconstraint,
          label: configurationConstants.PipelineTypesEnum.Unconstraint,
        },
      ],
    },
    sequenceEndA: {
      type: inputTypes.select,
      label: "configuration.sequenceEndA",
      placeholder: "configuration.sequenceEndAPlaceholder",
      rules: [{ required: true, message: "Please select an End Sequence" }],
      options: sequenceList,
    },
    // sequenceEndB: {
    //   type: inputTypes.select,
    //   label: "configuration.sequenceEndB",
    //   placeholder: "configuration.sequenceEndBPlaceholder",
    //   rules: [{ required: false, message: "Please select an End Sequence" }],
    //   options: sequenceList,
    // },
  };
};

const generateSpreadString = (arr) => {
  const maxNumber = Math.max(
    ...arr.map((item) => parseInt(item.split(" ")[1]) || 0)
  );

  const nextSpread = `Spread ${maxNumber + 1}`;

  return nextSpread;
};

const isAnySequenceRelatedParameterChangedForConfiguration = ({
  oldDataPipeLineType,
  oldDataTargetNumberOfPipes,
  updatedPipeLineType,
  updatedTargetNumberOfPipes,
}) => {
  if (oldDataPipeLineType != updatedPipeLineType) {
    return true;
  }

  if (oldDataTargetNumberOfPipes != updatedTargetNumberOfPipes) {
    return true;
  }

  return false;
};

export {
  spreadConfigurationForm,
  generateSpreadString,
  isAnySequenceRelatedParameterChangedForConfiguration,
};
