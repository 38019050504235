import { EyeOutlined } from "@ant-design/icons";
import { Table, Button, Modal, Tooltip, Space, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PipeDataView from "./PipeData-View";
import { commonConstants, configurationConstants } from "../../constants";
import { pipeFilterColumns } from "../../helpers";
import { GET_CONFIGURATION_SAGA } from "../../redux/configuration/types";
import {
  ASSIGN_PIPE_STATUS_DATA_SAGA,
  FETCH_PIPE_STATUS_DATA_SAGA,
} from "../../redux/reports/types";
import CommonLoader from "../Loaders/loader";

const PipeFilter = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedSpread, setSelectedSpread] = useState(null);
  const [selectedSubSpread, setSelectedSubSpread] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isTableModalVisible, setIsTableModalVisible] = useState(false);
  const [filters, setFilters] = useState({
    slNo: "",
    pipeID: "",
    spread: "",
    subSpread: "",
    status: "",
  });

  const { t } = useTranslation();
  const { Option } = Select;
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const reducerList = useSelector((state) => state);
  const spreadOptions =
    useSelector((state) => state.configurationReducer.configurationList) || [];
  const dispatch = useDispatch();

  const handleAssignButtonClick = () => {
    setModalVisible(true);
  };

  const PipeStatusData = Array.isArray(
    reducerList?.reportsReducer?.pipeStatusData
  )
    ? reducerList?.reportsReducer?.pipeStatusData
    : [];
  const project = useSelector((state) => state.projectReducer.selectedProject);

  useEffect(() => {
    if (project) {
      setIsLoading(true);
      setFilters({
        slNo: "",
        pipeID: "",
        spread: "",
        subSpread: "",
        status: "",
      });
      dispatch({
        type: GET_CONFIGURATION_SAGA,
        payload: { projectNumber: project.projectNumber },
        cb: () => {
          setSelectedRowKeys([]);
          setIsLoading(false);
        },
      });
    }
  }, [project]);

  useEffect(() => {
    if (reducerList?.projectReducer?.selectedProject?.projectNumber) {
      setIsLoading(true);
      dispatch({
        type: FETCH_PIPE_STATUS_DATA_SAGA,
        payload: {
          projectNumber:
            reducerList?.projectReducer?.selectedProject?.projectNumber,
        },
        cb: () => {
          setIsLoading(false);
        },
      });
    }
  }, [reducerList?.projectReducer?.selectedProject?.projectNumber]);

  const configurationList =
    reducerList?.configurationReducer?.configurationList;

  const getSpreadId = (selectedSpread) => {
    if (selectedSpread === configurationConstants.importDataSpread.anySpread) {
      return 0;
    }
    const returnedSpread = configurationList.find(
      (spread) => selectedSpread === spread.spreadName
    );
    return returnedSpread.id;
  };

  const getSubSpreadId = (selectedSpread, selectedSubSpread) => {
    if (selectedSpread === configurationConstants.importDataSpread.anySpread) {
      return 0;
    }
    if (
      selectedSubSpread ==
      configurationConstants.importDataSubSpread.anySubSpread
    ) {
      return 0;
    }
    const returnedSpread = configurationList.find(
      (spread) => spread.spreadName === selectedSpread
    );
    const returnedSubSpread = returnedSpread.subSpreads.find(
      (subSpread) => subSpread.subSpreadName === selectedSubSpread
    );
    return returnedSubSpread.id;
  };

  const [assigning, setAssigning] = useState(false);

  const handleModalOk = () => {
    setIsLoading(true);
    setAssigning(true);
    const payload = selectedRowKeys.map((selectedKey) => {
      const selectedPipe = PipeStatusData.find(
        (pipe) => pipe.pipeID === selectedKey
      );

      return {
        pipeId: selectedPipe.pipeID,
        spreadId: getSpreadId(selectedSpread),
        subSpreadId: getSubSpreadId(selectedSpread, selectedSubSpread),
      };
    });

    dispatch({
      type: ASSIGN_PIPE_STATUS_DATA_SAGA,
      projectNumber:
        reducerList?.projectReducer?.selectedProject?.projectNumber,
      payload: payload,
      cb: () => {
        setAssigning(false);
        setModalVisible(false);
        setSelectedSpread(null);
        setSelectedSubSpread(null);
        setSelectedRowKeys([]);
        setIsLoading(true);
        dispatch({
          type: FETCH_PIPE_STATUS_DATA_SAGA,
          payload: {
            projectNumber:
              reducerList?.projectReducer?.selectedProject?.projectNumber,
          },
          cb: () => {
            setIsLoading(false);
          },
        });
      },
      callback: () => {
        dispatch({
          type: FETCH_PIPE_STATUS_DATA_SAGA,
          payload: {
            projectNumber:
              reducerList?.projectReducer?.selectedProject?.projectNumber,
          },
          cb: () => {
            setIsLoading(false);
          },
        });
      },
    });
  };

  const getSubSpreads = (spreadName) => {
    const selectedSpread = spreadOptions.find(
      (spread) => spread.spreadName === spreadName
    );
    return selectedSpread ? selectedSpread.subSpreads || [] : [];
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setSelectedSpread(null);
    setSelectedSubSpread(null);
  };

  const data = PipeStatusData.map((pipe, index) => ({
    slNo: index + 1,
    projectNumber: pipe.projectNumber,
    spread: pipe.spread === "Any" ? "Unassigned" : pipe.spread,
    subSpread: pipe.subSpread === "Any" ? "Unassigned" : pipe.subSpread,
    status: pipe.status,
    pipeID: pipe.pipeID,
    endA: pipe.endA,
    endB: pipe.endB,
  }));

  const handleFilterChange = (value, dataIndex) => {
    const newFilters = { ...filters, [dataIndex]: value };

    if (value === undefined || (Array.isArray(value) && value.length === 0)) {
      delete newFilters[dataIndex];
    }

    setFilters(newFilters);
  };

  const filteredData = data.filter((item) =>
    Object.keys(filters).every((key) => {
      if (!filters[key]) return true;
      const value = (item[key] || "").toString();
      if (Array.isArray(filters[key])) {
        if (filters[key].length === 0) return true;
        return filters[key].some((val) =>
          value.toLowerCase().includes(val.toLowerCase())
        );
      } else {
        return value.toLowerCase().includes(filters[key].toLowerCase());
      }
    })
  );

  const columns = pipeFilterColumns.usePipeFilterColumns(
    filters,
    handleFilterChange,
    PipeStatusData
  );

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.status === "Used",
    }),
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    renderCell: (checked, record, index, originNode) => {
      return (
        <Tooltip
          title={
            record.status === "Used" ? t("pipestatusTable.pipeIsUsed") : ""
          }
        >
          {originNode}
        </Tooltip>
      );
    },
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const isAssignButtonDisabled = selectedRowKeys.length === 0;

  const handleSelectedSpread = (value) => {
    setSelectedSpread(value);

    setSelectedSubSpread(
      configurationConstants.importDataSubSpread.anySubSpread
    );
  };

  const showTableModal = () => {
    setIsTableModalVisible(true);
  };

  const handleTableModalCancel = () => {
    setIsTableModalVisible(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="initial-importData-load">
          <CommonLoader />
        </div>
      ) : (
        <div className="pipe-status-data">
          <Space direction="vertical" style={{ width: "100%" }}>
            <div className="sequence-head">
              {data.length > 0 && (
                <span
                  className="pipefilter-tooltip-view"
                  data-tooltip={t("sequenceTable.viewSpreadTooltip")}
                >
                  <EyeOutlined
                    className="pipefilter-view-icon"
                    onClick={showTableModal}
                  />
                </span>
              )}
              <div className="sequence-head-right">
                <Button
                  type="primary"
                  className={isAssignButtonDisabled ? "disable" : "assign"}
                  onClick={handleAssignButtonClick}
                  disabled={isAssignButtonDisabled}
                >
                  {t("pipestatusTable.assignSpreadSubSpread")}
                </Button>
              </div>
            </div>
            {isLoading ? (
              <CommonLoader />
            ) : (
              <Table
                className="pipe-status-table"
                rowSelection={rowSelection}
                columns={columns}
                dataSource={filteredData}
                rowKey={(record) => record.pipeID}
                scroll={{ y: 365 }}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: filteredData.length,
                  showSizeChanger: true,
                  pageSizeOptions: commonConstants.pageSizeOptions,
                  getPopupContainer: (triggerNode) => triggerNode.parentNode,
                }}
                onChange={handleTableChange}
              />
            )}
          </Space>
          <Modal
            title={t("pipestatusTable.particularSpreadSubSpreadAssign")}
            open={modalVisible}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
            className="assign-spread-subspread"
            width={"40vw"}
            okText={
              assigning
                ? t("pipestatusTable.assigning")
                : t("pipestatusTable.assign")
            }
            okButtonProps={{ disabled: !selectedSpread }}
          >
            <Select
              style={{ width: 200, marginBottom: 16 }}
              placeholder={t("pipestatusTable.selectSpread")}
              onChange={handleSelectedSpread}
              value={selectedSpread}
            >
              {spreadOptions.map((spread) => (
                <Option key={spread.spreadName} value={spread.spreadName}>
                  {spread.spreadName}
                </Option>
              ))}

              <Option value={configurationConstants.importDataSpread.anySpread}>
                {t("pipestatusTable.anyOption")}
              </Option>
            </Select>

            <Select
              style={{ width: 200, marginBottom: 16 }}
              placeholder={t("pipestatusTable.selectSubSpread")}
              onChange={(value) => setSelectedSubSpread(value)}
              value={selectedSubSpread}
              disabled={!selectedSpread}
            >
              {selectedSpread &&
                getSubSpreads(selectedSpread).map((subspread) => (
                  <Option
                    key={subspread.subSpreadName}
                    value={subspread.subSpreadName}
                  >
                    {subspread.subSpreadName}
                  </Option>
                ))}

              <Option value={configurationConstants.importDataSpread.anySpread}>
                {t("pipestatusTable.anyOption")}
              </Option>
            </Select>
          </Modal>
          <Modal
            open={isTableModalVisible}
            onCancel={handleTableModalCancel}
            footer={null}
            width="80%"
            bodyStyle={{ padding: 0 }}
            className="eye-modal-popup"
          >
            <PipeDataView
              data={data}
              columns={columns}
              onClose={handleTableModalCancel}
              filteredData={filteredData}
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default PipeFilter;
