const SET_REPORTS_PIPE_STATISTICS = "@@reports/pipeStatistic";
const SET_REPORTS_PIPE_STATUS = "@@reports/pipeStatus";
const SET_REPORTS_PIPE_DATA_GRAPH = "@@reports/pipeDataGraph";
const SET_PROJECT_STATUS_PIPE_DATA = "@@reports/projectStatusPipeData";
const SET_PROJECT_SUMMARY_DATA = "@@reports/projectSummaryData";
const SET_REPORTS_DATA_LOADING = "@@reports/dataLoading";

const FETCH_PIPE_STATISTICS_SAGA = "@@reports/fetchPipeStatisticSaga";
const FETCH_PIPE_STATUS_DATA_SAGA = "@@reports/fetchPipeStatusSaga";
const FETCH_PIPE_DATA_GRAPH_SAGA = "@@reports/fetchPipeDataGraphSaga";
const FETCH_PROJECT_STATUS_PIPE_DATA_SAGA =
  "@@reports/fetchProjectStatusPipeDataSaga";
const FETCH_PROJECT_REPORT_SAGA = "@@reports/fetchProjectReportSaga";
const FETCH_PROJECT_SUMMARY_REPORT_SAGA =
  "@@reports/fetchProjectSummaryReportSaga";
const SEND_EMAIL_PIPE_DATA_SAGA = "@@reports/sendEmailPipeData";
const FETCH_PROJECT_SUMMARY_DATA_SAGA = "@@reports/fetchProjectSummaryDataSaga";
const SEND_EMAIL_PROJECT_SUMMARY_SAGA = "@@reports/sendEmailProjectSummaryData";
const ASSIGN_PIPE_STATUS_DATA_SAGA = "@@reports/assignPipeStatusData";
const SEND_CONFIGURATION_EMAIL = "@@reports/sendProjectConfigurationEmail";

export {
  SET_REPORTS_PIPE_STATISTICS,
  SET_REPORTS_PIPE_STATUS,
  SET_REPORTS_PIPE_DATA_GRAPH,
  SET_PROJECT_STATUS_PIPE_DATA,
  SET_REPORTS_DATA_LOADING,
  SET_PROJECT_SUMMARY_DATA,
  //sagas
  FETCH_PIPE_STATISTICS_SAGA,
  FETCH_PIPE_DATA_GRAPH_SAGA,
  FETCH_PROJECT_STATUS_PIPE_DATA_SAGA,
  FETCH_PROJECT_REPORT_SAGA,
  FETCH_PROJECT_SUMMARY_REPORT_SAGA,
  SEND_EMAIL_PIPE_DATA_SAGA,
  FETCH_PROJECT_SUMMARY_DATA_SAGA,
  SEND_EMAIL_PROJECT_SUMMARY_SAGA,
  FETCH_PIPE_STATUS_DATA_SAGA,
  ASSIGN_PIPE_STATUS_DATA_SAGA,
  SEND_CONFIGURATION_EMAIL,
};
