import { INVALID_FILES_ERROR } from "./types";

const initialState = {
  invalidFiles: [],
};

const uploadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVALID_FILES_ERROR:
      return {
        ...state,
        invalidFiles: action.payload,
      };
    default:
      return state;
  }
};

export default uploadsReducer;
