import { all } from "redux-saga/effects";

import auth from "./auth";
import clientSagas from "./client";
import configurationSaga from "../redux/configuration/saga";
import projectSaga from "../redux/project/saga";
import reportsSaga from "../redux/reports/saga";
import uploadSagas from "../redux/uploads/saga";

export default function* rootSaga() {
  yield all([
    clientSagas(),
    auth(),
    projectSaga(),
    reportsSaga(),
    configurationSaga(),
    uploadSagas(),
  ]);
}
