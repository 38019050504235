let colorConstants = {
  whiteColor: "#ffffff",
  greyColor: "#707070",
  lightgreyColor: "rgb(225, 225, 225)",
  redColor: "#e03540",
  amberColor: "#de8735",
  slateBlue: "#738f97",
  mossGreen: "#7D975F",
  transparent: "transparent",
  blackColor: "#000000",
};
export { colorConstants };
