import React from "react";
import { Route, Redirect } from "react-router-dom";

const PublicRoute = ({ isLogin, component: Component, path, exact }) => (
  <Route
    exact={exact}
    path={path}
    render={() =>
      isLogin ? <Redirect to="/pipefitter-home" /> : <Component />
    }
  />
);

export default PublicRoute;
