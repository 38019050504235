import { Button, Select, Form, Col, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../locales/en.json";
import "../../global-styles/antd-custom.scss";
import { useSelector } from "react-redux";
import { commonConstants } from "../../constants";
import { spreadConfiguratorHelpers } from "../../helpers";

const AddSubSpread = (props) => {
  const { t } = useTranslation();
  const [subSpreadForm] = Form.useForm();
  const [sequenceOptionsList, setSequenceOptionsList] = useState([]);
  const reducerList = useSelector((state) => state);
  const [selectedSequenceEndA, setSelectedSequenceEndA] = useState("");
  const [selectedSequenceEndB, setSelectedSequenceEndB] = useState("");

  let { onSubmit, spreadList } = props;

  useEffect(() => {
    if (!props.visible) {
      subSpreadForm.resetFields();
    }
  }, [props.visible]);

  const handleSpreadChange = (spreadId) => {
    const selectedSpread = spreadList.find((spread) => spread.id === spreadId);

    if (selectedSpread) {
      let sequenceList = [];

      selectedSpread.subSpreads?.forEach((subSpread) => {
        sequenceList.push(
          {
            label: " Start of " + subSpread.subSpreadName,
            value: subSpread.subSpreadName + " End A",
          },
          {
            label: " End of " + subSpread.subSpreadName,
            value: subSpread.subSpreadName + " End B",
          }
        );
      });

      let usedSubSpreads = [];

      selectedSpread.subSpreads
        .filter((subSpread) => subSpread.pipelineTypes !== "Unconstraint")
        .filter((subSpread) => subSpread.sequenceEndA?.trim() !== "")
        .map((subSpread) => {
          let replacedString = subSpread.sequenceEndA?.replace(" End A", "");
          replacedString = replacedString?.replace(" End B", "");

          usedSubSpreads.push(replacedString);
        });

      sequenceList = sequenceList.filter(
        (option) => !usedSubSpreads.some((ite) => option.value.includes(ite))
      );

      setSequenceOptionsList(sequenceList);
    }
  };

  const validateAndAddSubSpread = () => {
    subSpreadForm
      .validateFields()
      .then((values) => {
        if (values.sequenceEndA === "None") {
          values.sequenceEndA = " ";
        }
        if (values.sequenceEndB === "None") {
          values.sequenceEndB = " ";
        }
        console.log("validated values: ", values);
        onSubmit(values);
        props.toggleLabels;
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const [disableSequenceEndA, setDisableSequenceEndA] = useState(false);
  const [disableSequenceEndB, setDisableSequenceEndB] = useState(false);

  const handlePipelineTypeChange = (value, field) => {
    if (value === "Unconstraint") {
      subSpreadForm.setFieldsValue({
        sequenceEndA: "None",
        sequenceEndB: "None",
      });
      setDisableSequenceEndA(true);
      setDisableSequenceEndB(true);
    } else if (value === "Continue") {
      subSpreadForm.setFieldsValue({
        sequenceEndA: undefined,
        sequenceEndB: undefined,
      });
      setDisableSequenceEndA(false);
      setDisableSequenceEndB(false);

      let filteredOptions = sequenceOptionsList;

      if (field === "sequenceEndA") {
        setSelectedSequenceEndA(value);
      } else if (field === "sequenceEndB") {
        setSelectedSequenceEndB(value);
      }
      setSequenceOptionsList(filteredOptions);
    } else {
      setDisableSequenceEndA(false);
      setDisableSequenceEndB(false);

      let filteredOptions = sequenceOptionsList;

      if (field === "sequenceEndA") {
        setSelectedSequenceEndA(value);
      } else if (field === "sequenceEndB") {
        setSelectedSequenceEndB(value);
      }
      setSequenceOptionsList(filteredOptions);
    }
  };

  const handleTargetNumberOfPipesChange = (value) => {
    const nominalPipeLength =
      reducerList?.projectReducer?.selectedProject?.nominalPipeLength;
    const targetLengthOfSpread = value * nominalPipeLength;

    subSpreadForm.setFieldsValue({
      targetLengthOfSpread: targetLengthOfSpread,
    });
  };

  return (
    <Form
      form={subSpreadForm}
      name="basic"
      layout="vertical"
      className="add-sub-spread"
    >
      <Col lg={24}>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please select a spread",
            },
          ]}
          label={t("configuration.subSpreadModalQuestion")}
          name="spreadId"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
        >
          <Select
            size="default"
            placeholder="Select a Spread"
            onChange={(spreadId) => handleSpreadChange(spreadId)}
          >
            <>
              {spreadList?.map((spread) => {
                return (
                  <Select.Option key={spread.id} value={spread.id}>
                    {spread.spreadName}
                  </Select.Option>
                );
              })}
            </>
          </Select>
        </Form.Item>
      </Col>
      {Object.keys(
        spreadConfiguratorHelpers.spreadConfigurationForm({
          sequenceList: sequenceOptionsList,
        })
      ).map((fieldName) => {
        const {
          type,
          label,
          labelSubSpread,
          placeholder,
          placeholderSubSpread,
          rules,
          options,
        } = spreadConfiguratorHelpers.spreadConfigurationForm({
          sequenceList: sequenceOptionsList,
        })?.[fieldName];
        if (
          type === commonConstants.inputTypes.input ||
          type === commonConstants.inputTypes.number
        ) {
          return (
            <Col key={fieldName} lg={24}>
              <Form.Item
                key={fieldName}
                label={t(`${labelSubSpread}`)}
                name={fieldName}
                rules={rules}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <Input
                  type={type}
                  size="large"
                  maxLength={commonConstants.commonConstant.maxFormFieldLength}
                  placeholder={t(`${placeholderSubSpread}`)}
                  className="no-spin-buttons"
                  onChange={(e) => {
                    if (fieldName === "targetNumberOfPipesInSpread") {
                      handleTargetNumberOfPipesChange(e.target.value);
                    }
                  }}
                />
              </Form.Item>
            </Col>
          );
        } else if (type === commonConstants.inputTypes.select) {
          return (
            <Col key={fieldName} lg={24}>
              <Form.Item
                key={fieldName}
                label={t(`${label}`)}
                name={fieldName}
                rules={rules}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <Select
                  className="select-drop"
                  bordered={false}
                  size="default"
                  placeholder={t(`${placeholder}`)}
                  onChange={(value) =>
                    handlePipelineTypeChange(value, fieldName)
                  }
                  disabled={
                    fieldName === "sequenceEndA"
                      ? disableSequenceEndA
                      : fieldName === "sequenceEndB"
                      ? disableSequenceEndB
                      : false
                  }
                >
                  {options?.map((ite) => {
                    return (
                      <Select.Option
                        key={ite.value}
                        value={ite.value}
                        disabled={
                          (fieldName === "sequenceEndA" &&
                            ite.value === selectedSequenceEndB) ||
                          (fieldName === "sequenceEndB" &&
                            ite.value === selectedSequenceEndA)
                        }
                      >
                        {ite.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          );
        }
        if (
          fieldName === "targetNumberOfPipesInSpread" ||
          fieldName === "targetLengthOfSpread" ||
          fieldName === "pipelineTypes" ||
          fieldName === "sequenceEndA" ||
          fieldName === "sequenceEndB"
        ) {
          return (
            <Col key={fieldName} lg={24}>
              <Form.Item
                key={fieldName}
                label={t(`${label}`)}
                name={fieldName}
                rules={rules}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              ></Form.Item>
            </Col>
          );
        }

        return null;
      })}
      <div className="spread-save-btn">
        <Button key="cancel" onClick={props.toggleLabels}>
          {t("common.cancelButtonText")}
        </Button>
        <Button
          onClick={() => validateAndAddSubSpread()}
          key="submit"
          type="primary"
          disabled={props.addSpreadLoader}
          className="addSubSpreadSaving"
        >
          {props.addSpreadLoader
            ? t("common.savingButtonText")
            : t("common.saveButtonText")}
        </Button>
      </div>
    </Form>
  );
};

export default AddSubSpread;
