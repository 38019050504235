import { notification } from "antd";
import { put, call, all, takeLatest } from "redux-saga/effects";

import {
  projectSubmitting,
  setAvailableSpreadPipeCount,
  setAvailableSubSpreadPipeCount,
  setPipeCountForSelectedProject,
  setProjectList,
  setTotalPipeCountForSelectedProject,
  setUsedPipeCountForSelectedProject,
  startProjectLoader,
  stopProjectLoader,
} from "./action.js";
import {
  FETCH_PIPE_LENGTH,
  FETCH_PROJECT_SAGA,
  SUBMIT_PROJECT_SAGA,
  UPDATE_PROJECT_SAGA,
} from "./types.js";
import { BASE_URL } from "../../config.js";
import fetch from "../../services/fetch";

function* fetchPipeLengthSaga(data) {
  try {
    let payload = data?.payload;

    let queryString = Object.keys(payload)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`
      )
      .join("&");

    const res = yield fetch(
      `${BASE_URL}/project/get-total-available-pipe/?${queryString}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          yield put(
            setPipeCountForSelectedProject(response?.data?.projectPipeCount)
          );
          yield put(
            setTotalPipeCountForSelectedProject(response?.data?.totalPipeCount)
          );
          yield put(
            setUsedPipeCountForSelectedProject(response?.data?.usedPipeCount)
          );
          yield put(
            setAvailableSpreadPipeCount(
              response?.data?.spreadAvailablePipeCount
            )
          );
          yield put(
            setAvailableSubSpreadPipeCount(
              response?.data?.subSpreadAvailablePipeCount
            )
          );
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: { error },
    });
  }
}

function* fetchProjectSaga() {
  try {
    yield put(startProjectLoader());

    const res = yield fetch(`${BASE_URL}/project`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          yield put(setProjectList(response?.data?.projectList));
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    yield put(stopProjectLoader());
  } catch (error) {
    console.log(error);
    notification.error({
      message: { error },
    });
  }
}

function* submitProjectSaga(data) {
  try {
    let apiPayload = data.payload;

    yield put(projectSubmitting(true));

    const res = yield fetch(`${BASE_URL}/project`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(apiPayload),
    });

    notification.destroy();
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          notification.success({
            message: "Project Created Successfully.",
          });

          yield fetchProjectSaga({});
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });

          yield put(projectSubmitting(false));
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
        yield put(projectSubmitting(false));
      }
    }

    yield put(projectSubmitting(false));
  } catch (error) {
    yield put(projectSubmitting(false));

    notification.destroy();
    notification.error({
      message: "Project Creation Submission Failed.",
      description: `${error}`,
    });
  }
}

function* updateProjectSaga(data) {
  try {
    let apiPayload = data.payload;

    yield put(projectSubmitting(true));

    const res = yield fetch(`${BASE_URL}/project/${apiPayload.projectNumber}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(apiPayload),
    });

    notification.destroy();
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          notification.success({
            message: "Project updated Successfully.",
          });

          yield fetchProjectSaga({});
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });

          yield put(projectSubmitting(false));
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
        yield put(projectSubmitting(false));
      }
    }

    yield put(projectSubmitting(false));
  } catch (error) {
    yield put(projectSubmitting(false));

    notification.destroy();
    notification.error({
      message: "Project Creation Submission Failed.",
      description: `${error}`,
    });
  }
}

function* projectWatcher() {
  yield takeLatest(FETCH_PROJECT_SAGA, fetchProjectSaga);
  yield takeLatest(SUBMIT_PROJECT_SAGA, submitProjectSaga);
  yield takeLatest(UPDATE_PROJECT_SAGA, updateProjectSaga);
  yield takeLatest(FETCH_PIPE_LENGTH, fetchPipeLengthSaga);
}

function* projectSagas() {
  yield all([call(projectWatcher)]);
}

export default projectSagas;
