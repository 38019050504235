import { MenuOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { colorConstants } from "../../constants/global-color";

const PipeDataGraph = ({ pipeDataGraph }) => {
  const reducerList = useSelector((state) => state);
  const projectName = reducerList?.projectReducer?.selectedProject?.projectName;
  const projectNumber =
    reducerList?.projectReducer?.selectedProject?.projectNumber;

  const themeState = reducerList?.switchThemeReducer?.switchTheme;
  const {
    amberColor,
    redColor,
    greyColor,
    lightgreyColor,
    mossGreen,
    slateBlue,
  } = colorConstants;
  // Function to map pipe data to the candlestick format
  const mapPipeDataToCandlestick = (pipeDataGraph) => {
    return pipeDataGraph.map((pipeData) => ({
      x: pipeData.pipeEndID, // Using pipeEndID as x-axis value
      y: [
        parseFloat(pipeData.avgID), // Avg
        parseFloat(pipeData.maxID), // Max
        parseFloat(pipeData.minID), // Min
        parseFloat(pipeData.avgID), // Avg (same as Open and Close in candlestick)
      ],
    }));
  };

  const csvMappedData = pipeDataGraph.map((ite) => {
    const { pipeEndID, avgID, minID, maxID, ooR } = ite;
    return { pipeEndID, avgID, minID, maxID, ooR };
  });

  const generateCSV = (data = csvMappedData) => {
    let headers = Object.keys(data[0]).join(",");
    headers = Object.keys(data[0]).map((ite) => {
      if (ite !== "pipeEndID") {
        return ite.concat(" (mm)");
      } else {
        return ite;
      }
    });
    const rows = data.map((row) => Object.values(row).join(",")).join("\n");
    return `${headers}\n${rows}`;
  };

  const downloadCSV = (data) => {
    const csv = generateCSV(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${projectName}_${projectNumber}-Pipe_Data.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [chartState] = useState({
    series: [
      {
        name: "candle",
        type: "candlestick",
        data: mapPipeDataToCandlestick(pipeDataGraph), // Passing mapped candlestick data
      },
    ],
    options: {
      grid: {
        borderColor: themeState ? lightgreyColor : greyColor,
      },
      chart: {
        height: 350,
        type: "candlestick",
        foreColor: themeState ? greyColor : lightgreyColor,
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        type: "category",
        labels: {
          rotate: -45,
          style: {
            colors: themeState ? greyColor : lightgreyColor,
          },
        },
      },
      yaxis: {
        tooltip: {
          enabled: true,
          crosshairs: {
            show: false,
          },
        },
        labels: {
          style: {
            colors: themeState ? greyColor : lightgreyColor,
          },
        },
        title: {
          text: "ID (mm)",
          style: {
            color: themeState ? greyColor : lightgreyColor,
            fontWeight: "400",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
          },
          align: "end",
        },
      },
      tooltip: {
        theme: "dark",
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          const avg = w.globals.seriesCandleO[seriesIndex][dataPointIndex];
          const max = w.globals.seriesCandleH[seriesIndex][dataPointIndex];
          const min = w.globals.seriesCandleL[seriesIndex][dataPointIndex];

          return `<div style="padding: 10px; color: White;">
              <span><b>Avg:</b> ${avg}</span><br/>
              <span><b>Max:</b> ${max}</span><br/>
              <span><b>Min:</b> ${min}</span><br/>
            </div>`;
        },
      },
      colors: [redColor, amberColor, slateBlue, mossGreen, greyColor],
      plotOptions: {
        candlestick: {
          colors: {
            upward: "#3C90EB",
            downward: "#3C90EB",
          },
          wick: {
            useFillColor: false,
          },
        },
      },
      stroke: {
        width: [1, 2],
        colors: ["#ff0000"],
      },
    },
  });
  const menu = (
    <Menu>
      <Menu.Item onClick={() => downloadCSV(csvMappedData)}>
        Download CSV
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="pipedata-graph">
      <div className="menuOutlined">
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          placement="bottomRight"
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          <MenuOutlined style={{ fontSize: "18px" }} />
        </Dropdown>
      </div>
      <div id="chart">
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="candlestick"
          height={350}
        />
      </div>
    </div>
  );
};

export default PipeDataGraph;
