import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Option } = Select;

export const usePipeFilterColumns = (filters, handleFilterChange, data) => {
  const { t } = useTranslation();

  const getColumnFilterOptions = (dataIndex) => {
    const uniqueOptions = [...new Set(data.map((item) => item[dataIndex]))];
    return uniqueOptions.filter((option) => option).sort();
  };

  const renderSelectFilter = (dataIndex, placeholder) => (
    <Select
      mode="multiple"
      showSearch
      placeholder={`${t("pipestatusTable.filter")} ${placeholder}`}
      value={filters[dataIndex] || []}
      onChange={(value) => handleFilterChange(value, dataIndex)}
      filterOption={(input, option) =>
        option?.children?.toLowerCase().includes(input.toLowerCase())
      }
      style={{ width: "95%" }}
      allowClear
    >
      {getColumnFilterOptions(dataIndex).map((option) => (
        <Option
          key={option}
          value={
            option === "Any"
              ? "Unassigned"
              : option === t("pipestatusTable.missingOneEnd")
              ? t("pipestatusTable.missingOneEnd")
              : option
          }
        >
          {option === "Any"
            ? "Unassigned"
            : option === t("pipestatusTable.defaultState")
            ? t("pipestatusTable.missingOneEnd")
            : option}
        </Option>
      ))}
    </Select>
  );

  return [
    {
      title: (
        <div className="search-filter">
          {t("pipestatusTable.siNo")}
          <Input
            placeholder={`${t("pipestatusTable.filter")} ${t(
              "pipestatusTable.siNo"
            )}`}
            value={filters.slNo}
            onChange={(e) => handleFilterChange(e.target.value, "slNo")}
            className="input-filter-style"
          />
        </div>
      ),
      dataIndex: "slNo",
      width: 80,
    },
    {
      title: (
        <div className="search-filter">
          {t("pipestatusTable.pipeID")}
          {renderSelectFilter("pipeID", t("pipestatusTable.pipeID"))}
        </div>
      ),
      dataIndex: "pipeID",
      width: 240,
    },
    {
      title: (
        <div className="search-filter">
          {t("pipestatusTable.EndA")}
          {renderSelectFilter("endA", t("pipestatusTable.EndA"))}
        </div>
      ),
      dataIndex: "endA",
      width: 80,
      render: (value) =>
        value ? (
          <CheckOutlined className="checkOutLined" />
        ) : (
          <CloseOutlined className="CloseOutlined" />
        ),
    },
    {
      title: (
        <div className="search-filter">
          {t("pipestatusTable.EndB")}
          {renderSelectFilter("endB", t("pipestatusTable.EndB"))}
        </div>
      ),
      dataIndex: "endB",
      width: 80,
      render: (value) =>
        value ? (
          <CheckOutlined className="checkOutLined" />
        ) : (
          <CloseOutlined className="CloseOutlined" />
        ),
    },
    {
      title: (
        <div className="search-filter">
          {t("pipestatusTable.spread")}
          {renderSelectFilter("spread", t("pipestatusTable.spread"))}
        </div>
      ),
      dataIndex: "spread",
      width: 140,
    },
    {
      title: (
        <div className="search-filter">
          {t("pipestatusTable.subSpread")}
          {renderSelectFilter("subSpread", t("pipestatusTable.subSpread"))}
        </div>
      ),
      dataIndex: "subSpread",
      width: 140,
    },
    {
      title: (
        <div className="search-filter">
          {t("pipestatusTable.pipeStatus")}
          {renderSelectFilter("status", t("pipestatusTable.pipeStatus"))}
        </div>
      ),
      dataIndex: "status",
      width: 120,
      render: (status) => {
        return status === t("pipestatusTable.defaultState")
          ? t("pipestatusTable.missingOneEnd")
          : status;
      },
    },
  ];
};
