/* eslint-disable react/prop-types */
import { PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { connect } from "react-redux";
import { roleBasedAccessControlConstants } from "../constants";
import { rabcHelpers } from "../helpers";
import CreateProjectPopup from "./projectCreation/projectCreationPopup";
import { addClient } from "../redux/actions/clientAction";

const CreateProject = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  let isDoesUserHavePermissionToCreateProject = rabcHelpers.hasUserHasRole({
    roleName: props.userRole,
    scope: roleBasedAccessControlConstants.scopes.project.create,
  });

  return (
    <>
      {isDoesUserHavePermissionToCreateProject && (
        <div
          onClick={showModal}
          className="project-details-wrapper create-project"
        >
          <PlusOutlined className="icon-28" />
        </div>
      )}

      {isModalVisible && (
        <>
          <CreateProjectPopup
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
          />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = {
  onAddClient: addClient,
};

const mapStateToProps = (allState) => {
  return {
    userRole:
      allState?.auth.userDetails?.signInUserSession?.accessToken?.payload?.[
        "cognito:groups"
      ]
        ?.filter((ite) =>
          roleBasedAccessControlConstants.pipeFitterRoles.includes(ite)
        )
        ?.sort(roleBasedAccessControlConstants.pipeFitterRoles.customSort)[0] ||
      "",

    allState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject);
