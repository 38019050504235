import { commonConstants } from "../constants";

let { inputTypes } = commonConstants;

const validatePositiveInteger = (_, value) => {
  if (value && !Number.isInteger(Number(value))) {
    return Promise.reject("Enter a valid integer");
  }
  if (Number(value) == 0) {
    return Promise.reject("Enter a non-zero positive number");
  } else if (Number(value) < 1) {
    return Promise.reject("Enter a positive number");
  }
  return Promise.resolve();
};

const validateRangeInput = (value, min, max) => {
  if (
    !Number.isInteger(Number(value)) ||
    parseFloat(value) !== parseInt(value, 10)
  ) {
    return Promise.reject("Please enter an integer");
  }

  if (value < min || value > max) {
    return Promise.reject(`Please enter in the range ${min} to ${max}`);
  }

  return Promise.resolve();
};

const validatePositiveDecimal = (value) => {
  const floatValue = parseFloat(value);

  if (value && isNaN(parseFloat(floatValue))) {
    return Promise.reject("Please enter a valid number");
  }
  if (floatValue == 0) {
    return Promise.reject("Enter a non-zero positive number");
  } else if (floatValue < 1) {
    return Promise.reject(`Please enter positive value`);
  }
  return Promise.resolve();
};

const validateRangeDecimal = (value, min, max) => {
  const floatValue = parseFloat(value);

  if (value && isNaN(parseFloat(floatValue))) {
    return Promise.reject("Please enter a valid number");
  }

  if (floatValue < min || floatValue > max) {
    return Promise.reject(`Please enter in the range ${min} to ${max}`);
  }
  return Promise.resolve();
};

const projectCreationForm = {
  projectNumber: {
    type: inputTypes.input,
    label: "common.projectNumber",
    placeholder: "addProject.projectNumberPlaceholder",
    rules: [
      { required: true, message: "addProject.errors.projectNumber" },
      {
        validator: (_, value) => {
          if (value && /\s/.test(value)) {
            return Promise.reject(
              "Spaces are not allowed in the project number"
            );
          }
          return Promise.resolve();
        },
        message: "Spaces are not allowed in the project number",
      },
    ],
    tooltip: "projectTooltip.projectNumber",
  },

  projectName: {
    type: inputTypes.input,
    label: "common.projectName",
    placeholder: "addProject.projectNamePlaceholder",
    rules: [{ required: true, message: "addProject.errors.projectName" }],
    tooltip: "projectTooltip.projectName",
  },

  contractNumber: {
    type: inputTypes.input,
    label: "addProject.contractNumber",
    placeholder: "addProject.contractNumberPlaceholder",
    rules: [{ required: true, message: "addProject.errors.contractNumber" }],
    tooltip: "projectTooltip.contractNumber",
  },

  location: {
    type: inputTypes.input,
    label: "addProject.location",
    placeholder: "addProject.locationPlaceholder",
    rules: [],
    tooltip: "projectTooltip.location",
  },

  startDate: {
    type: inputTypes.date,
    label: "common.startDate",
    placeholder: "Select Start Date",
    rules: [
      {
        required: true,
        message: "Please select the Start Date!",
      },
    ],
    tooltip: "projectTooltip.startDate",
  },

  endDate: {
    type: inputTypes.date,
    label: "common.endDate",
    placeholder: "Select end date",
    rules: [
      {
        required: false,
        message: "Please select the End Date!",
      },
    ],
    tooltip: "projectTooltip.endDate",
  },

  projectManagerName: {
    type: inputTypes.input,
    label: "addProject.managerName",
    placeholder: "addProject.managerNamePlaceholder",
    rules: [
      { required: false, message: "addProject.errors.projectManagerName" },
    ],
    tooltip: "projectTooltip.managerName",
  },

  technicianName: {
    type: inputTypes.input,
    label: "common.technicianName",
    placeholder: "addProject.technicianNamePlaceholder",
    rules: [{ required: true, message: "addProject.errors.technicianName" }],
    tooltip: "projectTooltip.technicianName",
  },

  customerName: {
    type: inputTypes.input,
    label: "common.customerName",
    placeholder: "addProject.customerNamePlaceholder",
    rules: [{ required: true, message: "addProject.errors.customerName" }],
    tooltip: "projectTooltip.customerName",
  },

  projectManagerEmail: {
    type: inputTypes.input,
    label: "addProject.managerEmail",
    placeholder: "addProject.managerEmailPlaceholder",
    rules: [
      { required: false, message: "addProject.errors.projectManagerEmail" },
      { type: "email", message: "common.errors.validEmail" },
    ],
    tooltip: "addProject.managerEmail",
  },

  technicianEmail: {
    type: inputTypes.input,
    label: "common.technicianEmail",
    placeholder: "addProject.technicianEmailPlaceholder",
    rules: [
      { required: true, message: "addProject.errors.technicianEmail" },
      { type: "email", message: "common.errors.validEmail" },
    ],
    tooltip: "common.technicianEmail",
  },

  customerEmail: {
    type: inputTypes.input,
    label: "common.customerEmail",
    placeholder: "addProject.customerEmailPlaceholder",
    rules: [
      { required: false, message: "addProject.errors.customerEmail" },
      { type: "email", message: "common.errors.validEmail" },
    ],
    tooltip: "common.customerEmail",
  },
};

const pipeParametersForm = {
  nominalPipeLength: {
    type: inputTypes.input,
    label: "common.nominalPipeLength",
    placeholder: "addProject.nominalPipeLengthPlaceholder",
    rules: [
      { required: true, message: "addProject.errors.nominalPipeLength" },
      {
        isValidator: true,
        validator: (_, value) => {
          return validatePositiveDecimal(value);
        },
      },
    ],
    tooltip: "projectTooltip.nominalPipeLength",
  },

  targetNumberOfPipes: {
    type: inputTypes.input,
    label: "common.targetNumberOfPipes",
    placeholder: "addProject.targetNumberOfPipesPlaceholder",
    rules: [
      {
        required: true,
        message: "addProject.errors.targetNumberOfPipes",
      },
      {
        isValidator: true,
        validator: (_, value) => {
          return validatePositiveInteger(_, value);
        },
      },
    ],
    tooltip: "projectTooltip.targetNumberOfPipes",
  },

  targetPipelineLength: {
    type: inputTypes.input,
    label: "common.targetPipelineLength",
    placeholder: "addProject.targetPipelineLengthPlaceholder",
    rules: [
      { required: false, message: "addProject.errors.targetPipelineLength" },
      {
        isValidator: true,
        validator: (_, value) => {
          return validatePositiveDecimal(value);
        },
      },
    ],
    tooltip: "projectTooltip.targetPipelineLength",
  },

  nominalODofPipe: {
    type: inputTypes.input,
    label: "common.nominalOdPipeLength",
    placeholder: "addProject.nominalOdPipeLengthPlaceholder",
    rules: [
      { required: true, message: "addProject.errors.nominalODofPipe" },
      {
        isValidator: true,
        validator: (_, value) => {
          return validatePositiveDecimal(value);
        },
      },
    ],
    tooltip: "projectTooltip.nominalOdPipeLength",
  },

  // resampleSize: {
  //   type: inputTypes.number,
  //   label: "common.resampleSize",
  //   placeholder: "addProject.resampleSizePlaceholder",
  //   rules: [
  //     { required: false, message: "addProject.errors.resampleSize" },
  //     {
  //       isValidator: true,
  //       validator: (_, value) => {
  //         return validateRangeInput(value, 36, 360);
  //       },
  //     },
  //   ],
  // },

  sixOClockExclusion: {
    type: inputTypes.input,
    label: "common.sixOClockExclusion",
    placeholder: "addProject.sixOClockExclusionPlaceholder",
    rules: [
      { required: true, message: "addProject.errors.sixOClockExclusion" },
      {
        isValidator: true,
        validator: (_, value) => {
          return validateRangeInput(value, 0, 90);
        },
      },
    ],
    tooltip: "projectTooltip.sixOClockExclusion",
  },

  weldSeamExclusion: {
    type: inputTypes.input,
    label: "common.weldSeamExclusion",
    placeholder: "addProject.weldSeamExclusionPlaceholder",
    rules: [
      { required: true, message: "addProject.errors.weldSeamExclusion" },
      {
        isValidator: true,
        validator: (_, value) => {
          return validateRangeInput(value, 0, 180);
        },
      },
    ],
    tooltip: "projectTooltip.weldSeamExclusion",
  },

  hiLoThreshold: {
    type: inputTypes.input,
    label: "common.hiLoThreshold",
    placeholder: "addProject.hiLoThresholdPlaceholder",
    rules: [
      { required: true, message: "Please input HiLo Threshold!" },
      {
        isValidator: true,
        validator: (_, value) => {
          return validateRangeDecimal(value, 0.1, 10.0);
        },
      },
    ],
    tooltip: "projectTooltip.hiLoThreshold",
  },

  pipeMaterial: {
    type: inputTypes.input,
    label: "common.pipeMaterial",
    placeholder: "addProject.pipeMaterialPlaceholder",
    rules: [],
    tooltip: "projectTooltip.pipeMaterial",
  },

  pipeType: {
    type: inputTypes.select,
    label: "common.pipeType",
    placeholder: "addProject.pipeTypePlaceholder",
    options: [
      { value: "Single", label: "Single" },
      { value: "Double", label: "Double" },
    ],
    rules: [{ required: false, message: "addProject.errors.pipeType" }],
    tooltip: "projectTooltip.pipeType",
  },

  pipeRotation: {
    type: inputTypes.select,
    label: "common.pipeRotation",
    placeholder: "addProject.pipeRotationPlaceholder",
    options: [
      { value: "Any", label: "Any" },
      { value: "Clockwise", label: "Clockwise" },
      { value: "Anti-Clockwise", label: "Anti-Clockwise" },
    ],
    rules: [{ required: false, message: "addProject.errors.pipeRotation" }],
    tooltip: "projectTooltip.pipeRotation",
  },

  fitQuality: {
    type: inputTypes.select,
    label: "common.fitQuality",
    placeholder: "addProject.fitQualityPlaceholder",
    options: [
      { value: "Optimal", label: "Optimal", defaultValue: "Optimal" },
      { value: "Acceptable", label: "Acceptable" },
    ],
    rules: [{ required: false, message: "addProject.errors.fitQuality" }],
    tooltip: "projectTooltip.fitQuality",
  },

  allowPipeTranslation: {
    type: inputTypes.switch,
    label: "addProject.allowPipeTranslation",
    placeholder: "",
    rules: [
      { required: false, message: "addProject.errors.allowPipeTranslation" },
    ],
    tooltip: "projectTooltip.allowPipeTranslation",
  },

  // duplicateSinglePipeEnd: {
  //   type: inputTypes.switch,
  //   label: "addProject.duplicateSinglePipeEnd",
  //   placeholder: "",
  //   rules: [
  //     { required: false, message: "addProject.errors.duplicateSinglePipeEnd" },
  //   ],
  // },
};

const mapLocalStateToApiRequest = (localState) => {
  let mappedProject = {
    projectNumber: localState.projectNumber?.toString(),
    projectName: localState.projectName,
    contractNumber: localState.contractNumber?.toString(),
    startDate: localState.startDate,
    customerName: localState.customerName,
    technicianEmail: localState.technicianEmail,
    nominalPipeLength: localState.nominalPipeLength,
    nominalOD: localState.nominalODofPipe,
    targetNumberOfPipes: localState.targetNumberOfPipes,
    resampleSize: localState.resampleSize || 360,
    exclusion6OClock: localState.sixOClockExclusion,
    weldSeamExclusion: localState.weldSeamExclusion,
    hiLoThreshold: localState.hiLoThreshold,
    pipeType: localState.pipeType,
    duplicateSinglePipeEnd: localState.duplicateSinglePipeEnd || false,
    allowPipeTranslation: localState.allowPipeTranslation || false,
    customerEmail: localState.customerEmail || "",
    projectManagerEmail: localState.projectManagerEmail || "",
    location: localState.location || "",
    projectManagerName: localState.projectManagerName || "",
    pipeMaterial: localState.pipeMaterial || "",
    endDate: localState.endDate || "",
  };

  if (localState.spreadTargetNumberOfPipesInSpread) {
    mappedProject.spreadTargetNumberOfPipesInSpread =
      localState.spreadTargetNumberOfPipesInSpread;
  }

  if (localState.spreadTargetLengthOfSpread) {
    mappedProject.spreadTargetLengthOfSpread =
      localState.spreadTargetLengthOfSpread;
  }

  if (localState.spreadPipelineTypes) {
    mappedProject.spreadPipelineTypes = localState.spreadPipelineTypes;
  }

  if (localState.spreadSequenceEndA) {
    mappedProject.spreadSequenceEndA = localState.spreadSequenceEndA;
  }

  if (localState.spreadSequenceEndB) {
    mappedProject.spreadSequenceEndB = localState.spreadSequenceEndB;
  }

  if (localState.technicianName) {
    mappedProject.technicianName = localState.technicianName;
  }

  if (localState.targetPipelineLength) {
    mappedProject.targetPipelineLength = localState.targetPipelineLength;
  }

  if (localState.pipeRotation) {
    mappedProject.pipeRotation = localState.pipeRotation;
  }

  if (localState.fitQuality) {
    mappedProject.fitQuality = localState.fitQuality;
  }

  return mappedProject;
};

const isAnyParameterChangedInProjectForSequenceGeneration = (
  oldProject,
  updatedProject
) => {
  if (oldProject.targetNumberOfPipes != updatedProject?.targetNumberOfPipes) {
    return true;
  }
  if (oldProject.exclusion6OClock != updatedProject?.sixOClockExclusion) {
    return true;
  }
  if (oldProject.weldSeamExclusion != updatedProject?.weldSeamExclusion) {
    return true;
  }
  if (oldProject.hiLoThreshold != updatedProject?.hiLoThreshold) {
    return true;
  }
  if (oldProject.pipeType != updatedProject?.pipeType) {
    return true;
  }
  if (oldProject.pipeRotation != updatedProject?.pipeRotation) {
    return true;
  }
  if (oldProject.allowPipeTranslation != updatedProject?.allowPipeTranslation) {
    return true;
  }
  return false;
};

export {
  projectCreationForm,
  pipeParametersForm,
  isAnyParameterChangedInProjectForSequenceGeneration,
  mapLocalStateToApiRequest,
};
