import {
  SET_PROJECT_STATUS_PIPE_DATA,
  SET_REPORTS_DATA_LOADING,
  SET_REPORTS_PIPE_DATA_GRAPH,
  SET_REPORTS_PIPE_STATISTICS,
  SET_PROJECT_SUMMARY_DATA,
  SET_REPORTS_PIPE_STATUS,
} from "./types";

const setReportsLoader = (payload) => {
  return {
    type: SET_REPORTS_DATA_LOADING,
    payload,
  };
};

const setPipeStatistics = (payload) => {
  return {
    type: SET_REPORTS_PIPE_STATISTICS,
    payload,
  };
};
const setPipeStatus = (payload) => {
  return {
    type: SET_REPORTS_PIPE_STATUS,
    payload,
  };
};

const setPipeDataGraph = (payload) => {
  return {
    type: SET_REPORTS_PIPE_DATA_GRAPH,
    payload,
  };
};

const setProjectStatusPipeData = (payload) => {
  return {
    type: SET_PROJECT_STATUS_PIPE_DATA,
    payload,
  };
};

const setProjectSummaryData = (payload) => {
  return {
    type: SET_PROJECT_SUMMARY_DATA,
    payload,
  };
};

export {
  setReportsLoader,
  setPipeStatistics,
  setPipeStatus,
  setPipeDataGraph,
  setProjectStatusPipeData,
  setProjectSummaryData,
};
