import {
  FormOutlined,
  DeleteOutlined,
  FilePdfOutlined,
  EyeOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Tree, Modal } from "antd";
import React, { useEffect, useState } from "react";
import "../../global-styles/antd-custom.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  commonConstants,
  configurationConstants,
  roleBasedAccessControlConstants,
} from "../../constants";
import { commonHelpers, rabcHelpers } from "../../helpers";
import {
  FETCH_SEQUENCE_CONFIGURATION_LIST,
  GENERATE_SEQUENCE_CONFIGURATION_LIST,
  GET_CONFIGURATION_SAGA,
  DELETE_SEQUENCE_SPREAD_SAGA,
  FETCH_SEQUENCE_REPORT_SAGA,
  APPROVE_SEQUENCE_SPREAD_SAGA,
} from "../../redux/configuration/types";
import { FETCH_PIPE_LENGTH } from "../../redux/project/types";
import EmailPopup from "../EmailPopup";
import CommonLoader from "../Loaders/loader";
import { SequenceTable } from "../sequence/SequenceTable";
import SequenceView from "../sequence/Sequenceview";

const { TreeNode } = Tree;

const Sequence = () => {
  const reducerList = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isTableModalVisible, setIsTableModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedKey, setSelectedKey] = useState("0");
  const [selectionType, setSelectionType] = useState("");
  const [selectedConfiguration, setSelectedConfiguration] = useState({});

  //sequenceList
  const [sequenceList, setSequenceList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    commonConstants.defaultPageSize
  );

  const [allConfigurationList, setAllConfigurationList] = useState([]);
  const [isSequenceApproved, setIsSequenceApproved] = useState(
    selectedConfiguration?.sequenceApprovalStatus === "Approved"
  );
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const showTableModal = () => {
    setIsTableModalVisible(true);
  };

  const handleTableModalCancel = () => {
    setIsTableModalVisible(false);
  };

  const spreadName = () => {
    if (selectionType == configurationConstants.selectionType.spread) {
      return selectedConfiguration.spreadName;
    } else {
      return "";
    }
  };

  let roleOfUser = commonHelpers.getRoleOfUserFromReduxState(
    reducerList?.auth?.userDetails
  );

  let doesHavePermissionToGenerate = rabcHelpers.hasUserHasRole({
    roleName: roleOfUser,
    scope: roleBasedAccessControlConstants.scopes.sequence.create,
  });

  let doesHavePermissionToDelete = rabcHelpers.hasUserHasRole({
    roleName: roleOfUser,
    scope: roleBasedAccessControlConstants.scopes.sequence.delete,
  });

  let doesHavePermissionToApprove = rabcHelpers.hasUserHasRole({
    roleName: roleOfUser,
    scope: roleBasedAccessControlConstants.scopes.sequence.approve,
  });
  let doesHavePermissionToViewApprove = rabcHelpers.hasUserHasRole({
    roleName: roleOfUser,
    scope: roleBasedAccessControlConstants.scopes.sequence.authorizedRead,
  });

  let doesHavePermissionToSendEmail = rabcHelpers.hasUserHasRole({
    roleName: roleOfUser,
    scope: roleBasedAccessControlConstants.scopes.sequence.sendEmail,
  });

  const resetConfigurationSelection = () => {
    setSelectedConfiguration({});
    setSelectionType("");
    setCurrentPage(1);
    setRowsPerPage(commonConstants.defaultPageSize);
    setSequenceList([]);
    setTotalCount(0);
    setIsSequenceApproved(false);
  };

  useEffect(() => {
    if (reducerList?.projectReducer?.selectedProject?.projectNumber) {
      resetConfigurationSelection();
      setIsLoading(true);

      dispatch({
        type: GET_CONFIGURATION_SAGA,
        payload: {
          projectNumber:
            reducerList?.projectReducer?.selectedProject?.projectNumber,
        },
        cb: () => {
          setIsLoading(false);
        },
      });
      setSelectedKey("0");
    }
  }, [
    reducerList?.auth?.userDetails,
    reducerList?.projectReducer?.selectedProject?.projectNumber,
  ]);

  useEffect(() => {
    setIsSequenceApproved(
      selectedConfiguration?.sequenceApprovalStatus === "Approved"
    );
  }, [selectedConfiguration]);

  useEffect(() => {
    if (selectedConfiguration.id) {
      if (selectionType === configurationConstants.selectionType.spread) {
        dispatch({
          type: FETCH_PIPE_LENGTH,
          payload: {
            projectNumber:
              reducerList?.projectReducer?.selectedProject?.projectNumber,
            spreadId: selectedConfiguration?.id,
            subSpreadId: "0",
          },
        });
      } else {
        dispatch({
          type: FETCH_PIPE_LENGTH,
          payload: {
            projectNumber:
              reducerList?.projectReducer?.selectedProject?.projectNumber,
            spreadId: selectedConfiguration?.spreadId,
            subSpreadId: selectedConfiguration?.id,
          },
        });
      }
      setIsLoading(true);
      dispatch({
        type: GET_CONFIGURATION_SAGA,
        payload: {
          projectNumber:
            reducerList?.projectReducer?.selectedProject?.projectNumber,
        },
        cb: () => {
          setIsLoading(false);
        },
      });
    }
  }, []);

  useEffect(() => {
    if (
      reducerList?.configurationReducer.configurationList?.length &&
      reducerList?.projectReducer?.selectedProject?.projectNumber
    ) {
      setAllConfigurationList(
        reducerList?.configurationReducer.configurationList
      );

      if (selectedConfiguration?.id) {
        handleClick("", {
          key: selectedKey,
          id: selectedConfiguration?.id,
          selectionType: selectionType,
        });
      } else {
        handleClick("", {
          key: selectedKey,
          id: reducerList?.configurationReducer.configurationList?.[0]?.id,
          selectionType: configurationConstants.selectionType.spread,
        });
      }
    }
  }, [
    reducerList?.configurationReducer.configurationList,
    reducerList?.projectReducer?.selectedProject?.projectNumber,
    selectedKey,
  ]);

  const selectConfiguration = ({ type, configurationId }) => {
    setSelectionType(type);
    setIsSequenceApproved(false);
    if (type == configurationConstants.selectionType.spread) {
      let selectedSpread = reducerList?.configurationReducer.configurationList?.find(
        (spread) => spread.id == configurationId
      );
      selectedSpread = {
        ...selectedSpread,
        canGenerateSpread: true,
        hasMoreSubSpreads:
          selectedSpread?.subSpreads?.length > 0 ? true : false,
      };
      setSelectedConfiguration(selectedSpread);

      if (
        selectedSpread.sequenceGenerationStatus ==
          configurationConstants.sequenceGenerationStatus.Completed ||
        selectedSpread?.subSpreads?.length > 0
      ) {
        let params = {
          skip: 0,
          limit: rowsPerPage,
          projectNumber:
            reducerList?.projectReducer?.selectedProject?.projectNumber,
          spread: selectedSpread.id,
        };

        setCurrentPage(1);

        setIsLoading(true);
        dispatch({
          type: FETCH_SEQUENCE_CONFIGURATION_LIST,
          payload: params,
          cb: (data) => {
            setSequenceList(data?.recordList);
            setTotalCount(data?.count);
            setIsLoading(false);
          },
        });
      }
    } else if (type == configurationConstants.selectionType.subSpread) {
      let allSubSpreads = [];
      reducerList?.configurationReducer.configurationList?.map((ite) => {
        ite.subSpreads?.map((subs) => allSubSpreads.push(subs));
      });

      let selectedSubSpread = allSubSpreads.find(
        (ite) => ite.id == configurationId
      );

      selectedSubSpread = {
        ...selectedSubSpread,
        canGenerateSpread: true,
      };
      setSelectedConfiguration(selectedSubSpread);

      if (
        selectedSubSpread.sequenceGenerationStatus ==
        configurationConstants.sequenceGenerationStatus.Completed
      ) {
        let params = {
          skip: 0,
          limit: rowsPerPage,
          projectNumber:
            reducerList?.projectReducer?.selectedProject?.projectNumber,
          subSpread: selectedSubSpread.id,
        };
        setCurrentPage(1);

        setIsLoading(true);

        dispatch({
          type: FETCH_SEQUENCE_CONFIGURATION_LIST,
          payload: params,
          cb: (data) => {
            setSequenceList(data?.recordList);
            setTotalCount(data?.count);
            setIsLoading(false);
          },
        });
      }
    }
  };

  const confirmSendEmail = () => {
    setShowEmailModal(true);
  };

  const handleTableChange = (pagination) => {
    let skip = commonHelpers.calculateSkipFromPage({
      page: pagination.current,
      limit: pagination.pageSize,
    });

    if (selectionType == configurationConstants.selectionType.spread) {
      let params = {
        skip: skip,
        limit: pagination.pageSize,
        projectNumber:
          reducerList?.projectReducer?.selectedProject?.projectNumber,
        spread: selectedConfiguration.id,
      };

      setCurrentPage(pagination.current);
      setRowsPerPage(pagination.pageSize);

      setIsLoading(true);

      dispatch({
        type: FETCH_SEQUENCE_CONFIGURATION_LIST,
        payload: params,
        cb: (data) => {
          setSequenceList(data?.recordList);
          setTotalCount(data?.count);
          setIsLoading(false);
        },
      });
    } else if (
      selectionType == configurationConstants.selectionType.subSpread
    ) {
      let params = {
        skip: skip,
        limit: pagination.pageSize,
        projectNumber:
          reducerList?.projectReducer?.selectedProject?.projectNumber,
        subSpread: selectedConfiguration.id,
      };
      setCurrentPage(pagination.current);
      setRowsPerPage(pagination.pageSize);

      setIsLoading(true);

      dispatch({
        type: FETCH_SEQUENCE_CONFIGURATION_LIST,
        payload: params,
        cb: (data) => {
          setSequenceList(data?.recordList);
          setTotalCount(data?.count);
          setIsLoading(false);
        },
      });
    }
  };

  const handleClick = (e, info) => {
    setSelectedKey(info.key);
    resetConfigurationSelection();
    switch (info.selectionType) {
      case configurationConstants.selectionType.spread:
        selectConfiguration({
          type: configurationConstants.selectionType.spread,
          configurationId: info.id,
        });
        break;
      case configurationConstants.selectionType.subSpread:
        selectConfiguration({
          type: configurationConstants.selectionType.subSpread,
          configurationId: info.id,
        });
        break;
      default:
        break;
    }
  };

  const generateSequence = () => {
    let payload = {};
    if (selectionType == configurationConstants.selectionType.spread) {
      payload = {
        generateFor: configurationConstants.selectionType.spread,
        projectNumber:
          reducerList?.projectReducer?.selectedProject?.projectNumber,
        spreadId: selectedConfiguration.id?.toString(),
      };
    } else if (
      selectionType == configurationConstants.selectionType.subSpread
    ) {
      payload = {
        generateFor: configurationConstants.selectionType.subSpread,
        projectNumber:
          reducerList?.projectReducer?.selectedProject?.projectNumber,
        subSpreadId: selectedConfiguration.id?.toString(),
      };
    }

    setIsLoading(true);

    dispatch({
      type: GENERATE_SEQUENCE_CONFIGURATION_LIST,
      payload: payload,
      cb: () => {
        dispatch({
          type: GET_CONFIGURATION_SAGA,
          payload: {
            projectNumber:
              reducerList?.projectReducer?.selectedProject?.projectNumber,
          },
          cb: () => {
            setIsLoading(false);
            setCurrentPage(1);
            setRowsPerPage(commonConstants.defaultPageSize);
          },
        });
      },
      error: () => {
        setIsLoading(false);
      },
    });
  };

  const deleteSpreadSubSpread = () => {
    setIsLoading(true);

    let payload = {
      projectNumber:
        reducerList?.projectReducer?.selectedProject?.projectNumber,
      sequenceDeletionFor: selectionType,
    };
    if (selectionType == configurationConstants.selectionType.spread) {
      payload = {
        ...payload,
        spreadId: selectedConfiguration.id?.toString(),
      };
    }

    if (selectionType == configurationConstants.selectionType.subSpread) {
      payload = {
        ...payload,
        subSpreadId: selectedConfiguration.id?.toString(),
      };
    }

    dispatch({
      type: DELETE_SEQUENCE_SPREAD_SAGA,
      payload: payload,
      cb: () => {
        dispatch({
          type: GET_CONFIGURATION_SAGA,
          payload: {
            projectNumber:
              reducerList?.projectReducer?.selectedProject?.projectNumber,
          },
          cb: () => {
            setIsLoading(false);
            setCurrentPage(1);
            setRowsPerPage(commonConstants.defaultPageSize);
          },
        });
      },
      error: () => {
        setIsLoading(false);
      },
    });
  };

  const [isPdfDownloading, setIsPdfDownloading] = useState(false);

  const handleGenerateSequencePDF = async () => {
    try {
      let payload = {
        projectNumber:
          reducerList?.projectReducer?.selectedProject?.projectNumber,
      };

      if (selectionType == configurationConstants.selectionType.spread) {
        payload = {
          ...payload,
          spread: selectedConfiguration.id?.toString(),
        };
      }

      if (selectionType == configurationConstants.selectionType.subSpread) {
        payload = {
          ...payload,
          subSpread: selectedConfiguration.id?.toString(),
        };
      }

      setIsPdfDownloading(true);
      dispatch({
        type: FETCH_SEQUENCE_REPORT_SAGA,
        payload: payload,
        cb: (blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = `${reducerList?.projectReducer?.selectedProject?.projectNumber}_Sequence-Report`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          setIsPdfDownloading(false);
        },
      });
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const approveSequenceSpread = () => {
    try {
      if (isSequenceApproved) {
        console.log("Sequence is already approved. No action needed.");
        return;
      }

      if (selectionType === configurationConstants.selectionType.spread) {
        let params = {
          generateFor: configurationConstants.selectionType.spread,
          projectNumber:
            reducerList?.projectReducer?.selectedProject?.projectNumber,
          spreadId: selectedConfiguration?.id?.toString(),
        };
        dispatchApproveSequence(params);
      } else {
        if (selectionType === configurationConstants.selectionType.subSpread) {
          let params = {
            generateFor: configurationConstants.selectionType.subSpread,
            projectNumber:
              reducerList?.projectReducer?.selectedProject?.projectNumber,
            subSpreadId: selectedConfiguration?.id?.toString(),
          };
          dispatchApproveSequence(params);
        } else {
          console.error();
        }
      }
    } catch (error) {
      console.error("Error approving sequence:", error);
    }
  };

  const dispatchApproveSequence = (params) => {
    setIsLoading(true);
    dispatch({
      type: APPROVE_SEQUENCE_SPREAD_SAGA,
      payload: params,
      cb: () => {
        dispatchFetchConfiguration();
        setIsLoading(false);
      },
      error: () => {
        setIsLoading(false);
      },
    });
  };

  const dispatchFetchConfiguration = () => {
    dispatch({
      type: GET_CONFIGURATION_SAGA,
      payload: {
        projectNumber:
          reducerList?.projectReducer?.selectedProject?.projectNumber,
      },
      cb: () => {
        setIsSequenceApproved(
          selectedConfiguration?.sequenceApprovalStatus === "Approved"
        );
        setIsLoading(false);
        setCurrentPage(1);
        setRowsPerPage(commonConstants.defaultPageSize);
      },
    });
  };

  const showDeleteModal = () => {
    let deleteMessage = "";
    if (
      selectionType === configurationConstants.selectionType.spread ||
      selectedConfiguration?.subSpreads?.length === 0
    ) {
      deleteMessage = t("sequenceTable.deleteSpread");
    } else if (
      selectionType === configurationConstants.selectionType.subSpread
    ) {
      deleteMessage = t("sequenceTable.deleteSubSpread");
    }
    Modal.confirm({
      title: deleteMessage,
      okText: <span style={{ color: "black" }}>{t("sequenceTable.yes")}</span>,
      cancelText: t("sequenceTable.no"),
      width: 600,
      onOk: deleteSpreadSubSpread,
    });
  };

  const showApproveModal = () => {
    Modal.confirm({
      title: t("sequenceTable.ApprovePopup"),
      okText: <span style={{ color: "black" }}>{t("sequenceTable.yes")}</span>,
      cancelText: t("sequenceTable.no"),
      width: 500,
      className: "custom-approve-modal",
      onOk: approveSequenceSpread,
    });
  };

  return (
    <>
      {isLoading ? (
        <div className="initial-sequence-load">
          <CommonLoader />
        </div>
      ) : (
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: "30%",
            }}
          >
            <div style={{ display: "flex" }}></div>
            {selectionType && selectedConfiguration ? (
              <div
                className={
                  sequenceList?.length === 0
                    ? "sequenceTab-tree"
                    : "sequenceTab-tree-table"
                }
              >
                <Tree
                  defaultExpandAll
                  draggable
                  className="parent-tree"
                  onClick={handleClick}
                  selectedKeys={[selectedKey]}
                >
                  {commonHelpers
                    .sortBySortOrder(allConfigurationList)
                    ?.map((spread, index) => {
                      return (
                        <TreeNode
                          title={spread.spreadName}
                          id={spread.id}
                          selectionType={
                            configurationConstants.selectionType.spread
                          }
                          key={`${index}`}
                          sortOrder={spread.sortOrder}
                          selected={true}
                        >
                          {commonHelpers
                            .sortBySortOrder(spread.subSpreads)
                            ?.map((subSpread, subIndex) => {
                              return (
                                <TreeNode
                                  title={`${subSpread.subSpreadName.replace(
                                    " ",
                                    ""
                                  )}`}
                                  key={`${index}-${subIndex}`}
                                  selectionType={
                                    configurationConstants.selectionType
                                      .subSpread
                                  }
                                  id={subSpread.id}
                                  sortOrder={subSpread.sortOrder}
                                  spreadId={subSpread.spreadId}
                                />
                              );
                            })}
                        </TreeNode>
                      );
                    })}
                </Tree>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="table-view" style={{ flex: "1000%" }}>
            <div className="switch-container"></div>
            <div className="sequence-icon">
              {doesHavePermissionToSendEmail &&
                selectedConfiguration?.id &&
                sequenceList.length > 0 && (
                  <>
                    <span
                      className="sequence-tooltip-send"
                      onClick={() => {
                        if (!isSendingEmail) {
                          confirmSendEmail();
                        }
                      }}
                      data-tooltip={
                        isSendingEmail
                          ? "\xa0".repeat(6) + "Sending email..."
                          : t("reportIcon.shareSequenceReport")
                      }
                    >
                      <SendOutlined className="sequence-send-icon" />
                      {isSendingEmail && (
                        <div className="downloading-text-sequence-email">
                          <div className="typing-animation">
                            <div
                              className="dot"
                              style={{ "--delay": "0ms" }}
                            ></div>
                            <div
                              className="dot"
                              style={{ "--delay": "200ms" }}
                            ></div>
                            <div
                              className="dot"
                              style={{ "--delay": "400ms" }}
                            ></div>
                          </div>
                        </div>
                      )}
                    </span>
                  </>
                )}
              {sequenceList.length > 0 && (
                <>
                  {(roleOfUser === roleBasedAccessControlConstants.role.admin ||
                    roleOfUser ===
                      roleBasedAccessControlConstants.role.projectManager) && (
                    <>
                      <span
                        className="sequence-tooltip-view"
                        data-tooltip={t("sequenceTable.viewSpreadTooltip")}
                      >
                        <EyeOutlined
                          className="sequence-view-icon"
                          onClick={showTableModal}
                        />
                      </span>
                      <span
                        className="sequence-tooltip"
                        onClick={() => {
                          if (!isPdfDownloading) {
                            handleGenerateSequencePDF();
                          }
                        }}
                        data-tooltip={
                          isPdfDownloading
                            ? "\xa0".repeat(10) +
                              "Downloading..." +
                              "\xa0".repeat(11)
                            : t("sequenceTable.sequenceDownloadpdf")
                        }
                      >
                        <FilePdfOutlined className="sequence-pdf-icon" />
                        {isPdfDownloading && (
                          <div className="downloading-text-sequence">
                            <div className="typing-animation">
                              <div
                                className="dot"
                                style={{ "--delay": "0ms" }}
                              ></div>
                              <div
                                className="dot"
                                style={{ "--delay": "200ms" }}
                              ></div>
                              <div
                                className="dot"
                                style={{ "--delay": "400ms" }}
                              ></div>
                            </div>
                          </div>
                        )}
                      </span>
                    </>
                  )}
                  {(roleOfUser ===
                    roleBasedAccessControlConstants.role.technician ||
                    roleOfUser ===
                      roleBasedAccessControlConstants.role.customer) && (
                    <>
                      <>
                        <span
                          className={
                            isSequenceApproved
                              ? "sequence-tooltip-view-rolebase"
                              : "sequence-tooltip-view-unapproved"
                          }
                          data-tooltip={t("sequenceTable.viewSpreadTooltip")}
                        >
                          <EyeOutlined
                            className={
                              isSequenceApproved
                                ? "sequence-view-icon-rolebase"
                                : "sequence-view-icon-unapproved"
                            }
                            onClick={showTableModal}
                          />
                        </span>
                        {isSequenceApproved && (
                          <span
                            className="sequence-tooltip-rolebase"
                            onClick={() => {
                              if (!isPdfDownloading) {
                                handleGenerateSequencePDF();
                              }
                            }}
                            data-tooltip={
                              isPdfDownloading
                                ? "\xa0".repeat(9) +
                                  "Downloading..." +
                                  "\xa0".repeat(12)
                                : t("sequenceTable.sequenceDownloadpdf")
                            }
                          >
                            <FilePdfOutlined className="sequence-pdf-icon-rolebase" />
                          </span>
                        )}
                      </>
                    </>
                  )}
                </>
              )}
              {doesHavePermissionToDelete &&
                selectedConfiguration?.id &&
                sequenceList.length > 0 && (
                  <span
                    className="sequence-tooltip-delete"
                    data-tooltip={t("sequenceTable.deleteSpreadTooltip")}
                  >
                    <DeleteOutlined
                      className="sequence-delete-icon"
                      onClick={showDeleteModal}
                    />
                  </span>
                )}
              {doesHavePermissionToApprove &&
                selectedConfiguration?.id &&
                sequenceList.length > 0 && (
                  <span
                    className={`sequence-tooltip-approval ${
                      isSequenceApproved ? "sequence-approved" : ""
                    }`}
                    data-tooltip={
                      isSequenceApproved
                        ? t("reportIcon.approved")
                        : t("reportIcon.approval")
                    }
                  >
                    <FormOutlined
                      className={`sequence-approved-icon ${
                        isSequenceApproved ? "approved-icon-grey" : ""
                      }`}
                      onClick={isSequenceApproved ? null : showApproveModal}
                    />
                  </span>
                )}
            </div>
            {selectedConfiguration?.id &&
            selectedConfiguration?.canGenerateSpread &&
            !isLoading ? (
              <>
                <div>
                  <SequenceTable
                    sequenceList={sequenceList}
                    hasMoreSubSpreads={selectedConfiguration.hasMoreSubSpreads}
                    selectionType={selectionType}
                    selectedConfiguration={selectedConfiguration}
                    currentPage={currentPage}
                    rowsPerPage={rowsPerPage}
                    totalCount={totalCount}
                    handleTableChange={handleTableChange}
                    generateSequence={generateSequence}
                    doesHavePermissionToGenerate={doesHavePermissionToGenerate}
                    doesHavePermissionToViewApprove={
                      doesHavePermissionToViewApprove
                    }
                    isSequenceApproved={isSequenceApproved}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      <Modal
        open={isTableModalVisible}
        onCancel={handleTableModalCancel}
        footer={null}
        width="80%"
        bodyStyle={{ padding: 0 }}
        className="eye-modal-popup"
      >
        {selectedConfiguration?.id && (
          <SequenceView
            sequenceList={sequenceList}
            selectionType={selectionType}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            totalCount={totalCount}
            handleTableChange={handleTableChange}
            spreadName={spreadName()}
            onClose={handleTableModalCancel}
          />
        )}
      </Modal>

      {showEmailModal && (
        <EmailPopup
          setIsSendingEmail={setIsSendingEmail}
          setShowEmailModal={setShowEmailModal}
          showEmailModalFor={commonConstants.showEmailModalFor.sequence}
          selectionType={selectionType}
          selectedConfiguration={selectedConfiguration}
        />
      )}
    </>
  );
};

export default Sequence;
