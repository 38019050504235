const displayFields = [
  {
    name: "common.projectNumber",
    value: "projectNumber",
  },
  {
    name: "common.projectName",
    value: "projectName",
  },
  {
    name: "common.contractNumber",
    value: "contractNumber",
  },
  {
    name: "common.startDate",
    value: "startDate",
  },
  {
    name: "common.technicianName",
    value: "technicianName",
  },
  {
    name: "common.technicianEmail",
    value: "technicianEmail",
  },
  {
    name: "addProject.managerName",
    value: "projectManagerName",
  },
  {
    name: "addProject.managerEmail",
    value: "projectManagerEmail",
  },
  {
    name: "common.customerName",
    value: "customerName",
  },
  {
    name: "common.customerEmail",
    value: "customerEmail",
  },
  {
    name: "common.nominalPipeLength",
    value: "nominalPipeLength",
  },
  {
    name: "common.nominalOdPipeLength",
    value: "nominalOD",
  },
  {
    name: "common.targetNumberOfPipes",
    value: "targetNumberOfPipes",
  },
  {
    name: "common.targetPipelineLength",
    value: "targetPipelineLength",
  },
  {
    name: "common.sixOClockExclusion",
    value: "exclusion6OClock",
  },
  {
    name: "common.weldSeamExclusion",
    value: "weldSeamExclusion",
  },
  {
    name: "common.hiLoThreshold",
    value: "hiLoThreshold",
  },
  {
    name: "common.pipeMaterial",
    value: "pipeMaterial",
  },
];

export { displayFields };
