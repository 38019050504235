import { getJwtToken } from "./auth";

const customFetch = async (api, data) => {
  const authToken = await getJwtToken();
  const response = await fetch(api, {
    ...data,
    headers: {
      ...data.headers,
      Authorization: authToken,
    },
  });
  return response;
};

export default customFetch;
