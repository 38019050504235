import {
  SIGNOUT,
  SIGNIN,
  SIGNINSUCCESS,
  SIGNINFAILURE,
  SIGNUP,
  SIGNUPSUCCESS,
  SIGNUPFAILURE,
  SETUSERDETAILS,
} from "../types";

const signIn = (credentials) => ({
  type: SIGNIN,
  credentials,
});

const signInSuccess = (payload) => ({
  type: SIGNINSUCCESS,
  ...payload,
});

const signInFailure = () => ({
  type: SIGNINFAILURE,
});

const signOut = () => ({
  type: SIGNOUT,
});

const signUp = (credentials) => ({
  type: SIGNUP,
  credentials,
});

const signUpSuccess = (payload) => ({
  type: SIGNUPSUCCESS,
  ...payload,
});

const signUpFailure = () => ({
  type: SIGNUPFAILURE,
});

const setUserDetails = (payload) => ({
  type: SETUSERDETAILS,
  payload,
});

export {
  signOut,
  signIn,
  signInSuccess,
  signInFailure,
  signUp,
  signUpSuccess,
  signUpFailure,
  setUserDetails,
};
