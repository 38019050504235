const SET_CONFIGURATION_LISTS = "@@configuration/setConfigurationList";
const SET_CONFIGURATION_LOADING = "@@configuration/setConfigurationLoading";
const SET_SEQUENCE_LISTS = "@@configuration/setSequenceList";

const GET_CONFIGURATION_SAGA = "GET_CONFIGURATION_SAGA";
const CREATE_SPREAD_CONFIGURATION_SAGA = "CREATE_SPREAD_CONFIGURATION_SAGA";
const UPDATE_SPREAD_CONFIGURATION_SAGA = "UPDATE_SPREAD_CONFIGURATION_SAGA";
const DELETE_SPREAD_CONFIGURATION_SAGA = "DELETE_SPREAD_CONFIGURATION_SAGA";
const CREATE_SUB_SPREAD_CONFIGURATION_SAGA =
  "CREAT_SUB_SPREAD_CONFIGURATION_SAGA";
const UPDATE_SUB_SPREAD_CONFIGURATION_SAGA =
  "UPDATE_SUB_SPREAD_CONFIGURATION_SAGA";
const DELETE_SUB_SPREAD_CONFIGURATION_SAGA =
  "DELETE_SUB_SPREAD_CONFIGURATION_SAGA";
const SORT_SPREAD_CONFIGURATION_SAGA = "SORT_SPREAD_CONFIGURATION_SAGA";
const SORT_SUB_SPREAD_CONFIGURATION_SAGA = "SORT_SUB_SPREAD_CONFIGURATION_SAGA";
const FETCH_SEQUENCE_CONFIGURATION_LIST = "FETCH_SEQUENCE_CONFIGURATION_LIST";
const GENERATE_SEQUENCE_CONFIGURATION_LIST =
  "GENERATE_SEQUENCE_CONFIGURATION_LIST";
const DELETE_SEQUENCE_SPREAD_SAGA = "DELETE_SEQUENCE_SPREAD_SAGA";
const FETCH_SEQUENCE_REPORT_SAGA = "FETCH_SEQUENCE_REPORT_SAGA";
const APPROVE_SEQUENCE_SPREAD_SAGA = "APPROVE_SEQUENCE_SPREAD_SAGA";
const SEND_EMAIL_SEQUENCE_SAGA = "SEND_EMAIL_SEQUENCE_SAGA";
const MIN_MAX_CALCULATION_SAGA = "MIN_MAX_CALCULATION_SAGA";

export {
  //sagas
  GET_CONFIGURATION_SAGA,
  CREATE_SPREAD_CONFIGURATION_SAGA,
  UPDATE_SPREAD_CONFIGURATION_SAGA,
  DELETE_SPREAD_CONFIGURATION_SAGA,
  CREATE_SUB_SPREAD_CONFIGURATION_SAGA,
  UPDATE_SUB_SPREAD_CONFIGURATION_SAGA,
  DELETE_SUB_SPREAD_CONFIGURATION_SAGA,
  SORT_SPREAD_CONFIGURATION_SAGA,
  SORT_SUB_SPREAD_CONFIGURATION_SAGA,
  DELETE_SEQUENCE_SPREAD_SAGA,
  APPROVE_SEQUENCE_SPREAD_SAGA,
  //reducers
  SET_CONFIGURATION_LOADING,
  SET_CONFIGURATION_LISTS,
  SET_SEQUENCE_LISTS,
  FETCH_SEQUENCE_CONFIGURATION_LIST,
  GENERATE_SEQUENCE_CONFIGURATION_LIST,
  FETCH_SEQUENCE_REPORT_SAGA,
  SEND_EMAIL_SEQUENCE_SAGA,
  MIN_MAX_CALCULATION_SAGA,
};
