import { Amplify } from "@aws-amplify/core";
import axios from "axios";
import get from "lodash/get";

import { getJwtToken } from "./auth";
import config from "../config";

// const token = localStorage.getItem('ap_idToken');
Amplify.configure(config);

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: getJwtToken(),
  },
});

const handleErroredResponse = async (error) => {
  const originalRequest = get(error, ["response", "config"], undefined);
  const statusCode = get(error, ["response", "status"], undefined);
  const errMessage = get(error, ["response", "data", "name"], undefined);
  const errData = get(error, ["response", "data"], undefined);

  if (statusCode === 401) {
    if (errMessage === "TokenExpiredError") {
      // remove token from local storage, if applicable
      // call for new id token
      // store token in token variable
      const token = await getJwtToken();
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      }
      return axiosConfig(originalRequest);
    }
  }

  if (errData) {
    return Promise.reject(errData);
  }
  return Promise.reject(error.message);
};

axiosConfig.interceptors.request.use(
  async (config) => {
    const aConfig = config;
    // call for id token and store in authToken variable
    const authToken = await getJwtToken();
    if (authToken) {
      aConfig.headers["Authorization"] = authToken;
    }
    return aConfig;
  },
  (error) => Promise.reject(error)
);

axiosConfig.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    return await handleErroredResponse(error);
  }
);

export default axiosConfig;
