import {
  PROJECT_LOADER_START,
  PROJECT_LOADER_STOP,
  PROJECT_SUBMITTING,
  SET_AVAILABLE_SPREAD_PIPE_COUNT,
  SET_AVAILABLE_SUBSPREAD_PIPE_COUNT,
  SET_PROJECT_LIST,
  SET_PROJECT_PIPE_COUNT,
  SET_SELECTED_PROJECT,
  SET_TOTAL_PIPE_COUNT,
  SET_USED_PIPE_COUNT,
} from "./types";

const startProjectLoader = () => ({
  type: PROJECT_LOADER_START,
});

const setProjectList = (payload) => ({
  type: SET_PROJECT_LIST,
  payload,
});

const setPipeCountForSelectedProject = (payload) => ({
  type: SET_PROJECT_PIPE_COUNT,
  payload,
});

const setTotalPipeCountForSelectedProject = (payload) => ({
  type: SET_TOTAL_PIPE_COUNT,
  payload,
});

const setUsedPipeCountForSelectedProject = (payload) => ({
  type: SET_USED_PIPE_COUNT,
  payload,
});

const setAvailableSpreadPipeCount = (payload) => ({
  type: SET_AVAILABLE_SPREAD_PIPE_COUNT,
  payload,
});

const setAvailableSubSpreadPipeCount = (payload) => ({
  type: SET_AVAILABLE_SUBSPREAD_PIPE_COUNT,
  payload,
});

const stopProjectLoader = () => ({
  type: PROJECT_LOADER_STOP,
});

const projectSubmitting = (payload) => ({
  type: PROJECT_SUBMITTING,
  payload,
});

const setSelectedProject = (payload) => ({
  type: SET_SELECTED_PROJECT,
  payload,
});

export {
  startProjectLoader,
  projectSubmitting,
  setProjectList,
  stopProjectLoader,
  setSelectedProject,
  setPipeCountForSelectedProject,
  setTotalPipeCountForSelectedProject,
  setUsedPipeCountForSelectedProject,
  setAvailableSpreadPipeCount,
  setAvailableSubSpreadPipeCount,
};
