import { Amplify } from "@aws-amplify/core";
import React from "react";
import { I18nextProvider } from "react-i18next"; // Import I18nextProvider
import { Provider } from "react-redux";

import config from "./config";
import i18n from "./locales/ii8n";
import configureStore from "./redux/store";
import AppContainer from "./routes";
import "./App.css";

Amplify.configure(config);
const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <React.StrictMode>
          <I18nextProvider i18n={i18n}>
            <AppContainer />
          </I18nextProvider>
        </React.StrictMode>
      </div>
    </Provider>
  );
}

export default App;
