import { Form, Input, Row, Col, Select, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { commonConstants, configurationConstants } from "../../constants";
import { spreadConfiguratorHelpers } from "../../helpers";

const SpreadConfigurationModal = (props) => {
  const { t } = useTranslation();
  const [spreadConfigForm] = Form.useForm();
  const [sequenceOptionsList, setSequenceOptionsList] = useState([]);
  const reducerList = useSelector((state) => state);
  const [selectedSequenceEndA, setSelectedSequenceEndA] = useState("");
  const [selectedSequenceEndB, setSelectedSequenceEndB] = useState("");

  useEffect(() => {
    if (!props.selectConfiguration.id) {
      return;
    }

    if (
      props.selectionType === configurationConstants.selectionType.spread ||
      configurationConstants.selectionType.subSpread
    ) {
      let sequenceList = [];

      props.configurationList.forEach((spread) => {
        sequenceList = [
          ...sequenceList,
          ...[
            {
              label: " Start of " + spread.spreadName,
              value: spread.spreadName + " End A",
            },
            {
              label: " End of " + spread.spreadName,
              value: spread.spreadName + " End B",
            },
          ],
        ];
      });

      let usedSpreads = [];

      props.configurationList
        .filter((spread) => spread.pipelineTypes != "Unconstraint")
        .filter((spread) => spread.sequenceEndA?.trim() != "")
        .map((spread) => {
          let replacedString = spread.sequenceEndA?.replace(" End A", "");
          replacedString = replacedString?.replace(" End B", "");

          usedSpreads.push(replacedString);
        });

      sequenceList = sequenceList.filter(
        (option) => !usedSpreads.some((ite) => option.value.includes(ite))
      );

      setSequenceOptionsList(sequenceList);
    }
  }, [props.selectConfiguration]);

  const handleTargetNumberOfPipesChange = (value) => {
    const nominalPipeLengthValue =
      reducerList?.projectReducer?.selectedProject?.nominalPipeLength;

    const targetLengthOfSpread = value * nominalPipeLengthValue;

    spreadConfigForm.setFieldsValue({
      targetLengthOfSpread: targetLengthOfSpread,
    });
  };

  const handleClickSubmit = () => {
    spreadConfigForm
      .validateFields()
      .then((values) => {
        if (values.sequenceEndA === "None") {
          values.sequenceEndA = " ";
        }
        if (values.sequenceEndB === "None") {
          values.sequenceEndB = " ";
        }
        props.onSubmit(values);
        props.toggleLabels;
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const [disableSequenceEndA, setDisableSequenceEndA] = useState(false);
  const [disableSequenceEndB, setDisableSequenceEndB] = useState(false);

  const handlePipelineTypeChange = (value, field) => {
    if (value === "Unconstraint") {
      spreadConfigForm.setFieldsValue({
        sequenceEndA: "None",
        sequenceEndB: "None",
      });
      setDisableSequenceEndA(true);
      setDisableSequenceEndB(true);
    } else if (value === "Continue") {
      spreadConfigForm.setFieldsValue({
        sequenceEndA: undefined,
        sequenceEndB: undefined,
      });

      setDisableSequenceEndA(false);
      setDisableSequenceEndB(false);

      if (field === "sequenceEndA") {
        setSelectedSequenceEndA(value);
      } else if (field === "sequenceEndB") {
        setSelectedSequenceEndB(value);
      }
    } else {
      setDisableSequenceEndA(false);
      setDisableSequenceEndB(false);

      if (field === "sequenceEndA") {
        setSelectedSequenceEndA(value);
      } else if (field === "sequenceEndB") {
        setSelectedSequenceEndB(value);
      }
    }
  };

  return (
    <Form form={spreadConfigForm} name="basic" layout="vertical">
      <Row gutter={12}>
        {Object.keys(
          spreadConfiguratorHelpers.spreadConfigurationForm({
            sequenceList: sequenceOptionsList,
          })
        ).map((fieldName) => {
          const { type, label, placeholder, rules, options } =
            // eslint-disable-next-line no-unsafe-optional-chaining
            spreadConfiguratorHelpers.spreadConfigurationForm({
              sequenceList: sequenceOptionsList,
            })?.[fieldName];
          if (
            type == commonConstants.inputTypes.input ||
            type == commonConstants.inputTypes.number
          ) {
            return (
              <Col key={fieldName} lg={24}>
                <Form.Item
                  key={fieldName}
                  label={t(`${label}`)}
                  name={fieldName}
                  rules={rules}
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 12 }}
                >
                  <Input
                    type={type}
                    size="large"
                    maxLength={
                      commonConstants.commonConstant.maxFormFieldLength
                    }
                    placeholder={t(`${placeholder}`)}
                    className="no-spin-buttons"
                    onChange={(e) => {
                      if (fieldName === "targetNumberOfPipesInSpread") {
                        handleTargetNumberOfPipesChange(e.target.value);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            );
          } else if (type == commonConstants.inputTypes.select) {
            return (
              <Col key={fieldName} lg={24}>
                <Form.Item
                  key={fieldName}
                  label={t(`${label}`)}
                  name={fieldName}
                  rules={rules}
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 12 }}
                >
                  <Select
                    className="select-drop"
                    bordered={false}
                    size="default"
                    placeholder={t(`${placeholder}`)}
                    onChange={(value) =>
                      handlePipelineTypeChange(value, fieldName)
                    }
                    disabled={
                      fieldName === "sequenceEndA"
                        ? disableSequenceEndA
                        : fieldName === "sequenceEndB"
                        ? disableSequenceEndB
                        : false
                    }
                  >
                    {options &&
                      options.map((ite) => (
                        <Select.Option
                          key={ite.value}
                          value={ite.value}
                          disabled={
                            (fieldName === "sequenceEndA" &&
                              ite.value === selectedSequenceEndB) ||
                            (fieldName === "sequenceEndB" &&
                              ite.value === selectedSequenceEndA)
                          }
                        >
                          {ite.label}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            );
          }
          if (
            fieldName === "targetNumberOfPipesInSpread" ||
            fieldName === "targetLengthOfSpread" ||
            fieldName === "pipelineTypes" ||
            fieldName === "sequenceEndA" ||
            fieldName === "sequenceEndB"
          ) {
            return (
              <Col key={fieldName} lg={12}>
                <Form.Item
                  key={fieldName}
                  label={t(`${label}`)}
                  name={fieldName}
                  rules={rules}
                ></Form.Item>
              </Col>
            );
          }

          return null;
        })}
      </Row>
      <div className="spread-save-btn">
        <Button key="cancel" onClick={props.toggleLabels}>
          {t("common.cancelButtonText")}
        </Button>
        <Button
          key="save"
          type="primary"
          onClick={handleClickSubmit}
          disabled={props.addSpreadLoader}
        >
          {props.addSpreadLoader ? "Saving" : "Save"}
        </Button>
      </div>
    </Form>
  );
};

export default SpreadConfigurationModal;
