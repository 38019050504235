import { notification } from "antd";
import { put, call, all, takeLatest } from "redux-saga/effects";

import {
  setPipeDataGraph,
  setPipeStatistics,
  setProjectStatusPipeData,
  setReportsLoader,
  setProjectSummaryData,
  setPipeStatus,
} from "./action.js";
import {
  FETCH_PIPE_DATA_GRAPH_SAGA,
  FETCH_PIPE_STATISTICS_SAGA,
  FETCH_PROJECT_REPORT_SAGA,
  FETCH_PROJECT_STATUS_PIPE_DATA_SAGA,
  SEND_EMAIL_PIPE_DATA_SAGA,
  FETCH_PROJECT_SUMMARY_DATA_SAGA,
  FETCH_PROJECT_SUMMARY_REPORT_SAGA,
  SEND_EMAIL_PROJECT_SUMMARY_SAGA,
  FETCH_PIPE_STATUS_DATA_SAGA,
  ASSIGN_PIPE_STATUS_DATA_SAGA,
  SEND_CONFIGURATION_EMAIL,
} from "./types.js";
import { BASE_URL } from "../../config.js";
import fetch from "../../services/fetch";

function* fetchPipeStaticsSaga(data) {
  try {
    let apiPayload = data.payload;

    yield put(setReportsLoader(true));

    const res = yield fetch(
      `${BASE_URL}/reports/pipe-summary/${apiPayload.projectNumber}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          yield put(setPipeStatistics(response?.data?.pipeStats));
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    if (data.cb) {
      data.cb();
    }

    yield put(setReportsLoader(false));
  } catch (e) {
    yield put(setReportsLoader(false));
  }
}
function* fetchPipeStatusDataSaga(data) {
  try {
    let apiPayload = data.payload;

    yield put(setReportsLoader(true));

    const res = yield fetch(
      `${BASE_URL}/reports/pipe-status/${apiPayload.projectNumber}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          yield put(setPipeStatus(response?.data));
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    if (data.cb) {
      data.cb();
    }

    yield put(setReportsLoader(false));
  } catch (e) {
    yield put(setReportsLoader(false));
  }
}
function* assignPipeStatusData(data) {
  try {
    let projectNumber = data.projectNumber;
    const updatePipeStatusPayload = data.payload;

    const res = yield fetch(
      `${BASE_URL}/reports/pipe-status/${projectNumber}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          updatePipeStatusPayload: updatePipeStatusPayload,
        }),
      }
    );
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          notification.success({
            message: "Data Assign successfully",
            duration: 6,
          });
          data.cb();
        }
        data.callback();
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }
  } catch (e) {
    console.log(`Error with Saga : ${e}`);
  }
}

function* fetchProjectPdfSaga(data) {
  try {
    let apiPayload = data.payload;

    yield put(setReportsLoader(true));

    const res = yield fetch(
      `${BASE_URL}/reportgen/pipe-data/${apiPayload.projectNumber}`,
      {
        method: "GET",
        headers: {
          Accept: "application/pdf",
        },
      }
    );
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const blob = yield res.blob();

    yield put(setReportsLoader(false));

    if (data.cb) {
      data.cb(blob);
    }
  } catch (e) {
    yield put(setReportsLoader(false));
  }
}

function* fetchProjectSummaryPdfSaga(data) {
  try {
    let apiPayload = data.payload;

    yield put(setReportsLoader(true));

    const res = yield fetch(
      `${BASE_URL}/reportgen/project-summary/${apiPayload.projectNumber}`,
      {
        method: "GET",
        headers: {
          Accept: "application/pdf",
        },
      }
    );
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const blob = yield res.blob();

    yield put(setReportsLoader(false));

    if (data.cb) {
      data.cb(blob);
    }
  } catch (e) {
    yield put(setReportsLoader(false));
  }
}

function* fetchProjectGraphData(data) {
  try {
    let apiPayload = data.payload;

    yield put(setReportsLoader(true));

    const res = yield fetch(
      `${BASE_URL}/reports/pipe-data/${apiPayload.projectNumber}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          yield put(setPipeDataGraph(response?.data?.pipeList));
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    if (data.cb) {
      data.cb();
    }

    yield put(setReportsLoader(false));
  } catch (e) {
    yield put(setReportsLoader(false));
  }
}

function* fetchProjectStatusPipeDataSaga(data) {
  try {
    let apiPayload = data.payload;

    yield put(setReportsLoader(true));

    const res = yield fetch(
      `${BASE_URL}/reports/pipe-scan/${apiPayload.projectNumber}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          yield put(setProjectStatusPipeData(response?.data?.pipeList));
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    if (data.cb) {
      data.cb();
    }

    yield put(setReportsLoader(false));
  } catch (e) {
    yield put(setReportsLoader(false));
  }
}

function* fetchProjectSummaryDataSaga(data) {
  try {
    let apiPayload = data.payload;

    yield put(setReportsLoader(true));

    const res = yield fetch(
      `${BASE_URL}/reports/project-summary/${apiPayload.projectNumber}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          yield put(setProjectSummaryData(response?.data));
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    if (data.cb) {
      data.cb();
    }

    yield put(setReportsLoader(false));
  } catch (e) {
    yield put(setReportsLoader(false));
  }
}
function* sendEmailProjectSummarySaga(data) {
  try {
    let apiPayload = data.payload;

    yield put(setReportsLoader(true));

    const res = yield fetch(
      `${BASE_URL}/reports/send-email/project-summary/${apiPayload.projectNumber}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({ emails: apiPayload.emails }),
      }
    );

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          notification.success({
            message: "Email sent successfully",
            duration: 6,
          });
          data.cb();
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
          data.error();
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
        data.error();
      }
    }

    yield put(setReportsLoader(false));
  } catch (e) {
    yield put(setReportsLoader(false));
    data.error();
  }
}
function* sendEmailPipeDataSaga(data) {
  try {
    let apiPayload = data.payload;

    yield put(setReportsLoader(true));

    const res = yield fetch(
      `${BASE_URL}/reports/send-email/pipe-data/${apiPayload.projectNumber}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({ emails: apiPayload.emails }),
      }
    );

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          notification.success({
            message: "Email sent successfully",
            duration: 6,
          });
          data.cb();
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
          data.error();
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
        data.error();
      }
    }

    yield put(setReportsLoader(false));
  } catch (e) {
    yield put(setReportsLoader(false));
    data.error();
  }
}

function* sendProjectConfigurationEmail(data) {
  try {
    let apiPayload = data.payload;
    yield put(setReportsLoader(true));

    const res = yield fetch(
      `${BASE_URL}/reports/send-email/project-configuration/${apiPayload.projectNumber}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({ emails: apiPayload.emails }),
      }
    );
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }

    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          notification.success({
            message: "Email Sent Successfully!",
            duration: 6,
          });
          data.cb();
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
          data.error();
        }
        break;
      default: {
        notification.error({ message: "Something went wrong." });
        data.error();
      }
    }
    yield put(setReportsLoader(false));
  } catch (e) {
    yield put(setReportsLoader(false));
    data.error();
  }
}

function* reportsWatcher() {
  yield takeLatest(FETCH_PIPE_STATISTICS_SAGA, fetchPipeStaticsSaga);
  yield takeLatest(FETCH_PIPE_STATUS_DATA_SAGA, fetchPipeStatusDataSaga);
  yield takeLatest(ASSIGN_PIPE_STATUS_DATA_SAGA, assignPipeStatusData);
  yield takeLatest(FETCH_PIPE_DATA_GRAPH_SAGA, fetchProjectGraphData);
  yield takeLatest(
    FETCH_PROJECT_STATUS_PIPE_DATA_SAGA,
    fetchProjectStatusPipeDataSaga
  );
  yield takeLatest(FETCH_PROJECT_REPORT_SAGA, fetchProjectPdfSaga);
  yield takeLatest(
    FETCH_PROJECT_SUMMARY_REPORT_SAGA,
    fetchProjectSummaryPdfSaga
  );
  yield takeLatest(SEND_EMAIL_PIPE_DATA_SAGA, sendEmailPipeDataSaga);
  yield takeLatest(
    SEND_EMAIL_PROJECT_SUMMARY_SAGA,
    sendEmailProjectSummarySaga
  );
  yield takeLatest(
    FETCH_PROJECT_SUMMARY_DATA_SAGA,
    fetchProjectSummaryDataSaga
  );
  yield takeLatest(SEND_CONFIGURATION_EMAIL, sendProjectConfigurationEmail);
}

function* reportSagas() {
  yield all([call(reportsWatcher)]);
}

export default reportSagas;
