import { SendOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import QRCode from "qrcode.react";
import React, { useState } from "react";
import "../../global-styles/antd-custom.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import crcLogo from "../../assets/logo_red.png";
import { commonConstants } from "../../constants";
import EmailPopup from "../EmailPopup";

const Qrcode = (props) => {
  let { qrCodeModal, setQrCodeModal } = props;
  const { t } = useTranslation();

  const reducerList = useSelector((state) => state);
  const selectedProject = reducerList?.projectReducer?.selectedProject;

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const vCardData = `
  Project Number : ${selectedProject.projectNumber ?? "-"}
  Project Name : ${selectedProject.projectName ?? "-"}
  Customer Name : ${selectedProject.customerName ?? "-"}
  Project Manager : ${selectedProject.projectManagerName ?? "-"}
  Technician Name : ${selectedProject.technicianName ?? "-"}
  Pipe Length : ${selectedProject.nominalPipeLength ?? "-"}
  Pipe OD : ${selectedProject.nominalOD ?? "-"}
  Pipe Material : ${selectedProject.pipeMaterial ?? "-"}
  Location : ${selectedProject.location ?? "-"}  
`;
  const handleCancel = () => {
    setQrCodeModal(false);
  };
  const showEmailPopup = () => {
    setShowEmailModal(true);
  };
  return (
    <>
      <Modal
        width="457px"
        title={t("configuration.qrCodeModal.projectConfigDetailsHeader")}
        className="quality-modal"
        centered
        open={qrCodeModal}
        maskClosable={false}
        footer={
          <div className="qr-footer">
            <div className="qr-send-btn">
              <Button
                onClick={showEmailPopup}
                className="email-button"
                data-tooltip={t("configuration.qrCodeModal.sendEmailTooltip")}
              >
                <SendOutlined />
              </Button>
            </div>
            <Button key="cancel" type="secondary" onClick={handleCancel}>
              {t("common.close")}
            </Button>
          </div>
        }
      >
        <div className="qr-config-details-parent">
          <div className="qr-code">
            <QRCode
              value={vCardData}
              renderAs="svg"
              size={196}
              level="L"
              imageSettings={{
                src: crcLogo,
                x: null,
                y: null,
                height: 48,
                width: 48,
                excavate: true,
              }}
              td
            />
          </div>
        </div>
      </Modal>
      {showEmailModal && (
        <EmailPopup
          setShowEmailModal={setShowEmailModal}
          showEmailModalFor={commonConstants.showEmailModalFor.configuration}
          isSendingEmail={isSendingEmail}
          setIsSendingEmail={setIsSendingEmail}
        />
      )}
    </>
  );
};

export default Qrcode;
