import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Sequencedata from "./Sequencedata";
import { configurationConstants } from "../../constants";

export const SequenceTable = ({
  selectedConfiguration,
  sequenceList,
  selectionType,
  generateSequence,
  doesHavePermissionToGenerate,
  hasMoreSubSpreads,
  //pagination
  currentPage,
  rowsPerPage,
  totalCount,
  handleTableChange,
  isSequenceApproved,
  doesHavePermissionToViewApprove,
}) => {
  const { t } = useTranslation();
  const reducerList = useSelector((state) => state);

  const handleYesButtonClick = () => {
    generateSequence();
  };

  const getNameBasedOnType = (type) => {
    switch (type) {
      case configurationConstants.selectionType.spread:
        return selectedConfiguration.spreadName;
      case configurationConstants.selectionType.subSpread:
        return selectedConfiguration.subSpreadName;
      default:
        return "";
    }
  };

  const spreadName = () => {
    if (selectionType == configurationConstants.selectionType.spread) {
      return selectedConfiguration.spreadName;
    } else {
      return "";
    }
  };

  const customPendingStatusTextV = () => {
    const { projectPipeCount, spreadPipeCount, subSpreadPipeCount } =
      reducerList?.projectReducer || {};

    if (projectPipeCount === 0) {
      return (
        <div className="pipe-data-not-available">
          {t("sequenceTable.pipeDataNotAvailable")}
        </div>
      );
    } else if (selectionType === configurationConstants.selectionType.spread) {
      const sufficientData =
        selectedConfiguration.targetNumberOfPipes <= spreadPipeCount;
      return (
        t(
          sufficientData
            ? "sequenceTable.pipeDataSufficient"
            : "sequenceTable.pipeDataInSufficient"
        ) +
        ` ${getNameBasedOnType(selectionType)} ` +
        t("sequenceTable.doYouWantToGenerateWithAvailableData")
      );
    } else {
      const sufficientData =
        selectedConfiguration.targetNumberOfPipes <= subSpreadPipeCount;
      return (
        t(
          sufficientData
            ? "sequenceTable.pipeDataSufficient"
            : "sequenceTable.pipeDataInSufficient"
        ) +
        ` ${getNameBasedOnType(selectionType)} ` +
        t("sequenceTable.doYouWantToGenerateWithAvailableData")
      );
    }
  };

  const customPendingStatusText = (
    <div className="sequenceNodata-table">
      <span>{customPendingStatusTextV()}</span>
      {reducerList?.projectReducer?.projectPipeCount ? (
        <div className="Sequence-btn">
          <Button type="primary" onClick={handleYesButtonClick}>
            {t("sequenceTable.yes")}
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );

  const customerErrorStatusText = (
    <div className="sequenceNodata-table">
      <p>
        {"Sequence failed for the reason: "}
        {selectedConfiguration?.reasonForError
          ? selectedConfiguration?.reasonForError
          : "Some unknown error"}
      </p>
      <p>{`Do you want to re generate the sequence for ${getNameBasedOnType(
        selectionType
      )}?`}</p>
      <div className="Sequence-btn">
        <Button type="primary" onClick={handleYesButtonClick}>
          {t("sequenceTable.yes")}
        </Button>
      </div>
    </div>
  );

  const getOtherStatusText = (status) => (
    <div className="sequenceNodata-table-status">
      <span>{`Current status of sequence generation is: ${status}`}</span>
    </div>
  );

  const getSequenceGenerationTextBasedOnStatus = (status) => {
    if (!doesHavePermissionToGenerate) {
      return "Please wait till the sequence is generated.";
    }
    switch (status) {
      case configurationConstants.sequenceGenerationStatus.Pending:
        return customPendingStatusText;
      case configurationConstants.sequenceGenerationStatus.ErrorOccurred:
        return customerErrorStatusText;
      default:
        return getOtherStatusText(status);
    }
  };

  return (
    <div className="container">
      <div className="table-container">
        {selectedConfiguration?.sequenceGenerationStatus !=
          configurationConstants.sequenceGenerationStatus.Completed &&
        !hasMoreSubSpreads ? (
          doesHavePermissionToViewApprove ? (
            <div className="sequenceNodata-table">
              <span>{t("sequenceTable.sequenceyetgenerated")}</span>
            </div>
          ) : (
            getSequenceGenerationTextBasedOnStatus(
              selectedConfiguration?.sequenceGenerationStatus
            )
          )
        ) : hasMoreSubSpreads && sequenceList?.length === 0 ? (
          <>
            <div className="sequenceNodata-table-status">
              <span>{t("sequenceTable.sequencenotready")}</span>
            </div>
          </>
        ) : (
          <div>
            {doesHavePermissionToViewApprove && !isSequenceApproved ? (
              <div className="sequence-approval-stage">
                {t("sequenceTable.sequenceinapprovalstage")}
              </div>
            ) : (
              <Sequencedata
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                totalCount={totalCount}
                handleTableChange={handleTableChange}
                spreadName={spreadName()}
                sequenceList={sequenceList}
                selectionType={selectionType}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
