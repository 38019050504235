import { notification } from "antd";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { invalidFilesError } from "./action";
import { UPLOAD_JSON_FILES } from "./types";
import { BASE_URL } from "../../config";
import fetch from "../../services/fetch";

function* uploadJsonFilesSaga(apiPayload) {
  try {
    const { projectNumber, spread, subSpread, files } = apiPayload;
    const queryStringObject = { projectNumber, spread, subSpread };
    const queryString = Object.keys(queryStringObject)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          queryStringObject[key]
        )}`;
      })
      .join("&");

    const res = yield fetch(
      `${BASE_URL}/file-upload/upload-pipes-json?${queryString}`,
      {
        method: "POST",
        body: files,
      }
    );

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }

    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          if (response.data.invalidFiles.length) {
            let invalidFiles = response.data.invalidFiles;
            yield put(invalidFilesError(invalidFiles));
            invalidFiles.forEach((file) => {
              apiPayload.cd(file.message);
            });
          } else {
            notification.success({
              message: "Files Uploaded Successfully!",
              duration: 6,
            });
            apiPayload.success();
          }
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
          apiPayload.error();
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong!",
          duration: 6,
        });
      }
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: error?.message,
    });
  }
}

function* uploadWatcher() {
  yield takeLatest(UPLOAD_JSON_FILES, uploadJsonFilesSaga);
}

function* uploadSagas() {
  yield all([call(uploadWatcher)]);
}

export default uploadSagas;
