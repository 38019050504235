import { roleBasedAccessControlConstants } from "../constants";

function hasUserHasRole({ roleName, scope }) {
  const role = roleBasedAccessControlConstants.RBAC.find(
    (r) => r.name === roleName
  );

  if (!role) {
    return false; // Role not found
  }

  return role.scopes.includes(scope);
}

export { hasUserHasRole };
