let scopes = {
  application: {
    read: "module/application:read",
    edit: "module/application:edit",
  },
  project: {
    read: "module/project:read",
    create: "module/project:create",
    edit: "module/project:edit",
    delete: "module/project:delete",
  },
  configuration: {
    create: "module/configuration:create",
    read: "module/configuration:read",
    delete: "module/configuration:delete",
    edit: "module/configuration:edit",
  },
  sequence: {
    create: "module/sequence:create",
    delete: "module/sequence:delete",
    review: "module/sequence:review",
    approve: "module/sequence:approve",
    authorizedRead: "module/sequence:authorizedRead",
    sendEmail: "module/sequence:sendEmail",
  },
  report: {
    review: "module/report:review",
    approve: "module/report:approve",
    authorizedRead: "module/report:authorizedRead",
  },
  setting: {
    read: "module/setting:read",
    write: "module/setting:write",
  },
  pipeDetails: {
    create: "module/pipeDetails:create",
  },
};

let role = {
  admin: "pipefitter-admin",
  projectManager: "pipefitter-project-manager",
  technician: "pipefitter-technician",
  customer: "pipefitter-customer",
};

let pipeFitterRoles = [
  role.admin,
  role.projectManager,
  role.technician,
  role.customer,
];

function customSort(a, b) {
  const order = [
    role.admin,
    role.projectManager,
    role.technician,
    role.customer,
  ];

  return order.indexOf(a) - order.indexOf(b);
}

let RBAC = [
  {
    name: role.admin,
    scopes: [
      scopes.application.read,
      scopes.application.edit,
      scopes.project.create,
      scopes.project.read,
      scopes.project.edit,
      scopes.project.delete,
      scopes.configuration.create,
      scopes.configuration.read,
      scopes.configuration.edit,
      scopes.configuration.delete,
      scopes.sequence.create,
      scopes.sequence.delete,
      scopes.sequence.review,
      scopes.sequence.approve,
      scopes.sequence.sendEmail,
      scopes.report.review,
      scopes.report.approve,
      scopes.report.authorizedRead,
      scopes.setting.read,
      scopes.setting.write,
      scopes.pipeDetails.create,
    ],
  },
  {
    name: role.projectManager,
    scopes: [
      scopes.application.read,
      scopes.project.read,
      scopes.project.edit,
      scopes.configuration.create,
      scopes.configuration.read,
      scopes.configuration.edit,
      scopes.configuration.delete,
      scopes.sequence.create,
      scopes.sequence.review,
      scopes.sequence.approve,
      scopes.sequence.sendEmail,
      scopes.report.review,
      scopes.report.approve,
      scopes.report.authorizedRead,
      scopes.pipeDetails.create,
    ],
  },
  {
    name: role.technician,
    scopes: [
      scopes.application.read,
      scopes.project.read,
      scopes.configuration.read,
      scopes.sequence.create,
      scopes.report.authorizedRead,
    ],
  },
  {
    name: role.customer,
    scopes: [
      scopes.application.read,
      scopes.project.read,
      scopes.configuration.read,
      scopes.sequence.create,
      scopes.sequence.authorizedRead,
      scopes.report.authorizedRead,
    ],
  },
];

export { RBAC, scopes, role, pipeFitterRoles, customSort };
