import { SWITCH_THEME, TOGGLE_THEME } from "./types";

const switchTheme = (payload) => ({
  type: SWITCH_THEME,
  payload,
});

const toggleTheme = () => ({
  type: TOGGLE_THEME,
});

export { switchTheme, toggleTheme };
