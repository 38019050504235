/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, notification, Row, Col, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { resetPasswordSubmit } from "../services/auth";
import history from "../services/history";
import * as constants from "../utils/constants";
import { passwordPolicy } from "../utils/passwordPolicy";

const ResetPasswordForm = () => {
  const [passForm] = Form.useForm();
  const [validatingStatus, setValidatingStatus] = useState("");
  const [validateVisible, setValidateVisible] = useState(false);
  const [validatingStatusConfirm, setValidatingStatusConfirm] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const onFinish = () => {};

  const changeTempPass = async (data) => {
    try {
      if (data.password === data.password_confirm) {
        if (data.lastName) {
          data.fullName = `${data.firstName} ${data.lastName}`;
        } else {
          data.fullName = `${data.firstName}`;
        }
        await resetPasswordSubmit(data.password, data.fullName);
        history.push("./login");
      } else {
        notification.warning({
          message: "Passwords does not match",
        });
      }
    } catch (err) {
      notification.warning({
        message: "Something went wrong.- Please try again!",
      });
    }
  };

  const validatePass = () => {
    passForm.validateFields().then(async (data) => {
      await changeTempPass(data);
    });
  };

  useEffect(() => {
    passForm.resetFields();
    setValidatingStatus("");
    setValidatingStatusConfirm("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Validating Entered Password with password policy */
  const validateToNextPassword = (e) => {
    const value = e.target.value;
    if (!value) {
      setValidateVisible(false);
    }
    if (value) {
      setNewPassword(value);
      setValidatingStatus("validating");
      setTimeout(() => {
        if (value.length > 7 && constants.PASSWORD_PATTERN.test(value)) {
          setValidatingStatus("success");
          setValidateVisible(false);
        } else {
          setValidatingStatus("warning");
          setValidateVisible(true);
        }
      }, 500);
    }
  };

  /** Checking Entered Password and Confirmation password to match */
  const validateToFirstPassword = (e) => {
    const value = e.target.value;

    if (value) {
      setValidatingStatusConfirm("validating");
      setTimeout(() => {
        if (value.length > 7 && value === newPassword) {
          setValidatingStatusConfirm("success");
        } else {
          setValidatingStatusConfirm("warning");
        }
      }, 500);
    }
  };

  return (
    <div className="login-form reset-form">
      <LockOutlined
        className="mb-4"
        style={{ fontSize: "40px", paddingRight: "20px" }}
      />
      <h1 className="mb-3 text-center">Change Password</h1>
      <Form
        name="normal_login"
        initialValues={{
          remember: true,
        }}
        form={passForm}
        onFinish={onFinish}
      >
        <Popover
          placement="bottomRight"
          title={constants.TITLE}
          content={passwordPolicy}
          trigger="focus"
          visible={validateVisible}
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter new password.",
              },
            ]}
            hasFeedback
            validateStatus={validatingStatus}
          >
            <Input.Password
              size="large"
              className="password-icon"
              placeholder="New password"
              onChange={validateToNextPassword}
              iconRender={(visibility) =>
                visibility ? (
                  <EyeOutlined style={{ color: "grey" }} />
                ) : (
                  <EyeInvisibleOutlined style={{ color: "grey" }} />
                )
              }
            />
          </Form.Item>
        </Popover>
        <Form.Item
          name="password_confirm"
          rules={[
            {
              required: true,
              message: "Please confirm your password.",
            },
          ]}
          hasFeedback
          validateStatus={validatingStatusConfirm}
        >
          <Input.Password
            size="large"
            className="password-icon"
            placeholder="Confirm new password"
            onChange={validateToFirstPassword}
            iconRender={(visibility) =>
              visibility ? (
                <EyeOutlined style={{ color: "grey" }} />
              ) : (
                <EyeInvisibleOutlined style={{ color: "grey" }} />
              )
            }
          />
        </Form.Item>
        <Row gutter={16}>
          <Col lg={12}>
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please enter your first name.",
                },
              ]}
            >
              <Input size="large" placeholder="First name" maxLength={25} />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: false,
                  message: "Please enter your last name.",
                },
              ]}
            >
              <Input size="large" placeholder="Last name" maxLength={25} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            size="large"
            shape="round"
            block
            htmlType="submit"
            className="login-form-button"
            onClick={validatePass}
          >
            Change Password
          </Button>
        </Form.Item>
        <Button
          // size="large"
          // shape="round"
          block
          htmlType="submit"
          className="login-form-button"
        >
          <Link to="/login">Back to Login</Link>
        </Button>
      </Form>
      <span className="login_copy">
        Pioneered by CRC Evans &copy;{constants.CURR_YEAR} |{" "}
        <a href={constants.TERMS_AND_USE} rel="noreferrer" target="_blank">
          Terms & Conditions
        </a>{" "}
        |{" "}
        <a href={constants.PRIVACY_LINK} rel="noreferrer" target="_blank">
          Privacy Policy
        </a>
      </span>
    </div>
  );
};

export default ResetPasswordForm;
